/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

const childRoutes = [];

// Home
childRoutes[childRoutes.length] = {
    path: '/',
    exact: true,
    componentName: 'HomePageContainer',
    title: 'Home',
};

// Beta Feedback Page
childRoutes[childRoutes.length] = {
    path: '/feedback',
    componentName: 'Feedback',
    title: 'Feedback',
    enabled: process.env.FEEDBACK_ENABLED,
};

// Home: Sign up - legacy ???
childRoutes[childRoutes.length] = {
    path: '/sign-up',
    exact: true,
    componentName: 'HomePageContainer',
    title: 'Home',
};

// Home: Log in - legacy ???
childRoutes[childRoutes.length] = {
    path: '/log-in',
    exact: true,
    componentName: 'HomePageContainer',
    title: 'Home',
};

// Home: Magic Log In
childRoutes[childRoutes.length] = {
    path: '/magic-log-in',
    exact: true,
    componentName: 'HomePageContainer',
    title: 'Home',
};

// Home: CSR Session URL
childRoutes[childRoutes.length] = {
    path: '/csr-log-in/:csrToken',
    exact: true,
    componentName: 'HomePageContainer',
    title: 'Home',
};

// Home: CSR Session URL w/ newUrl param
childRoutes[childRoutes.length] = {
    path: '/csr-log-in/:csrToken/:newUrl',
    exact: true,
    componentName: 'HomePageContainer',
    title: 'Home',
};

// Home: Reset password - legacy ???
childRoutes[childRoutes.length] = {
    path: '/reset-password',
    exact: true,
    componentName: 'HomePageContainer',
    title: 'Home',
};

// Account Confirmation
childRoutes[childRoutes.length] = {
    path: '/account/confirmation/:confirmToken',
    componentName: 'HomePageContainer',
    title: 'Account Confirmation',
};

// Password Reset
childRoutes[childRoutes.length] = {
    path: '/account/reset/:resetToken',
    componentName: 'HomePageContainer',
    title: 'Password Reset',
};

childRoutes[childRoutes.length] = {
    path: '/mfa',
    componentName: 'HomePageContainer',
    title: 'MFA',
};

// Accessibility Pages
childRoutes[childRoutes.length] = {
    path: '/accessibility',
    componentName: 'AccessibilityPage',
    title: 'Accessibility Pages',
};

// Subscribe Page
childRoutes[childRoutes.length] = {
    path: '/subscribe',
    componentName: 'SubscribePage',
    title: 'Subscribe to our newsletter',
};

// Disclaimers Page
childRoutes[childRoutes.length] = {
    path: '/disclaimers',
    componentName: 'DisclaimersPage',
    title: 'Disclaimers Page',
};

// FOIA Page
childRoutes[childRoutes.length] = {
    path: '/foia',
    componentName: 'FOIAPage',
    title: 'FOIA Page',
};

// AboutUs Page
childRoutes[childRoutes.length] = {
    path: '/about-us',
    componentName: 'AboutUsPage',
    title: 'About Us',
};

// Reservation Policies Page
childRoutes[childRoutes.length] = {
    path: '/rules-reservation-policies',
    componentName: 'RulesReservationPoliciesPage',
    title: 'Rules & Reservation Policies',
};

// Reservation Policies Page Redirect
childRoutes[childRoutes.length] = {
    path: '/reservation-policies',
    componentName: 'RulesReservationPoliciesPage',
    title: 'Rules & Reservation Policies',
};

// Privacy Policy Page
childRoutes[childRoutes.length] = {
    path: '/privacy-policy',
    componentName: 'PrivacyPolicyPage',
    title: 'Privacy Policy',
};

// Use Our Data Page
childRoutes[childRoutes.length] = {
    path: '/use-our-data',
    componentName: 'UseOurData',
    title: 'Use Our Data',
};

// GetInvolved (Volunteer) Page
childRoutes[childRoutes.length] = {
    path: '/get-involved',
    componentName: 'GetInvolvedPage',
    title: 'Get Involved',
};

// Gift Card Order Details Page
childRoutes[childRoutes.length] = {
    path: '/gift-cards/order-details/:id',
    componentName: 'GiftCardOrderDetailsPage',
    title: 'Gift Card Order Details',
    enabled: process.env.GIFT_CARDS_ENABLED,
    ldFlagEnabled: 'enableGiftCards',
};

// Gift Card Page
childRoutes[childRoutes.length] = {
    path: '/gift-cards',
    componentName: 'GiftCardPage',
    title: 'Gift Card',
    enabled: process.env.GIFT_CARDS_ENABLED,
    ldFlagEnabled: 'enableGiftCards',
};

// add / articles
childRoutes[childRoutes.length] = {
    path: '/articles',
    exact: true,
    componentName: 'ArticlesPage',
    title: 'Articles',
};

// add / articles
childRoutes[childRoutes.length] = {
    path: '/articles/:contentType/:title/:id',
    componentName: 'ArticlePage',
    title: '',
};

// Unsupported Browsers Page
childRoutes[childRoutes.length] = {
    path: '/unsupported-browser',
    componentName: 'UnsupportedBrowser',
    title: 'Unsupported Browser',
};

// Mobile App Page
childRoutes[childRoutes.length] = {
    path: '/mobile-app',
    componentName: 'MobileAppPage',
    title: 'Mobile App',
};

// Government Shutdown Page
childRoutes[childRoutes.length] = {
    path: '/government-shutdown-info',
    componentName: 'GovernmentShutdownPage',
    title: 'Federal Government Shutdown',
};

// New Implementations Page
childRoutes[childRoutes.length] = {
    path: '/new-implementations',
    componentName: 'NewImplementationsPage',
    title: `List your facility on ${process.env.SITE_NAME}`,
};

childRoutes[childRoutes.length] = {
    path: '/learnmore/:invType',
    componentName: 'NewInventoryLearnMorePage',
    title: `List your facility on ${process.env.SITE_NAME}`,
};

// Media Center Page
childRoutes[childRoutes.length] = {
    path: '/media-center',
    componentName: 'MediaCenterPage',
    title: 'Media Center',
};

// Rentals Page
childRoutes[childRoutes.length] = {
    path: '/rentals',
    componentName: 'Rentals',
    title: 'Rentals',
};

// What's New Page
childRoutes[childRoutes.length] = {
    path: '/whats-new',
    componentName: 'WhatsNew',
    title: "What's New",
};

// Discover camping by state
childRoutes[childRoutes.length] = {
    path: '/discover/camping/:state/state',
    componentName: 'DiscoverCampingState',
    title: 'Discover Camping',
};

// Discover camping by location
childRoutes[childRoutes.length] = {
    path: '/discover/camping/:location',
    componentName: 'DiscoverCampingLocation',
    title: 'Discover Camping',
};

// Discover by state
childRoutes[childRoutes.length] = {
    path: '/discover/:state/state',
    componentName: 'DiscoverState',
    title: 'Discover',
};

// Discover by location
childRoutes[childRoutes.length] = {
    path: '/discover/:location',
    componentName: 'DiscoverLocation',
    title: 'Discover',
};

// Site Map Page
childRoutes[childRoutes.length] = {
    path: '/site-map',
    componentName: 'SiteMap',
    title: 'Site Map',
};

// Mobile Sign Up Page
childRoutes[childRoutes.length] = {
    path: '/mobile-sign-up',
    componentName: 'MobileSignUp',
    title: 'Sign Up',
};

// BLM Donation T&C
childRoutes[childRoutes.length] = {
    path: '/blm-donations-terms-and-conditions',
    componentName: 'DonationTACBLM',
    title: 'BLM Donations Terms & Conditions',
};

// USACE Donation T&C
childRoutes[childRoutes.length] = {
    path: '/usace-donations-terms-and-conditions',
    componentName: 'DonationTACUSACE',
    title: 'USACE Donations Terms & Conditions',
};

// USACE Donation T&C
childRoutes[childRoutes.length] = {
    path: '/usfs-donations-terms-and-conditions',
    componentName: 'DonationTACUSFS',
    title: 'USFS Donations Terms & Conditions',
};

// FAQ Page
childRoutes[childRoutes.length] = {
    path: '/faq',
    componentName: 'FAQ',
    title: 'Program & Contract FAQs',
};

// Vulnerability Disclosure Policy
childRoutes[childRoutes.length] = {
    path: '/vulnerability-disclosure-policy',
    componentName: 'VulnerabilityDisclosurePolicyPage',
    title: 'Vulnerability Disclosure Policy',
};

export default childRoutes;
