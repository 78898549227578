/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * QuickLinks.jsx
 * Updated by Destin Frasier on 10/10/2022
 */

import React from 'react';
import { Heading, Icon, Button, ExternalLink, useWindowSize } from 'sarsaparilla';
import { FACEBOOK, INSTAGRAM } from 'site-kit';

export default function QuickLinks() {
    const sectionClickTagCategory = 'Homepage';
    const sectionClickTagLabel = 'Inspiration & Information - Quick Links';
    const promoItems = [
        {
            url: '/mobile-app',
            icon: <Icon iconName="apple" />,
            title: `Download our iOS App`,
            trackingID: '845291919451',
        },
        {
            url: '/mobile-app',
            icon: <Icon iconName="android" />,
            title: `Get our Android App`,
            trackingID: '734739801356',
        },
        {
            url: '/rentals/#rv-rentals',
            icon: <Icon iconName="rv-trailer" />,
            title: `RV & Trailer Rentals`,
            trackingID: '989088509555',
        },
        {
            url: 'https://www.sharetheexperience.org/',
            icon: <Icon iconName="photo" />,
            title: `Share the Experience photo contest`,
            isExternal: true,
            trackingID: '988289865284',
        },
        {
            url: INSTAGRAM,
            icon: <Icon iconName="instagram" />,
            title: `Follow us on Instagram`,
            isExternal: true,
            trackingID: '585372708376',
        },
        {
            url: FACEBOOK,
            icon: <Icon iconName="facebook" />,
            title: `Like Us on Facebook`,
            isExternal: true,
            trackingID: '666162115404',
        },
    ];

    // Find the width of the page
    const { width } = useWindowSize();

    // Change the size of the heading depending on the width of the page
    const setHeadingSize = width <= 992 ? 'h4' : 'h5';

    return (
        <div className="nav-quick-links-wrap">
            <Heading headingLevel={3} appearance={setHeadingSize} hasUnderline="left">
                Quick Links
            </Heading>

            <ul>
                {promoItems.map((promoItem, index) => {
                    return (
                        <li key={index}>
                            {promoItem.isExternal ? (
                                <ExternalLink
                                    hasExternalIcon={false}
                                    url={promoItem.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    clickTagCategory={sectionClickTagCategory}
                                    clickTagAction={promoItem.title}
                                    clickTagLabel={sectionClickTagLabel}
                                    gaTrackingId={promoItem.trackingID}
                                >
                                    {promoItem.icon}
                                    {promoItem.title}
                                </ExternalLink>
                            ) : (
                                <Button
                                    isUnstyled
                                    href={promoItem.url}
                                    clickTagCategory={sectionClickTagCategory}
                                    clickTagAction={promoItem.title}
                                    clickTagLabel={sectionClickTagLabel}
                                    gaTrackingId={promoItem.trackingID}
                                >
                                    {promoItem.icon}
                                    {promoItem.title}
                                </Button>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
