/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * ArticleLeftRail.jsx
 * Created by Destin Frasier on 03/27/18
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FlexCol } from 'sarsaparilla';
import { ARTICLE } from '../../../constants/propTypes';

import TripIdeas from './leftRail/TripIdeas';
import BringHomeStoryItems from './leftRail/BringHomeStoryItems';
import AboutAuthor from './leftRail/AboutAuthor';
// import ShareExperience from '../../../components/articles/article/leftRail/ShareExperience';
// import MyItinerary from '../../../components/articles/article/leftRail/MyItinerary';
// import SubmitStories from '../../../components/articles/article/leftRail/SubmitStories';
// import MapContainer from '../../../components/articles/article/leftRail/MapContainer';

export class ArticleLeftRail extends React.PureComponent {
    static propTypes = {
        article: ARTICLE,
        contentType: PropTypes.string,
    };

    render() {
        const { article, contentType } = this.props;

        let bringHomeAStory = null;
        let aboutAuthor = null;
        let tripIdeas = null;
        let map = null;

        if (article.bring_home_inventory && article.bring_home_inventory.length > 0) {
            bringHomeAStory = (
                <BringHomeStoryItems stories={article.bring_home_inventory} />
            );
        }

        // Show AboutAuthor for Journal articles only
        // Show TripIdeas for List and Location Spotlight articles only
        // Show Map for Location Spotlight articles only
        if (contentType === 'journal') {
            aboutAuthor = (
                <AboutAuthor
                    image={article.about_author_image}
                    alt={article.author_by}
                    bioText={article.about_author_description}
                />
            );
        } else {
            if (
                article.explore_related_trips &&
                article.explore_related_trips.length > 0
            ) {
                tripIdeas = <TripIdeas trips={article.explore_related_trips} />;
            }

            if (contentType === 'location-spotlight') {
                // Temporarily disable map
                // map = <MapContainer />;
                map = null;
            }
        }

        return (
            <FlexCol md={4} xl={3} mdOrder={1}>
                <div
                    className="nav-article-left-rail-wrap"
                    role="complementary"
                    id="nav-article-left-menu"
                >
                    {bringHomeAStory}
                    {aboutAuthor}
                    {tripIdeas}
                    {map}
                </div>
            </FlexCol>
        );
    }
}
