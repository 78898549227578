/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

// Mapbox Studio icon names
export const CAMPING_PIN = 'camping-pin';
export const CAMPING_PIN_DISABLED = 'camping-pin-disabled';
export const CAMPING_PIN_ACTIVE = 'camping-pin-active';
export const DAY_USE_PIN = 'day-use-pin';
export const DAY_USE_PIN_DISABLED = 'dayUse-pin-disabled';
export const PERMITS_PIN = 'permits-pin';
export const TREE_PERMITS_PIN = 'tree-permit-pin';
export const POI_PIN = 'poi-pin';
export const REC_AREA_PIN = 'rec-area-pin';
export const PASSES_PIN = 'passes-pin';
export const PASSES_PIN_DISABLED = 'passes-pin-disabled';
export const TICKET_FACILITY_PIN = 'ticket-facility-pin';
export const TOURS_PIN = 'tours-pin';
export const VENUE_WEDDING_PIN = 'venue-wedding-pin';
export const VENUE_SMALL_GATHERING_PIN = 'venue-small-gathering-pin';
export const VENUE_SHOOTING_PIN = 'venue-shooting-pin';
export const VENUE_OTHER_PIN = 'venue-other-pin';
export const VENUE_LARGE_RECEPTION_PIN = 'venue-large-reception-pin';
export const VENUE_HUNTING_PIN = 'venue-hunting-pin';
export const VENUE_GOLFING_PIN = 'venue-golfing-pin';
export const VENUE_ATHLETIC_ACTIVITY_PIN = 'venue-athletic-activity-pin';
export const GATEWAY_PIN = 'gateway-pin';
export const RESTROOM_PIN = 'restroom-pin';
export const TRAIL_HEAD_PIN = 'trailhead-pin';
export const DRINKING_WATER_PIN = 'drinking-water-pin';
export const DESTINATION_PIN = 'destination-pin';
export const REC_ICON_VENUE_OTHER = 'rec-icon-venue-other';
export const REC_ICON_TICKET = 'rec-icon-ticket';
export const ENTRANCE_PIN = 'entrance-pin';
export const EXIT_PIN = 'exit-pin';
export const DESTINATION_START_PIN = 'destination-start-pin';

// Amenities
export const REC_ICON_AMENITIES = 'rec-icon-amenities';

// Rec.gov map icon names
export const REC_CAMPING_PIN = 'camping_pin@2x.png';
export const REC_DAY_USE_PIN = 'dayUse_pin@2x.png';
export const REC_PERMITS_PIN = 'permit_pin@2x.png';
export const REC_POI_PIN = 'poi_pin@2x.png';
export const REC_PASSES_PIN = 'pass_pin@2x.png';
export const REC_REC_AREA_PIN = 'recArea_pin@2x.png';
export const REC_TOURS_PIN = 'tour_pin@2x.png';
export const REC_VENUE_OTHER_PIN = 'venue_other_pin@2x.png';

// Define a unique identifier per facility type
export const services = {
    pass: {
        name: 'pass',
        color: '#1F3B94',
        pdfTitle: 'PASS MAP',
    },
    destination: {
        name: 'destination',
        color: '#1F3B94',
        pdfTitle: 'DESTINATION MAP',
    },
    venue: {
        name: 'venue',
        color: '#731F44',
        defaultMapboxIcon: REC_ICON_VENUE_OTHER,
        pdfTitle: 'VENUE MAP',
    },
    treepermit: {
        name: 'treepermit',
        color: '#c3512c',
        pdfTitle: 'TREE PERMIT MAP',
    },
    ticket: {
        name: 'ticket',
        color: '#93348c',
        defaultMapboxIcon: REC_ICON_TICKET,
        pdfTitle: 'TICKET MAP',
    },
    timedentry: {
        name: 'timedentry',
        color: '#93348c',
        defaultMapboxIcon: REC_ICON_TICKET,
        pdfTitle: 'TIME ENTRY MAP',
    },
    campingpoi: {
        name: 'campingpoi',
        color: '#5abf95',
        defaultMapboxIcon: POI_PIN,
        pdfTitle: 'CAMPING MAP',
    },
    internalcampingfieldsales: {
        name: 'internalcampingfieldsales',
        color: '#4a77b4',
    },
    camping: {
        name: 'camping',
        color: '#4a77b4',
        pdfTitle: 'CAMPING MAP',
    },
    permit: {
        name: 'permit',
        defaultMapboxIcon: PERMITS_PIN,
        pdfTitle: 'PERMIT MAP',
    },
    navigation: {
        name: 'navigation',
        pdfTitle: 'HOMEPAGE MAP',
    },
    search: {
        name: 'search',
        pdfTitle: 'SEARCH MAP',
    },
    gateway: {
        name: 'gateway',
        pdfTitle: 'GATEWAY MAP',
    },
    tripplanner: {
        name: 'tripplanner',
        pdfTitle: 'TRIP PLANNER MAP',
    },
    campingsinglepoint: {
        name: 'campingsinglepoint',
        pdfTitle: 'CAMPING MAP',
        color: '#4a77b4',
        defaultMapboxIcon: CAMPING_PIN,
    },
    campingitinerary: {
        name: 'campingitinerary',
        pdfTitle: 'CAMPING MAP',
        defaultMapboxIcon: 'camping-pin',
    },
    campinginternal: {
        name: 'campinginternal',
        pdfTitle: 'CAMPING MAP',
        defaultMapboxIcon: 'camping-pin',
    },
};

export const facilityIcons = [
    CAMPING_PIN,
    DAY_USE_PIN,
    PERMITS_PIN,
    TREE_PERMITS_PIN,
    POI_PIN,
    REC_AREA_PIN,
    PASSES_PIN,
    TICKET_FACILITY_PIN,
    TOURS_PIN,
    VENUE_WEDDING_PIN,
    VENUE_SMALL_GATHERING_PIN,
    VENUE_SHOOTING_PIN,
    VENUE_OTHER_PIN,
    VENUE_LARGE_RECEPTION_PIN,
    VENUE_HUNTING_PIN,
    VENUE_GOLFING_PIN,
    VENUE_ATHLETIC_ACTIVITY_PIN,
];

export const facilityTypes = {
    campground: {
        symbol: CAMPING_PIN,
    },
    campsite: {
        symbol: CAMPING_PIN,
    },
    gateway: {
        symbol: GATEWAY_PIN,
    },
    'permit office': {
        symbol: PERMITS_PIN,
    },
    'picnic area': {
        symbol: DAY_USE_PIN,
    },
    'recreation area': {
        symbol: REC_AREA_PIN,
    },
    restroom: {
        symbol: RESTROOM_PIN,
    },
    trailhead: {
        symbol: TRAIL_HEAD_PIN,
    },
    ticket: {
        symbol: TOURS_PIN,
    },
    tour: {
        symbol: TOURS_PIN,
    },
    'water source': {
        symbol: DRINKING_WATER_PIN,
    },
    venue: {
        symbol: VENUE_OTHER_PIN,
    },
    default: {
        symbol: POI_PIN,
    },
};

export const permitsIconTypes = {
    permits: PERMITS_PIN,
    poi: POI_PIN,
    trailhead: TRAIL_HEAD_PIN,
    camping: CAMPING_PIN,
    entrance: ENTRANCE_PIN,
    default: POI_PIN,
};

export const circleIconsColors = [
    [CAMPING_PIN, '#4a77b4'],
    [CAMPING_PIN_DISABLED, '#afbec8'],
    [CAMPING_PIN_ACTIVE, '#c3512c'],
    [DAY_USE_PIN, '#7fb135'],
    [GATEWAY_PIN, '#efaa00'],
    [PERMITS_PIN, '#d27a56'],
    [REC_AREA_PIN, '#efaa00'],
    [POI_PIN, '#5abf95'],
    [TOURS_PIN, '#93348c'],
    [TICKET_FACILITY_PIN, '#93348c'],
    [PASSES_PIN, '#3e4ded'],
    [VENUE_WEDDING_PIN, '#731F44'],
    [VENUE_SMALL_GATHERING_PIN, '#731F44'],
    [VENUE_SHOOTING_PIN, '#731F44'],
    [VENUE_OTHER_PIN, '#731F44'],
    [VENUE_LARGE_RECEPTION_PIN, '#731F44'],
    [VENUE_HUNTING_PIN, '#731F44'],
    [VENUE_GOLFING_PIN, '#731F44'],
    [VENUE_ATHLETIC_ACTIVITY_PIN, '#731F44'],
];

// Geojson data types
export const POINT = 'point';

// Rec.gov colors
export const recColors = {
    clusterCircle: '#295a0b',
    strokeClusterCircle: '#97c273',
    text: '#ffffff',
    outline: '#2F4A0B',
    recAreas: '#466C04',
    radiusOutline: '#E7AB09',
    radiusFill: '#FFC72E',
    trailsLine: '#008480',
    selectedTrailLine: '#0F6460',
    hoveredTrailLine: '#00A398',
    borderTrailLine: '#E0F7F6',
    evClusterFill: '#2E5E4D',
    evLevelsStrokeFill: '#5ABF95',
    activeFeature: '#C3512C',
};

// Camping map
export const MAX_NEARBY_RADIUS = 161; // 100 miles > 161 km
export const campingSources = {
    campsites: 'campsites',
    amenities: 'amenities',
    children: 'children',
    inventory: 'inventory',
    nearbyCampgrounds: 'nearbyCampgrounds',
    nearbyRadius: 'nearbyRadius',
    itinerary: 'itinerary',
    entrances: 'entrances',
};

export const tripPlannerSources = {
    lineRoute: 'lineRoute',
    polygonRoute: 'polygonRoute',
    recreation: 'recreation',
    tripDestinations: 'tripDestinations',
    tripItems: 'tripItems',
};

export const MAP_LAYER_CONTROL_CONTENT = [
    {
        icons: [POI_PIN],
        label: 'Points of Interest',
        id: 'poi',
    },
    {
        icons: [REC_AREA_PIN],
        label: 'Recreation Areas',
        id: 'recreation-areas',
    },
    {
        icons: [DAY_USE_PIN, DAY_USE_PIN_DISABLED],
        label: 'Day Use',
        id: 'day-use',
    },
    {
        icons: [CAMPING_PIN, CAMPING_PIN_DISABLED],
        label: 'Campgrounds',
        id: 'campground',
    },
    {
        icons: [PERMITS_PIN],
        label: 'Permits',
        id: 'permits',
    },
    {
        icons: [TREE_PERMITS_PIN],
        label: 'Tree Permits',
        id: 'tree-permits',
    },
    {
        icons: [PASSES_PIN, PASSES_PIN_DISABLED],
        label: 'Activity Passes',
        id: 'activity-passes',
    },
    {
        icons: [TICKET_FACILITY_PIN, TOURS_PIN],
        label: 'Tours',
        id: 'tours',
    },
];

export const permitSources = {
    point: 'point',
    points: 'points',
    lines: 'lines',
    polygons: 'polygons',
    highlightedLines: 'highlightedLines',
};

export const zoomStops = {
    1: 200,
    2: 200,
    3: 200,
    4: 200,
    5: 190,
    6: 180,
    7: 70,
    8: 40,
    9: 20,
    10: 10,
    11: 7,
    12: 3,
    13: 1,
};

// EV levels
export const evLevels = {
    'level-01': {
        name: 'Level 1',
        label: 'Level 1 Stations',
        level: 'level-01',
        shouldDisplay: true,
    },
    'level-02': {
        name: 'Level 2',
        label: 'Level 2 Stations',
        level: 'level-02',
        shouldDisplay: true,
    },
    'level-03': {
        name: 'Level 3',
        label: 'DCFast Stations',
        level: 'level-03',
        shouldDisplay: true,
    },
    'other-level': { label: 'Other', level: 'other-level', shouldDisplay: true },
};

export const evAdapters = {
    chademo: { label: 'CHAdeMO', adapter: 'chademo', shouldDisplay: true },
    j1772: { label: 'J1772', adapter: 'j1772', shouldDisplay: true },
    j1772combo: { label: 'J1772 Combo', adapter: 'j1772combo', shouldDisplay: true },
    'tesla-adapter': { label: 'Tesla', adapter: 'tesla-adapter', shouldDisplay: true },
    'other-adapter': { label: 'Other', adapter: 'other-adapter', shouldDisplay: true },
};

export const evNetworks = {
    'chargepoint-network': {
        label: 'ChargePoint',
        network: 'chargepoint-network',
        shouldDisplay: true,
    },
    'tesla-network': { label: 'Tesla', network: 'tesla-network', shouldDisplay: true },
    'blink-network': { label: 'Blink', network: 'blink-network', shouldDisplay: true },
    'electrify-america': {
        label: 'Electrify America',
        network: 'electrify-america',
        shouldDisplay: true,
    },
    'ev-connect': { label: 'EV Connect', network: 'ev-connect', shouldDisplay: true },
    volta: { label: 'Volta', network: 'volta', shouldDisplay: true },
    'evgo-network': { label: 'Evgo', network: 'evgo-network', shouldDisplay: true },
    'other-network': { label: 'Other', network: 'other-network', shouldDisplay: true },
};

// Layer visibility
export const VISIBLE = 'visible';
export const NONE = 'none';
export const VISIBILITY = 'visibility';

// Legend
export const FACILITY_TYPES = 'FACILITY TYPES';
export const SITE_TYPES = 'SITE TYPES';
export const SITE_STATUS = 'STATUS';
export const AMENITIES = 'AMENITIES';

// USA Bounding box
export const usBoundingBox = [-125.00165, 24.9493, -66.93457, 49.5904];

// cSpell:ignore treepermit, timedentry, campingpoi, internalcampingfieldsales, tripplanner
