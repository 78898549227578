/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import DiscoverResultsContainer from './DiscoverResultsContainer';

export default function DiscoverCampingState() {
    const { state } = useParams();
    const stateName = decodeURIComponent(state).replace(/\+/g, ' ').replace(/-/g, ' ');
    return (
        <DiscoverResultsContainer
            titlePrefix={`Discover Camping in `}
            location={null}
            state={stateName}
        />
    );
}
