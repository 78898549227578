/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Heading } from 'sarsaparilla';

const zoomActions = [
    {
        action: 'Virtual HTML Features',
        command: 'INSERT + F3',
    },
    {
        action: 'Increase magnification',
        command: 'ALT + NUMPAD PLUS',
    },
    {
        action: 'Decrease magnification',
        command: 'ALT + NUMPAD MINUS',
    },
    {
        action: 'Turn color enhancements on and off',
        command: 'CTRL + SHIFT + C',
    },
    {
        action: 'Font Enhancements (xFont, Standard and None)',
        command: 'CTRL + SHIFT + F',
    },
];

const tecName = 'Zoom';

export default function ZoomActionsSection() {
    return (
        <section>
            <Heading
                headingLevel={3}
                appearance="h4"
                className="accessibility-table-heading mb-2"
            >
                {tecName} Actions and Commands
            </Heading>
            <table
                className="table-no-border mb-4"
                aria-label={`${tecName} Actions and Commands Table`}
            >
                <thead>
                    <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Commands</th>
                    </tr>
                </thead>
                <tbody>
                    {zoomActions.map((zoomAction) => (
                        <tr key={zoomAction.action}>
                            <td width="70%">{zoomAction.action}</td>
                            <td width="30%">{zoomAction.command}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
}
