/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { HelmetWrapper, SingleColumnContainer, Heading } from 'sarsaparilla';

import { NewsletterSubscribeForm } from 'site-kit';

export default function SubscribePage() {
    return (
        <SingleColumnContainer className="nav-feedback-page" role="main">
            <HelmetWrapper title="Subscribe to Our Newsletter" />

            <Heading headingLevel={1} appearance="h1">
                Subscribe to Our Newsletter
            </Heading>
            <p>
                Let&#39;s Stay in Touch! Get travel planning ideas, helpful tips, and
                stories from our visitors delivered right to your inbox. Fields marked
                with a red asterisk (<span className="asterisk-example">*</span>) are
                required to sign up for emails from {process.env.SITE_NAME}.
            </p>

            <NewsletterSubscribeForm isForHomePage={false} />
        </SingleColumnContainer>
    );
}
