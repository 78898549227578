/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import './RecGovAppIcon.scss';

import * as React from 'react';

import iconSrc from './rec-gov-app-icon.svg';

export function RecGovAppIcon({
    className,
    ...rest
}: React.ImgHTMLAttributes<HTMLImageElement>) {
    return (
        <img
            data-component="RecGovAppIcon"
            src={iconSrc}
            className={className}
            alt="Mobile app icon"
            {...rest}
        />
    );
}
