/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    CAMPING_PIN,
    CAMPING_PIN_DISABLED,
    DAY_USE_PIN,
    DAY_USE_PIN_DISABLED,
    PASSES_PIN,
    PASSES_PIN_DISABLED,
    PERMITS_PIN,
    POI_PIN,
    REC_AREA_PIN,
    TICKET_FACILITY_PIN,
    TOURS_PIN,
    TREE_PERMITS_PIN,
} from 'shared-ui/maps/constants';

// pagination
export const PAGINATION_SIZE = 200;

// search
export const GET_SEARCH_SUGGESTIONS = 'GET_SEARCH_SUGGESTIONS';
export const START_GET_SEARCH_RESULTS = 'START_GET_SEARCH_RESULTS';
export const END_GET_SEARCH_RESULTS = 'END_GET_SEARCH_RESULTS';
export const START_GET_GEO_SEARCH_RESULTS = 'START_GET_GEO_SEARCH_RESULTS';
export const END_GET_GEO_SEARCH_RESULTS = 'END_GET_GEO_SEARCH_RESULTS';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const GET_EXACT_SEARCH_RESULT = 'GET_EXACT_SEARCH_RESULT';
export const GET_SEARCH_ERROR = 'GET_SEARCH_ERROR';
export const UPDATE_SEARCH_CRITERIA = 'UPDATE_SEARCH_CRITERIA';
export const UPDATE_SEARCH_CRITERIAS = 'UPDATE_SEARCH_CRITERIAS';
export const ADD_SEARCH_FILTER = 'ADD_SEARCH_FILTER';
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const REMOVE_SEARCH_FILTER = 'REMOVE_SEARCH_FILTER';
export const RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS';
export const RESET_SEARCH_FILTERS_CAMPSITES = 'RESET_SEARCH_FILTERS_CAMPSITES';
export const HIGHLIGHT_SEARCH_RESULT_ITEM = 'HIGHLIGHT_SEARCH_RESULT_ITEM';
export const GET_SEARCH_FILTER_AGGREGATIONS = 'GET_SEARCH_FILTER_AGGREGATIONS';
export const GET_SEARCH_FILTER_GROUP_AGGREGATIONS =
    'GET_SEARCH_FILTER_GROUP_AGGREGATIONS';
export const GET_BOUNDARY_SEARCH_RESULT = 'GET_BOUNDARY_SEARCH_RESULT';
export const GET_BOUNDARY_SEARCH_RESULT_ERROR = 'GET_BOUNDARY_SEARCH_RESULT_ERROR';
export const PREFERENCES_UPDATE_FAILED = 'PREFERENCES_UPDATE_FAILED';
export const PREFERENCES_UPDATE_SUCCESS = 'PREFERENCES_UPDATE_SUCCESS';
export const GET_TRAILS_SEARCH_RESULT = 'GET_TRAILS_SEARCH_RESULT';
export const GET_TRAILS_SEARCH_RESULT_ERROR = 'GET_TRAILS_SEARCH_RESULT_ERROR';
export const GET_EV_SEARCH_RESULT = 'GET_EV_SEARCH_RESULT';
export const GET_EV_SEARCH_RESULT_ERROR = 'GET_EV_SEARCH_RESULT_ERROR';

export const UPDATE_CHECKIN_DATE = 'UPDATE_CHECKIN_DATE';
export const UPDATE_CHECKOUT_DATE = 'UPDATE_CHECKOUT_DATE';

// navbar
export const SHOW_SEARCH_INPUT = 'SHOW_SEARCH_INPUT';
export const HIDE_SEARCH_INPUT = 'HIDE_SEARCH_INPUT';

// Page Flags
export const MAIN_SEARCH_RESULTS_PAGE = 'Main_Search_Results_Page';
export const CAMPSITE_PAGE = 'Campsite_Page';
export const CAMPGROUND_PAGE = 'Campground_Page';
export const CAMPGROUND_AVAILABILITY_PAGE = 'Campground_Availability_Page';

// launch darkly flags
export const SHOW_CAMPGROUND_SEARCH_RESULTS_IMAGES =
    'show-campground-search-results-images';
export const SHOW_MAP_OVERLAY_IMAGES = 'show-map-overlay-images';

// Search List and Map related action types
export const SEARCH_HANDLE_SELECTED_MAP_FEATURE = 'SEARCH_HANDLE_SELECTED_MAP_FEATURE';
export const SEARCH_HANDLE_SELECTED_CARD = 'SEARCH_HANDLE_SELECTED_CARD';
export const SEARCH_HANDLE_SEARCH_ON_MOVE = 'SEARCH_HANDLE_SEARCH_ON_MOVE';

export const CAL_DATE_FORMAT = 'MM/DD/YYYY';

export const SET_EQUIPMENTS_INVENTORY_COUNTS = 'SET_EQUIPMENTS_INVENTORY_COUNTS';
export const SET_SITE_TYPES_INVENTORY_COUNTS = 'SET_SITE_TYPES_INVENTORY_COUNTS';

export const CAMPSITE_EQUIPMENT_FILTER_TENT_OR = {
    key: 'campsite-equipment-tent',
    logic: 'OR',
    value: 'Tent',
    filters: [
        {
            attribute: '~campsite_equipment_name',
            filterValue: 'Tent',
        },
    ],
};

export const CAMPSITE_EQUIPMENT_FILTER_TENT_AND = {
    key: 'campsite-equipment-tent',
    logic: 'AND',
    value: 'Tent',
    filters: [
        {
            attribute: 'campsite_equipment_name',
            filterValue: 'Tent',
        },
    ],
};

export const CAMPSITE_EQUIPMENT_FILTER_RV_OR = {
    key: 'campsite-equipment-rv',
    logic: 'OR',
    value: 'RV/Motorhome',
    filters: [
        {
            attribute: '~campsite_equipment_name',
            filterValue: 'RV,RV/Motorhome',
        },
    ],
};

export const CAMPSITE_EQUIPMENT_FILTER_RV_AND = {
    key: 'campsite-equipment-rv',
    logic: 'AND',
    value: 'RV/Motorhome',
    filters: [
        {
            attribute: 'campsite_equipment_name',
            filterValue: 'RV,RV/Motorhome',
        },
    ],
};

export const CAMPSITE_EQUIPMENT_FILTERS = [
    CAMPSITE_EQUIPMENT_FILTER_TENT_AND,
    CAMPSITE_EQUIPMENT_FILTER_RV_AND,
    {
        key: 'campsite-equipment-trailer',
        value: 'Trailer',
        filters: [
            {
                attribute: 'campsite_equipment_name',
                filterValue: 'Trailer',
            },
        ],
    },
    {
        key: 'campsite-equipment-boat',
        value: 'Boat',
        filters: [
            {
                attribute: 'campsite_equipment_name',
                filterValue: 'Boat',
            },
        ],
    },
    {
        key: 'campsite-equipment-horse',
        value: 'Horse',
        filters: [
            {
                attribute: 'campsite_equipment_name',
                filterValue: 'Horse',
            },
        ],
    },
];

export const PRIMARY_CAMPSITE_EQUIPMENT_FILTERS_IDS = [
    CAMPSITE_EQUIPMENT_FILTERS[0].key,
    CAMPSITE_EQUIPMENT_FILTERS[1].key,
    CAMPSITE_EQUIPMENT_FILTERS[2].key,
];

export const CAMPSITE_TYPE_FILTERS = [
    {
        key: 'campsite-type-standard',
        value: 'Standard',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'STANDARD ELECTRIC,STANDARD NONELECTRIC',
            },
        ],
    },
    {
        key: 'campsite-type-group-standard',
        value: 'Group Standard',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'GROUP STANDARD ELECTRIC,GROUP STANDARD NONELECTRIC',
            },
        ],
    },
    {
        key: 'campsite-type-walkto',
        value: 'Walk/Hike To',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'WALK TO,GROUP WALK TO,HIKE TO',
            },
        ],
    },
    {
        key: 'campsite-type-tent-only',
        value: 'Tent Only',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue:
                    'TENT ONLY ELECTRIC,TENT ONLY NONELECTRIC,GROUP TENT ONLY AREA NONELECTRIC',
            },
        ],
    },
    {
        key: 'campsite-type-shelter',
        value: 'Shelter',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue:
                    'SHELTER ELECTRIC,SHELTER NONELECTRIC,GROUP SHELTER ELECTRIC,GROUP SHELTER NONELECTRIC,OVERNIGHT SHELTER ELECTRIC,OVERNIGHT SHELTER NONELECTRIC',
            },
        ],
    },
    {
        key: 'campsite-type-group-area',
        value: 'Group Area',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue:
                    'GROUP STANDARD AREA ELECTRIC,GROUP STANDARD AREA NONELECTRIC',
            },
        ],
    },
    {
        key: 'campsite-type-rv',
        value: 'RV',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'RV ELECTRIC,RV NONELECTRIC',
            },
        ],
    },
    {
        key: 'campsite-type-cabin',
        value: 'Cabin',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'CABIN ELECTRIC,CABIN NONELECTRIC',
            },
        ],
    },
    {
        key: 'campsite-type-lookout',
        value: 'Lookout',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'LOOKOUT',
            },
        ],
    },
    {
        key: 'campsite-type-horse-sites',
        value: 'Horse Sites',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue:
                    'EQUESTRIAN ELECTRIC,EQUESTRIAN NONELECTRIC,GROUP EQUESTRIAN',
            },
        ],
    },
    {
        key: 'campsite-type-boat-sites',
        value: 'Boat Sites',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'BOAT IN,MOORING',
            },
        ],
    },
    {
        key: 'campsite-type-yurts',
        value: 'Yurts',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'YURT',
            },
        ],
    },
    {
        key: 'campsite-type-picnic-area',
        value: 'Picnic Area',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'PICNIC,GROUP PICNIC AREA',
            },
        ],
    },
    {
        key: 'campsite-type-court',
        value: 'Court',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'COURT,BALL FIELD',
            },
        ],
    },
    {
        key: 'campsite-type-parking',
        value: 'Parking',
        filters: [
            {
                attribute: '~campsite_type',
                filterValue: 'PARKING',
            },
        ],
    },
];

export const PRIMARY_CAMPSITE_TYPE_FILTERS_IDS = [
    CAMPSITE_TYPE_FILTERS[0].key,
    CAMPSITE_TYPE_FILTERS[1].key,
    CAMPSITE_TYPE_FILTERS[2].key,
    CAMPSITE_TYPE_FILTERS[3].key,
    CAMPSITE_TYPE_FILTERS[6].key,
    CAMPSITE_TYPE_FILTERS[7].key,
    CAMPSITE_TYPE_FILTERS[8].key,
    CAMPSITE_TYPE_FILTERS[11].key,
];

export const CAMPSITE_ACCESSIBLE_FILTER = {
    key: 'campsite-amenities-accessibility',
    value: 'Accessibility',
    filters: [
        {
            attribute: 'campsite_accessible',
            filterValue: '1',
        },
    ],
};

export const CAMPSITE_AMENITY_FILTERS = [
    {
        key: 'campsite-amenities-electricity-hookup',
        value: 'Electricity Hookup',
        filters: [
            {
                attribute: 'att_electricity_hookup',
                filterValue:
                    '100 amp,100,50 amp,50,30 amp,30,15 amp,15,20 amp,20,5,5 amp,Electricity Hookup,20,50/30,100,15/30/50,5',
            },
        ],
    },
    {
        key: 'campsite-amenities-fire-pit',
        value: 'Fire Pit',
        filters: [
            {
                attribute: 'att_fire_pit',
                filterValue: 'Y,Fire Pit,Yes',
            },
        ],
    },
    {
        key: 'campsite-amenities-flush-toilet',
        value: 'Flush Toilet',
        filters: [
            {
                attribute: 'att_toilet',
                filterValue: 'Y,Yes,Toilet',
            },
        ],
    },
    {
        key: 'campsite-amenities-group-site',
        value: 'Group Site',
        filters: [
            {
                attribute: 'campsite_type',
                filterValue:
                    'GROUP STANDARD NONELECTRIC,GROUP SHELTER ELECTRIC,GROUP PICNIC AREA,GROUP TENT ONLY AREA NONELECTRIC,GROUP SHELTER NONELECTRIC,GROUP STANDARD ELECTRIC,GROUP EQUESTRIAN,GROUP STANDARD AREA ELECTRIC,GROUP STANDARD AREA NONELECTRIC,GROUP STANDARD AREA ELECTRIC,GROUP WALK TO,GROUP RV AREA NONELECTRIC',
            },
        ],
    },
    {
        key: 'campsite-amenities-pets-allowed',
        value: 'Pets Allowed',
        filters: [
            {
                attribute: 'att_pets_allowed',
                filterValue: 'Domestic,Horse,Yes,Pets Allowed',
            },
        ],
    },
    {
        key: 'campsite-amenities-pull-through-driveway',
        value: 'Pull Through Driveway',
        filters: [
            {
                attribute: 'att_driveway_entry',
                filterValue: 'Pull-Through,Pull-through',
            },
        ],
    },
    {
        key: 'campsite-amenities-sewer-hookup',
        value: 'Sewer Hookup',
        filters: [
            {
                attribute: 'att_sewer_hookup',
                filterValue: 'Y,Yes,Sewer Hookup',
            },
        ],
    },
    {
        key: 'campsite-amenities-shade',
        value: 'Shade',
        filters: [
            {
                attribute: 'att_shade',
                filterValue: 'Partial,Full,Shade,Yes',
            },
        ],
    },
    {
        key: 'campsite-amenities-water-hookup',
        value: 'Water Hookup',
        filters: [
            {
                attribute: 'att_water_hookup',
                filterValue: 'Y,Yes,Water Hookup',
            },
        ],
    },
    {
        key: 'campsite-amenities-waterfront',
        value: 'Waterfront',
        filters: [
            {
                attribute: 'att_proximity_to_water',
                filterValue: 'Lakefront,Riverfront,Oceanfront,Island,Springs',
            },
        ],
    },
];

export const PRIMARY_CAMPSITE_AMENITY_FILTERS_IDS = [
    CAMPSITE_AMENITY_FILTERS[0].key,
    CAMPSITE_AMENITY_FILTERS[2].key,
    CAMPSITE_AMENITY_FILTERS[3].key,
    CAMPSITE_AMENITY_FILTERS[4].key,
    CAMPSITE_AMENITY_FILTERS[6].key,
    CAMPSITE_AMENITY_FILTERS[9].key,
];

export const MAP_LAYER_CONTROL_CONTENT = [
    {
        icons: [POI_PIN],
        label: 'Points of Interest',
    },
    {
        icons: [REC_AREA_PIN],
        label: 'Recreation Areas',
    },
    {
        icons: [DAY_USE_PIN, DAY_USE_PIN_DISABLED],
        label: 'Day Use',
    },
    {
        icons: [CAMPING_PIN, CAMPING_PIN_DISABLED],
        label: 'Campgrounds',
    },
    {
        icons: [PERMITS_PIN],
        label: 'Permits',
    },
    {
        icons: [TREE_PERMITS_PIN],
        label: 'Tree Permits',
    },
    {
        icons: [PASSES_PIN, PASSES_PIN_DISABLED],
        label: 'Activity Passes',
    },
    {
        icons: [TICKET_FACILITY_PIN, TOURS_PIN],
        label: 'Tours',
    },
];

export const SEARCH_FILTER_AGENCIES_VALUES = [
    {
        key: 'BLM',
        hasCode: true,
        value: 'Bureau of Land Management',
        displayName: 'Bureau of Land Management',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '126',
            },
        ],
    },
    {
        key: 'Presidio Trust',
        hasCode: false,
        value: 'Presidio Trust',
        displayName: 'Presidio Trust',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '250',
            },
        ],
    },
    {
        key: 'BOR',
        hasCode: true,
        value: 'Bureau of Reclamation',
        displayName: 'Bureau of Reclamation',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '129',
            },
        ],
    },
    {
        key: 'USACE',
        hasCode: true,
        value: 'US Army Corps of Engineers',
        displayName: 'US Army Corps of Engineers',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '130',
            },
        ],
    },
    {
        key: 'NARA',
        hasCode: true,
        value: 'National Archives and Records Administration',
        displayName: 'National Archives and Records Administration',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '192',
            },
        ],
    },
    {
        key: 'FWS',
        hasCode: true,
        value: 'Fish and Wildlife Service',
        displayName: 'US Fish and Wildlife Service',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '127',
            },
        ],
    },
    {
        key: 'NPS',
        hasCode: true,
        value: 'National Park Service',
        displayName: 'National Park Service',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '128',
            },
        ],
    },
    {
        key: 'USFS',
        hasCode: true,
        value: 'USDA Forest Service',
        displayName: 'US Forest Service',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '131',
            },
        ],
    },
    {
        key: 'NAVY',
        hasCode: true,
        value: 'Commander, Navy Installation Command (CNIC)',
        displayName: 'Naval District Washington',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '260',
            },
        ],
    },
    {
        key: 'NOAA',
        hasCode: true,
        value: 'National Oceanic and Atmospheric Administration',
        displayName: 'National Oceanic and Atmospheric Administration',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '137',
            },
        ],
    },
    {
        key: 'NHL',
        hasCode: true,
        value: 'National Historic Landmark',
        displayName: 'National Historic Landmark',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '239',
            },
        ],
    },
    {
        key: 'NRHP',
        hasCode: true,
        value: 'National Register of Historic Places',
        displayName: 'National Register of Historic Places',
        filters: [
            {
                attribute: 'org_id',
                filterValue: '143',
            },
        ],
    },
];

export const MAP_LAYER_CONTROL_TYPES = ['mapSourceLayer', 'mapSourceCircleLayer'];

export const BOUNDARY_TYPES = {
    state: 'state',
    city: 'city',
    facility: 'facility',
};

// trip preferences keys
export const availabilityOptions = [
    {
        name: 'Only show locations with accessible campsites',
        value: 'campsite_accessible',
        id: 'campsiteaccessible',
    },
];

export const bookingPreferenceOptions = [
    { name: 'Hide unavailable', value: 'include_unavailable', id: 'includeunavailable' },
    {
        name: 'Hide partially available',
        value: 'include_partially_available',
        id: 'includepartiallyavailable',
    },
    {
        name: 'Hide first come, first served',
        value: 'include_notreservable',
        id: 'includenotreservable',
    },
];

export const allowedEquipmentOptions = [
    { name: 'Tent', value: 'campsite-equipment-tent', id: 'campsiteequipmenttent' },
    { name: 'RV / Motorhome', value: 'campsite-equipment-rv', id: 'campsiteequipmentrv' },
    {
        name: 'Trailer',
        value: 'campsite-equipment-trailer',
        id: 'campsiteequipmenttrailer',
    },
];

export const amenitiesOptions = [
    {
        name: 'Electric hookup',
        value: 'campsite-amenities-electricity-hookup',
        id: 'campsiteamenitieselectricityhookup',
    },
    {
        name: 'Water Hookup',
        value: 'campsite-amenities-water-hookup',
        id: 'campsiteamenitieswaterhookup',
    },
    {
        name: 'Sewer hookup',
        value: 'campsite-amenities-sewer-hookup',
        id: 'campsiteamenitiessewerhookup',
    },
    {
        name: 'Pull-through driveway',
        value: 'campsite-amenities-pull-through-driveway',
        id: 'campsiteamenitiespullthroughdriveway',
    },
    { name: 'Shade', value: 'campsite-amenities-shade', id: 'campsiteamenitiesshadee' },
    {
        name: 'Pets allowed',
        value: 'campsite-amenities-pets-allowed',
        id: 'campsiteamenitiespetsallowed',
    },
];

export const preferencesOptions = [
    {
        Name: 'Only show locations with accessible campsites',
        Value: 'campsite_accessible',
        Category: 'accessibility',
    },
    {
        Name: 'Tent',
        Value: 'campsite-equipment-tent',
        Category: 'booking_preferences',
    },
    {
        Name: 'RV / Motorhome',
        Value: 'campsite-equipment-rv',
        Category: 'booking_preferences',
    },
    {
        Name: 'Trailer',
        Value: 'campsite-equipment-trailer',
        Category: 'booking_preferences',
    },
    {
        Name: 'Hide unavailable',
        Value: 'include_unavailable',
        Category: 'allowed_equipment',
    },
    {
        Name: 'Hide partially available',
        Value: 'include_partially_available',
        Category: 'allowed_equipment',
    },
    {
        Name: 'Hide first come, first served',
        Value: 'include_notreservable',
        Category: 'allowed_equipment',
    },
    {
        Name: 'Electric hookup',
        Value: 'campsite-amenities-electricity-hookup',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Water Hookup',
        Value: 'campsite-amenities-water-hookup',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Sewer hookup',
        Value: 'campsite-amenities-sewer-hookup',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Pull-through driveway',
        Value: 'campsite-amenities-pull-through-driveway',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Shade',
        Value: 'campsite-amenities-shade',
        Category: 'site_features_amenities',
    },
    {
        Name: 'Pets allowed',
        Value: 'campsite-amenities-pets-allowed',
        Category: 'site_features_amenities',
    },
];

export const SEARCH_FILTER_ACCOMODATIONS = [
    {
        key: 'accommodations-tent-camping',
        value: 'Tent Camping',
        filters: [
            {
                attribute: 'accommodation_types',
                filterValue: 'tent,small tent,large tent over 9x12`',
            },
        ],
    },
    {
        key: 'accommodations-rv',
        value: 'RV Sites',
        filters: [
            {
                attribute: 'accommodation_types',
                filterValue:
                    'pop up,trailer,pickup camper,caravan/camper van,fifth wheel',
            },
        ],
    },
    {
        key: 'accommodations-tent-cabins',
        value: 'Cabins / Yurts',
        filters: [
            {
                attribute: 'accommodation_types',
                filterValue: 'cabin electric,cabin nonelectric,yurt',
            },
        ],
    },
    {
        key: 'accommodations-day-use',
        value: 'Day Use Facilities',
        filters: [
            {
                attribute: 'accommodation_types',
                filterValue: 'day',
            },
        ],
    },
];

export const SEARCH_FILTER_ACTIVITIES = [
    {
        key: 'activity-auto-touring',
        value: 'Auto Touring',
    },
    {
        key: 'activity-biking',
        value: 'Biking',
    },
    {
        key: 'activity-boating',
        value: 'Boating',
    },
    {
        key: 'activity-camping',
        value: 'Camping',
    },
    {
        key: 'activity-climbing',
        value: 'Climbing',
    },
    {
        key: 'activity-day-use-area',
        value: 'Day Use Area',
    },
    {
        key: 'activity-diving',
        value: 'Diving',
    },
    {
        key: 'activity-documentary-site',
        value: 'Documentary Site',
    },
    {
        key: 'activity-environmental-education',
        value: 'Environmental Education',
    },
    {
        key: 'activity-fire-lookouts-cabins-overnight',
        value: 'Fire Lookouts/Cabins Overnight',
    },
    {
        key: 'activity-fish-hatchery',
        value: 'Fish Hatchery',
    },
    {
        key: 'activity-fish-viewing-site',
        value: 'Fish Viewing Site',
    },
    {
        key: 'activity-fishing',
        value: 'Fishing',
    },
    {
        key: 'activity-hiking',
        value: 'Hiking',
    },
    {
        key: 'activity-historic-and-cultural-site',
        value: 'Historic & Cultural Site',
    },
    {
        key: 'activity-horse-camping',
        value: 'Horse Camping',
    },
    {
        key: 'activity-horseback-riding',
        value: 'Horseback Riding',
    },
    {
        key: 'activity-hotel-lodge-resort-fs-owned',
        value: 'Hotel/Lodge/Resort Fs Owned',
    },
    {
        key: 'activity-hotel-lodge-resort-privately-owned',
        value: 'Hotel/Lodge/Resort Privately Owned',
    },
    {
        key: 'activity-hunting',
        value: 'Hunting',
    },
    {
        key: 'activity-information-site',
        value: 'Information Site',
    },
    {
        key: 'activity-interpretive-programs',
        value: 'Interpretive Programs',
    },
    {
        key: 'activity-observation-site',
        value: 'Observation Site',
    },
    {
        key: 'activity-off-highway-vehicle',
        value: 'Off Highway Vehicle',
    },
    {
        key: 'activity-other-recreation-concession-site',
        value: 'Other Recreation Concession Site',
    },
    {
        key: 'activity-paddling',
        value: 'Paddling',
    },
    {
        key: 'activity-photography',
        value: 'Photography',
    },
    {
        key: 'activity-picnicking',
        value: 'Picnicking',
    },
    {
        key: 'activity-playground-park-specialized-sport-site',
        value: 'Playground Park Specialized Sport Site',
    },
    {
        key: 'activity-recreational-shooting',
        value: 'Recreational Shooting',
    },
    {
        key: 'activity-recreational-vehicles',
        value: 'Recreational Vehicles',
    },
    {
        key: 'activity-snorkeling',
        value: 'Snorkeling',
    },
    {
        key: 'activity-snowpark',
        value: 'Snowpark',
    },
    {
        key: 'activity-swimming',
        value: 'Swimming',
    },
    {
        key: 'activity-visitor-center',
        value: 'Visitor Center',
    },
    {
        key: 'activity-water-sports',
        value: 'Water Sports',
    },
    {
        key: 'activity-wilderness',
        value: 'Wilderness',
    },
    {
        key: 'activity-wildlife-viewing',
        value: 'Wildlife Viewing',
    },
    {
        key: 'activity-winter-sports',
        value: 'Winter Sports',
    },
];

export const SEARCH_FILTER_CAMPSITE_ELECTRICAL = [
    {
        key: 'campsite-elec-5',
        value: '5 amp',
        filters: [
            {
                attribute: 'att_electricity_hookup',
                aggregation: 'campsiteElectricityHookup',
                filterValue: '5,5 amp',
            },
        ],
    },
    {
        key: 'campsite-elec-15',
        value: '15 amp',
        filters: [
            {
                attribute: 'att_electricity_hookup',
                aggregation: 'campsiteElectricityHookup',
                filterValue: '15 amp,15,15/30/50',
            },
        ],
    },
    {
        key: 'campsite-elec-20',
        value: '20 amp',
        filters: [
            {
                attribute: 'att_electricity_hookup',
                aggregation: 'campsiteElectricityHookup',
                filterValue: '20,20 amp',
            },
        ],
    },
    {
        key: 'campsite-elec-30',
        value: '30 amp',
        filters: [
            {
                attribute: 'att_electricity_hookup',
                aggregation: 'campsiteElectricityHookup',
                filterValue: '30 amp,30,50/30,15/30/50',
            },
        ],
    },
    {
        key: 'campsite-elec-50',
        value: '50 amp',
        filters: [
            {
                attribute: 'att_electricity_hookup',
                aggregation: 'campsiteElectricityHookup',
                filterValue: '50 amp,50,50/30,15/30/50',
            },
        ],
    },
    {
        key: 'campsite-elec-100',
        value: '100 amp',
        filters: [
            {
                attribute: 'att_electricity_hookup',
                aggregation: 'campsiteElectricityHookup',
                filterValue: '100,100 amp',
            },
        ],
    },
];

export const SEARCH_FILTER_CAMPSITE_USE_TYPES = [
    {
        key: 'campsite-use-overnight',
        value: 'Camping',
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Overnight',
            },
        ],
    },
    {
        key: 'campsite-use-day',
        value: 'Day',
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Day,Day Use',
            },
        ],
    },
];

export const SEARCH_FILTER_VEHICLE_LENGTH = [
    {
        key: 'campsiteVehicleLength',
        value: '',
        filters: [
            {
                attribute: 'campsite_equipment_max_length',
                filterValue: ``,
            },
        ],
    },
];

export const SEARCH_FILTER_PERMIT_TYPES = [
    {
        key: 'permit-activity-auto-touring',
        value: 'Auto Touring',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Auto Touring',
            },
        ],
    },
    {
        key: 'permit-activity-boating',
        value: 'Boating',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Boating',
            },
        ],
    },
    {
        key: 'permit-activity-climbing',
        value: 'Climbing',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Climbing',
            },
        ],
    },
    {
        key: 'permit-activity-fishing',
        value: 'Fishing',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Fishing',
            },
        ],
    },
    {
        key: 'permit-activity-hiking',
        value: 'Hiking',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Hiking',
            },
        ],
    },
    {
        key: 'permit-activity-horseback-riding',
        value: 'Horseback Riding',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Horseback Riding',
            },
        ],
    },
    {
        key: 'permit-activity-hunting',
        value: 'Hunting',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Hunting',
            },
        ],
    },
    {
        key: 'permit-activity-off-highway-vehicle',
        value: 'Off Highway Vehicle',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Off Highway Vehicle',
            },
        ],
    },
    {
        key: 'permit-activity-paddling',
        value: 'Paddling',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Paddling',
            },
        ],
    },
    {
        key: 'permit-activity-picnicking',
        value: 'Picnicking',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Picnicking',
            },
        ],
    },
    {
        key: 'permit-activity-snorkeling',
        value: 'Snorkeling',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Snorkeling',
            },
        ],
    },
    {
        key: 'permit-activity-wildlife-viewing',
        value: 'Wildlife Viewing',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Wildlife Viewing',
            },
        ],
    },
    {
        key: 'permit-activity-winter-sports',
        value: 'Winter Sports',
        filters: [
            {
                attribute: 'permit_activities',
                filterValue: 'Winter Sports',
            },
        ],
    },
];

export const SEARCH_FILTER_START_TIME = [
    {
        key: 'tour-times-early-morning',
        value: 'Early Morning (Midnight - 7:59 AM)',
        filters: [
            {
                attribute: 'tour_times',
                filterValue: 'early_morning',
            },
        ],
    },
    {
        key: 'tour-times-morning',
        value: 'Morning (8 - 11:59 AM)',
        filters: [
            {
                attribute: 'tour_times',
                filterValue: 'morning',
            },
        ],
    },
    {
        key: 'tour-times-early-afternoon',
        value: 'Early Afternoon (12 - 2:59 PM)',
        filters: [
            {
                attribute: 'tour_times',
                filterValue: 'early_afternoon',
            },
        ],
    },
    {
        key: 'tour-times-afternoon',
        value: 'Afternoon (3 - 5:59 PM)',
        filters: [
            {
                attribute: 'tour_times',
                filterValue: 'afternoon',
            },
        ],
    },
    {
        key: 'tour-times-evening',
        value: 'Evening (6 - 8:59 PM)',
        filters: [
            {
                attribute: 'tour_times',
                filterValue: 'evening',
            },
        ],
    },
    {
        key: 'tour-times-late-night',
        value: 'Late Night (9 - 11:59 PM)',
        filters: [
            {
                attribute: 'tour_times',
                filterValue: 'late_night',
            },
        ],
    },
];

export const SEARCH_FILTER_TOUR_ACCESSIBLE = [
    {
        key: 'tour-accessible',
        value: 'Accessible',
        filters: [
            {
                attribute: 'tour_accessible',
                filterValue: 'Yes',
            },
        ],
    },
];

export const SEARCH_FILTER_TOUR_DIFFICULTY = [
    {
        key: 'tour-diff-easy',
        value: 'Easy',
        filters: [
            {
                attribute: 'att_physical_effort',
                filterValue: 'Easy',
            },
        ],
    },
    {
        key: 'tour-diff-Moderately easy',
        value: 'Moderately easy',
        filters: [
            {
                attribute: 'att_physical_effort',
                filterValue: 'Moderately easy',
            },
        ],
    },
    {
        key: 'tour-diff-Moderate',
        value: 'Moderate',
        filters: [
            {
                attribute: 'att_physical_effort',
                filterValue: 'Moderate',
            },
        ],
    },
    {
        key: 'tour-diff-Moderately difficult',
        value: 'Moderately difficult',
        filters: [
            {
                attribute: 'att_physical_effort',
                filterValue: 'Moderately difficult',
            },
        ],
    },
    {
        key: 'tour-diff-Difficult',
        value: 'Difficult',
        filters: [
            {
                attribute: 'att_physical_effort',
                filterValue: 'Difficult',
            },
        ],
    },
    {
        key: 'tour-diff-Moderately Strenuous',
        value: 'Moderately Strenuous',
        filters: [
            {
                attribute: 'att_physical_effort',
                filterValue: 'Moderately Strenuous',
            },
        ],
    },
    {
        key: 'tour-diff-Strenuous',
        value: 'Strenuous',
        filters: [
            {
                attribute: 'att_physical_effort',
                filterValue: 'Strenuous',
            },
        ],
    },
    {
        key: 'tour-diff-Very strenuous',
        value: 'Very strenuous',
        filters: [
            {
                attribute: 'att_physical_effort',
                filterValue: 'Very strenuous',
            },
        ],
    },
];

export const SEARCH_FILTER_TOUR_TYPE = [
    {
        key: 'tour-type-Historic Tour',
        value: 'Historic Tour',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Historic Tour',
            },
        ],
    },
    {
        key: 'tour-type-Nature Tour',
        value: 'Nature Tour',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Nature Tour',
            },
        ],
    },
    {
        key: 'tour-type-Cave Tour',
        value: 'Cave Tour',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Cave Tour',
            },
        ],
    },
    {
        key: 'tour-type-Nature Hike',
        value: 'Nature Hike',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Nature Hike',
            },
        ],
    },
    {
        key: 'tour-type-Combo Tour',
        value: 'Combo Tour',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Combo Tour',
            },
        ],
    },
    {
        key: 'tour-type-Cliff Dwelling Tour',
        value: 'Cliff Dwelling Tour',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Cliff Dwelling Tour',
            },
        ],
    },
    {
        key: 'tour-type-Boat Tour',
        value: 'Boat Tour',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Boat Tour',
            },
        ],
    },
    {
        key: 'tour-type-Natural and Cultural Hike',
        value: 'Natural and Cultural Hike',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Natural and Cultural Hike',
            },
        ],
    },
    {
        key: 'tour-type-Hike',
        value: 'Hike',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Hike',
            },
        ],
    },
    {
        key: 'tour-type-Movie Tour',
        value: 'Movie Tour',
        filters: [
            {
                attribute: 'tour_type',
                filterValue: 'Movie Tour',
            },
        ],
    },
];

export const SEARCH_FILTER_INVENTORY_VALUES = [
    {
        key: 'camping',
        value: 'Camping',
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Overnight,na',
            },
            {
                attribute: 'entity_type',
                filterValue: 'campground',
            },
        ],
    },
    {
        key: 'dayuse',
        value: 'Day Use',
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Day',
            },
            {
                attribute: 'entity_type',
                filterValue: 'campground',
            },
        ],
    },
    {
        key: 'poi',
        value: 'Points of Interest',
        filters: [
            {
                attribute: 'entity_type',
                filterValue:
                    'recarea,facility,cemeteryandmemorial,library,archives,museum,kiosk,nationalfishhatchery',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'activitypass',
        value: 'Activity Pass',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'activitypass',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'tours',
        value: 'Tickets & Tours',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'ticketfacility,tour,timedentry,timedentry_tour',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'venuereservations',
        value: 'Venues',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'venuereservations,venuereservations_venue',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'recarea',
        value: 'Rec Areas',
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'recarea',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
];

export const SEARCH_FILTER_OCCUPANTS = [
    {
        key: 'occupants',
        filters: [
            {
                attribute: 'att_max_num_of_people',
                filterValue: ``,
            },
        ],
    },
];

export const ALL_FILTERS = [
    ...CAMPSITE_EQUIPMENT_FILTERS,
    CAMPSITE_EQUIPMENT_FILTER_TENT_OR,
    CAMPSITE_EQUIPMENT_FILTER_RV_OR,
    ...SEARCH_FILTER_CAMPSITE_ELECTRICAL,
    ...CAMPSITE_TYPE_FILTERS,
    CAMPSITE_ACCESSIBLE_FILTER,
    ...CAMPSITE_AMENITY_FILTERS,
    ...SEARCH_FILTER_AGENCIES_VALUES,
    ...SEARCH_FILTER_ACCOMODATIONS,
    ...SEARCH_FILTER_ACTIVITIES,
    ...SEARCH_FILTER_CAMPSITE_USE_TYPES,
    ...SEARCH_FILTER_VEHICLE_LENGTH,
    ...SEARCH_FILTER_PERMIT_TYPES,
    ...SEARCH_FILTER_START_TIME,
    ...SEARCH_FILTER_TOUR_ACCESSIBLE,
    ...SEARCH_FILTER_TOUR_DIFFICULTY,
    ...SEARCH_FILTER_TOUR_TYPE,
    ...SEARCH_FILTER_INVENTORY_VALUES,
    ...SEARCH_FILTER_OCCUPANTS,
];

export const ALL_FILTER_KEYS = {
    campsiteUseTypes: ['campsite_type_of_use'],
    campsiteTypes: ['campsite_type', '~campsite_type'],
    campsiteElectricityHookup: ['att_electricity_hookup'],
    campsiteAmenities: [
        'att_fire_pit',
        'att_electricity_hookup',
        'att_toilet',
        'campsite_type',
        'att_pets_allowed',
        'att_driveway_entry',
        'att_sewer_hookup',
        'att_shade',
        'att_water_hookup',
        'att_proximity_to_water',
    ],
    campsiteVehicleLength: ['campsite_equipment_max_length'],
    campsiteEquipment: ['campsite_equipment_name', '~campsite_equipment_name'],
    activities: ['asset_activities'],
    agencies: ['org_id'],
    occupants: ['att_max_num_of_people'],
    inventoryTypes: ['entity_type', 'campsite_type_of_use'],
    tourTypes: ['tour_type'],
    tourAccessible: ['tour_accessible'],
    tourDifficulty: ['att_physical_effort'],
    tourTimes: ['tour_times'],
    permitTypes: ['permit_activities'],
};

export const CAMPSITE_AMENITY_ELECTRICAL_HOOKUP = {
    key: 'campsite-amenities-electricity-hookup',
    legacyKey: 'campsite-amenities-electricity-hookup',
    value: 'Electricity Hookup',
    filters: [
        {
            attribute: 'att_electricity_hookup',
            filterValue:
                '100 amp,100,50 amp,50,30 amp,30,15 amp,15,20 amp,20,5,5 amp,Electricity Hookup,20,50/30,100,15/30/50,5',
        },
    ],
};

export const uiFiltersGlossary = [
    {
        key: 'agency',
        value: 'agencies',
        filters: SEARCH_FILTER_AGENCIES_VALUES,
    },
    {
        key: 'permit-type',
        value: 'permitTypes',
        filters: SEARCH_FILTER_PERMIT_TYPES,
    },
    {
        key: 'tour-accessible',
        value: 'tourAccessible',
        filters: SEARCH_FILTER_TOUR_ACCESSIBLE,
    },
    {
        key: 'tour-time',
        value: 'tourTimes',
        filters: SEARCH_FILTER_START_TIME,
    },
    {
        key: 'tour-type',
        value: 'tourTypes',
        filters: SEARCH_FILTER_TOUR_TYPE,
    },
    {
        key: 'tour-difficulty',
        value: 'tourDifficulty',
        filters: SEARCH_FILTER_TOUR_DIFFICULTY,
    },
    {
        key: 'campsite_amenity',
        value: 'campsiteAmenities',
        filters: CAMPSITE_AMENITY_FILTERS,
    },
    {
        key: 'campsite_equipment',
        value: 'campsiteEquipment',
        filters: CAMPSITE_EQUIPMENT_FILTERS,
    },
    {
        key: 'campsite_type',
        value: 'campsiteTypes',
        filters: CAMPSITE_TYPE_FILTERS,
    },
];

export const SEARCH_INITIAL_STATE = {
    showSearchInput: true,
    what: '',
    headerTextQuery: '',
    where: '',
    location: '',
    show_ai_search: false,
    ai_query: '',
    lat: null,
    lng: null,
    lng_sw: null,
    lat_sw: null,
    lng_ne: null,
    lat_ne: null,
    lngSw: null,
    latSw: null,
    lngNe: null,
    latNe: null,
    map_center_lat: null,
    map_center_lng: null,
    radius: null,
    placeid: null,
    placename: null,
    start: null,
    sort: null,
    size: null,
    checkin_time: null,
    checkout_time: null,
    highlighted_item: -1,
    uiFilters: {
        hasFilters: false,
        accommodations: {},
        agencies: {},
        campsiteUseTypes: {},
        campsiteTypes: {},
        campsiteAmenities: {},
        campsiteElectricityHookup: {},
        campsiteVehicleLength: {},
        campsiteEquipment: {},
        activities: {},
        occupants: {},
        inventoryTypes: {},
        tourTypes: {},
        tourAccessible: {},
        tourDifficulty: {},
        tourDuration: {},
        tourPrice: {},
        tourTime: {},
        tourTimes: {},
        permitTypes: {},
        reservable: {},
        lastAppliedFilter: null,
        includeUnavailable: true,
    },
    inventoryTypeFiltersVisible: false,
    filtersVisible: false,
    filters: [],
    aggregations: [],
    fg: [],
};
