/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable react/jsx-props-no-spreading */
/**
 * MapSearchBox.jsx
 * Created by michael bray on 8/2/18.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import debounce from 'lodash/debounce';

import { Icon, Button, Label } from 'sarsaparilla';

export default function MapSearchBox({
    selectedMapSearchTerm,
    updateSelectedMapSearchTerm,
    performMapSearch,
    clearSuggestions,
    suggestions,
    selectSearchResult,
    dynamicClassName,
}) {
    const performDebouncedMapSearch = debounce(
        (searchTerm) => {
            performMapSearch(searchTerm);
        },
        100,
        { leading: 100 }
    );

    const [savedSearchTerm, setSavedSearchTerm] = useState(null);

    const changedLocation = (val) => {
        if (val) {
            performDebouncedMapSearch(val);
        } else if (suggestions.length) {
            clearSuggestions();
        }
    };

    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectItem,
        setInputValue,
        inputValue: comboboxValue,
    } = useCombobox({
        items: suggestions,
        onInputValueChange: ({ inputValue }) => {
            changedLocation(inputValue);
        },
        itemToString(item) {
            return item ? item.label : '';
        },
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                const { label, searchResultItem } = selectedItem;

                selectSearchResult(label, searchResultItem);
            }
        },
        stateReducer: (state, { type, changes }) => {
            // If the user presses enter, choose the first selection in the dropdown for them
            if (type === useCombobox.stateChangeTypes.InputKeyDownEnter) {
                if (suggestions.length && highlightedIndex < 0) {
                    const firstSuggestion = suggestions[0];

                    selectSearchResult(
                        firstSuggestion.label,
                        firstSuggestion.searchResultItem
                    );
                    return {
                        ...state,
                        selectedItem: firstSuggestion,
                    };
                }
            }

            return changes;
        },
    });

    useEffect(() => {
        if (sessionStorage && sessionStorage.getItem('savedLocation')) {
            const savedLocation = JSON.parse(sessionStorage.getItem('savedLocation'));

            if (savedLocation.searchTerm) {
                const criteria = savedLocation.searchTerm;

                setSavedSearchTerm(criteria);
                setInputValue(criteria);
                updateSelectedMapSearchTerm(criteria);
                performMapSearch(criteria);
            }
        }
    }, [setInputValue, updateSelectedMapSearchTerm, performMapSearch]);

    useEffect(() => {
        setInputValue(selectedMapSearchTerm);
    }, [selectedMapSearchTerm, setInputValue]);

    useEffect(() => {
        if (
            savedSearchTerm &&
            suggestions.length &&
            suggestions[0]?.label === savedSearchTerm
        ) {
            setSavedSearchTerm(null);

            if (suggestions.length) {
                const firstSuggestion = suggestions[0];

                selectSearchResult(
                    firstSuggestion.label,
                    firstSuggestion.searchResultItem
                );
            }
        }
    }, [suggestions, selectSearchResult, savedSearchTerm]);

    const onClearFunc = () => {
        selectItem(null);
        clearSuggestions();
        updateSelectedMapSearchTerm('');
        if (sessionStorage) {
            sessionStorage.removeItem('savedLocation');
        }
    };

    return (
        <div className={dynamicClassName} autoComplete="off">
            <div className="sarsa-field-outer-container">
                <div className="sarsa-field-label-wrapper">
                    <Label
                        htmlFor="nav-map-search-by-state"
                        className="nav-flex-search-title"
                        {...getLabelProps()}
                    >
                        Find Destinations
                    </Label>
                </div>

                <div className="sarsa-field-inner-container">
                    <span className="sarsa-field-icon-content left">
                        <Icon iconName="search" />
                    </span>

                    <input
                        id="nav-map-search-by-state"
                        placeholder="Search by city, state, or zip code"
                        {...getInputProps()}
                    />

                    {comboboxValue && (
                        <span className="sarsa-text-field-button-wrapper">
                            <Button
                                appearance="subtle"
                                size="xs"
                                iconBeforeElement={<Icon iconName="close" />}
                                screenReaderTextAfter="Clear field contents"
                                className="sarsa-text-field-clear-button"
                                onClick={onClearFunc}
                                gaTrackingId="391315499346"
                            />
                        </span>
                    )}
                </div>
                <ul
                    {...getMenuProps()}
                    className={`nav-map-search-results ${
                        isOpen && suggestions.length ? 'open' : ''
                    }`}
                >
                    {isOpen &&
                        suggestions.map((suggestion, index) => (
                            <li
                                key={`${suggestion.searchResultText}${index}`}
                                className={`${
                                    highlightedIndex === index ? 'highlighted' : ''
                                }`}
                                {...getItemProps({ item: suggestion, index })}
                            >
                                <Button
                                    type="button"
                                    className="rec-button-link"
                                    isUnstyled
                                    gaTrackingId="422931343613"
                                >
                                    {suggestion.searchResultText}
                                </Button>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
}

MapSearchBox.propTypes = {
    selectedMapSearchTerm: PropTypes.string,
    updateSelectedMapSearchTerm: PropTypes.func,
    performMapSearch: PropTypes.func,
    clearSuggestions: PropTypes.func,
    suggestions: PropTypes.array,
    selectSearchResult: PropTypes.func,
    dynamicClassName: PropTypes.string,
};

MapSearchBox.defaultProps = {
    selectedMapSearchTerm: '',
    suggestions: [],
    dynamicClassName: 'nav-map-search-input',
};
