/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import '../css/_ExpandMapButton.scss';

import cx from 'classnames';
import { Icon } from 'sarsaparilla';
import React from 'react';
import { createRoot } from 'react-dom/client';

type ExpandMapButtonProps = {
    onExpand: (v: boolean) => void;
    expanded: boolean;
};

export function ExpandMapButton({ onExpand, expanded = false }: ExpandMapButtonProps) {
    const [isExpanded, setIsExpanded] = React.useState(expanded);
    const handleClick = () => {
        setIsExpanded(!isExpanded);
        onExpand(isExpanded);
    };

    React.useEffect(() => {
        if (expanded) {
            onExpand(true);
        }
    }, [expanded, onExpand]);

    return (
        <div className="mapboxgl-ctrl-text-group">
            <button
                className="expand-map-button"
                onClick={handleClick}
                data-ga-tracking-id="866374897906"
                data-ga-tracking-value={isExpanded ? 'Show List' : 'Expand'}
                type="button"
            >
                <Icon
                    size="lg"
                    iconName={isExpanded ? 'chevron-right' : 'chevron-left'}
                />
                <span
                    className={cx(
                        'expand-map-button__text',
                        isExpanded && 'expand-map-button__text--open'
                    )}
                >
                    Show List
                </span>
            </button>
        </div>
    );
}

interface ExpandMapControlProps {
    isMapExpanded: boolean;
    onExpandMap: (v: boolean) => void;
}

class ExpandMapControl {
    container: HTMLElement;
    map?: mapboxgl.Map;
    root: any;
    onExpand: (v: boolean) => void;
    isMapExpanded: boolean;

    constructor({ onExpandMap, isMapExpanded }: ExpandMapControlProps) {
        this.onExpand = onExpandMap;
        this.isMapExpanded = isMapExpanded;
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl';
        this.update = this.update.bind(this);
    }

    onAdd(map: mapboxgl.Map): HTMLElement {
        this.map = map;
        this.update();
        return this.container;
    }

    onRemove(): void {
        this.container.parentNode?.removeChild(this.container);
        this.map = undefined;
    }

    update(): void {
        const root = createRoot(this.container);
        root.render(
            <ExpandMapButton onExpand={this.onExpand} expanded={this.isMapExpanded} />
        );
    }
}

export default ExpandMapControl;
