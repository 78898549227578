/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { FlexRow, FlexCol, Heading, ImageWithFallback } from 'sarsaparilla';
import { MobileAppButtons } from './MobileAppButtons';

import { RecGovAppIcon } from '../../Icons/App/RecGovAppIcon';
import heroPhoneImageWebp from '../images/hero-phone-image.webp';
import heroPhoneImagePng from '../images/hero-phone-image.png';

export function MobileAppHero() {
    return (
        <section className="nav-mobile-app-hero">
            <div className="nav-mobile-app-hero-content">
                <FlexRow justifyContent="center">
                    <FlexCol xlOffset={1} xl={11} lg={12}>
                        <FlexRow>
                            <FlexCol lg={6}>
                                <div className="nav-mobile-app-hero-header">
                                    <div className="nav-mobile-app-hero-title">
                                        <span className="nav-mobile-app-icon">
                                            <RecGovAppIcon />
                                        </span>

                                        <Heading headingLevel={1} appearance="h1">
                                            Recreation.gov Mobile App
                                        </Heading>
                                    </div>
                                    <p>
                                        Adventure is at your fingertips with the
                                        Recreation.gov mobile app! From booking a weekend
                                        getaway to planning a cross-country road trip, the
                                        Recreation.gov app helps you find and reserve
                                        campsites, review location details for your trip,
                                        and quickly access information on past and
                                        upcoming reservations.
                                    </p>
                                    <div className="nav-mobile-app-hero-actions">
                                        <MobileAppButtons />
                                    </div>
                                </div>
                            </FlexCol>
                            <FlexCol lg={6}>
                                <div className="nav-mobile-app-hero-media">
                                    <div className="nav-mobile-app-hero-media-item">
                                        <ImageWithFallback
                                            src={heroPhoneImageWebp}
                                            fallbackSrc={heroPhoneImagePng}
                                            alt="mobile app splash screen on white iPhone"
                                        />
                                    </div>
                                </div>
                            </FlexCol>
                        </FlexRow>
                    </FlexCol>
                </FlexRow>
            </div>
            <div className="nav-mobile-app-hero-background" />
        </section>
    );
}
