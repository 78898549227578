/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { DropdownMenu, Checkbox, Icon } from 'sarsaparilla';
import { useState } from 'react';

type TourTimeFilterType = {
    earlyMorning: boolean;
    morning: boolean;
    earlyAfternoon: boolean;
    afternoon: boolean;
    evening: boolean;
    lateNight: boolean;
};

type TourTimeFilterProps = {
    onTourTimeChange: (tourTimeFilter: TourTimeFilterType) => void;
};

export default function TourTimeFilter({ onTourTimeChange }: TourTimeFilterProps) {
    const [filters, setFilters] = useState({
        earlyMorning: false,
        morning: false,
        earlyAfternoon: false,
        afternoon: false,
        evening: false,
        lateNight: false,
    });

    const handleFilterChange = (key: keyof typeof filters) => {
        setFilters((prev) => {
            return {
                ...prev,
                [key]: !filters[key],
            };
        });

        onTourTimeChange({
            ...filters,
            [key]: !filters[key],
        });
    };

    const handleClearFilters = () => {
        setFilters({
            earlyMorning: false,
            morning: false,
            earlyAfternoon: false,
            afternoon: false,
            evening: false,
            lateNight: false,
        });
    };

    function getLabel() {
        let labelText = '';

        if (filters.earlyMorning) {
            labelText = 'Early Morning';
        }
        if (filters.morning) {
            labelText = labelText ? `${labelText}, Morning` : 'Morning';
        }
        if (filters.earlyAfternoon) {
            labelText = labelText ? `${labelText}, Early Afternoon` : 'Early Afternoon';
        }
        if (filters.afternoon) {
            labelText = labelText ? `${labelText}, Afternoon` : 'Afternoon';
        }
        if (filters.evening) {
            labelText = labelText ? `${labelText}, Evening` : 'Evening';
        }
        if (filters.lateNight) {
            labelText = labelText ? `${labelText}, Late Night` : 'Late Night';
        }
        if (labelText.length > 20) {
            labelText = `${labelText.slice(0, 20)}...`;
        }

        return (
            <div className="tour-time-filter-button">
                <span className="tour-time-filter-icon">
                    <Icon iconName="clock" size="sm" />
                </span>
                <span className="tour-time-filter-text">
                    {labelText.length > 0 ? labelText : 'Time'}
                </span>
            </div>
        );
    }

    return (
        <DropdownMenu
            label={getLabel()}
            className="tour-time-filter-dropdown-menu"
            applyButtonLabel="Clear All Filters"
            onApply={handleClearFilters}
            size="lg"
        >
            <div className="mb-2">
                <Checkbox
                    label="Early Morning (Midnight - 7:59 AM)"
                    id="earlyMorning"
                    isChecked={filters.earlyMorning}
                    onChange={() => handleFilterChange('earlyMorning')}
                />
            </div>
            <div className="mb-2">
                <Checkbox
                    label="Morning (8:00 AM - 11:59 AM)"
                    id="morning"
                    isChecked={filters.morning}
                    onChange={() => handleFilterChange('morning')}
                />
            </div>
            <div className="mb-2">
                <Checkbox
                    label="Early Afternoon (12:00 PM - 2:59 PM)"
                    id="earlyAfternoon"
                    isChecked={filters.earlyAfternoon}
                    onChange={() => handleFilterChange('earlyAfternoon')}
                />
            </div>
            <div className="mb-2">
                <Checkbox
                    label="Afternoon (3:00 PM - 5:59 PM)"
                    id="afternoon"
                    isChecked={filters.afternoon}
                    onChange={() => handleFilterChange('afternoon')}
                />
            </div>
            <div className="mb-2">
                <Checkbox
                    label="Evening (6:00 PM - 8:59 PM)"
                    id="evening"
                    isChecked={filters.evening}
                    onChange={() => handleFilterChange('evening')}
                />
            </div>
            <div className="mb-2">
                <Checkbox
                    label="Late Night (9:00 PM - 11:59 PM)"
                    id="lateNight"
                    isChecked={filters.lateNight}
                    onChange={() => handleFilterChange('lateNight')}
                />
            </div>
        </DropdownMenu>
    );
}
