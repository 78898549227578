/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Heading, TextBlock } from 'sarsaparilla';

interface SiteActivityPassSectionProps {
    activityPassAnchor?: string;
}

export default function SiteActivityPassSection({
    activityPassAnchor = '',
}: SiteActivityPassSectionProps) {
    return (
        <section className="mb-8" id={activityPassAnchor}>
            <Heading headingLevel={2} appearance="h2" className="mb-1">
                Site and Activity Passes
            </Heading>
            <TextBlock width="xl">
                <p>
                    <strong>Site passes</strong> are non-transferrable, non-refundable,
                    not replaceable if lost or stolen, and are void if altered or
                    reproduced.
                </p>
                <p>
                    <strong>Activity passes</strong> are non-transferable, non-refundable,
                    cannot be replaced if lost or stolen, and are void if altered or
                    reproduced.
                </p>
            </TextBlock>
        </section>
    );
}
