/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { INVENTORY_TYPES_FILTERS } from './inventoryTypes';

export const CAMPSITE_CAMPING_TYPE = 'campsite-camping';
export const CAMPSITE_DAYUSE_TYPE = 'campsite-dayuse';

// Camping Types
export const CAMPING_TYPES = [
    {
        legacyKey: 'campsite-equipment-tent',
        value: 'Tent',
        key: 'tent',
    },
    {
        legacyKey: 'campsite-equipment-rv',
        value: 'RV/ Motorhome/ Trailer',
        key: 'rmt',
    },
    {
        legacyKey: 'campsite-equipment-trailer',
        value: 'Lodging (Cabins/Yurts/Lookouts)',
        key: 'lodging',
    },
];

// Electricity

export const ELECTRIC_TYPE = [
    {
        legacyKey: 'electric-type-electric-site',
        value: 'Electric Site',
        key: 'electric-site',
    },
    {
        legacyKey: 'electric-type-nonelectric-site',
        value: 'Non-Electric Site',
        key: 'nonelectric-site',
    },
];

export const AMENITIES_TYPE = [
    {
        legacyKey: 'campsite-amenities-water-hookup',
        value: 'Water Hookup',
        key: 'water-hookup',
    },
    {
        legacyKey: 'campsite-amenities-waterfront',
        value: 'Waterfront',
        key: 'waterfront',
    },
    {
        legacyKey: 'campsite-amenities-sewer-hookup',
        value: 'Sewer Hookup',
        key: 'sewer-hookup',
    },
    {
        legacyKey: 'campsite-amenities-flush-toilet',
        value: 'Flush Toilet',
        key: 'toilet',
    },
    {
        legacyKey: 'campsite-amenities-electricity-hookup',
        value: 'Electricity Hookup',
        key: 'electricity-hookup',
    },
    {
        legacyKey: 'campsite-amenities-fire-pit',
        value: 'Fire Pit',
        key: 'firepit',
    },
    {
        legacyKey: 'campsite-amenities-type-shower',
        value: 'Showers',
        key: 'showers',
    },
    {
        legacyKey: 'campsite-amenities-shade',
        value: 'Shade',
        key: 'shade',
    },
];

// Key Characteristics

export const KEY_CHARACTERISTICS_TYPE = [
    {
        legacyKey: 'amenities-accessibility',
        value: 'Accessibility',
        key: 'accessible',
        accessibility: true,
    },
    {
        legacyKey: 'campsite-amenities-pets-allowed',
        value: 'Pet Friendly',
        key: 'pets',
    },
    {
        legacyKey: 'campsite-type-group-standard',
        value: 'Group Site',
        key: 'group-site',
    },
];

// Access
export const ACCESS_TYPE = [
    {
        legacyKey: 'campsite-type-walkto',
        value: 'Walk/Hike To',
        key: 'walk',
    },
    {
        legacyKey: 'campsite-type-boat-sites',
        value: 'Boat-in',
        key: 'boat-in',
    },
    {
        legacyKey: 'amenities-pull-through-driveway',
        value: 'Pull Through Driveway',
        key: 'pull-through',
    },
];

// Special Site types
export const SPECIAL_SITE_TYPE = [
    {
        legacyKey: 'campsite-type-picnic-area',
        value: 'Picnic',
        key: 'picnic',
    },
    {
        legacyKey: 'campsite-type-shelter',
        value: 'Shelter',
        key: 'shelter',
    },
    {
        legacyKey: 'campsite-equipment-boat',
        value: 'Boat',
        key: 'boat',
    },
    {
        value: 'Equestrian',
        legacyKey: 'campsite-equipment-horse',
        key: 'equestrian',
    },
];

export const SEARCH_FILTER_CAMPSITE_ELECTRICAL = [
    {
        legacyKey: 'campsite-elec-100',
        value: '100 amp',
        key: '100-amp',
    },
    {
        legacyKey: 'campsite-elec-50',
        value: '50 amp',
        key: '50-amp',
    },
    {
        legacyKey: 'campsite-elec-30',
        value: '30 amp',
        key: '30-amp',
    },
    {
        legacyKey: 'campsite-elec-20',
        value: '20 amp',
        key: '20-amp',
    },
    {
        legacyKey: 'campsite-elec-15',
        value: '15 amp',
        key: '15-amp',
    },
    {
        legacyKey: 'campsite-elec-5',
        value: '5 amp',
        key: '5-amp',
    },
];

export const SEARCH_FILTER_CAMPSITE_ELECTRICAL_REORDERED = [
    {
        legacyKey: 'campsite-elec-100',
        value: '100 amp',
        key: '100-amp',
    },
    {
        legacyKey: 'campsite-elec-20',
        value: '20 amp',
        key: '20-amp',
    },
    {
        legacyKey: 'campsite-elec-50',
        value: '50 amp',
        key: '50-amp',
    },
    {
        legacyKey: 'campsite-elec-15',
        value: '15 amp',
        key: '15-amp',
    },
    {
        legacyKey: 'campsite-elec-30',
        value: '30 amp',
        key: '30-amp',
    },
    {
        legacyKey: 'campsite-elec-5',
        value: '5 amp',
        key: '5-amp',
    },
];

// First come serve
export const FIRST_COME_FIRST_SERVE_TYPE = [
    {
        legacyKey: 'include-not-reservable',
        value: 'Only show First-Come First-Served (not reservable in advance)',
        key: 'fcfs',
    },
];

export const CASHLESS_TYPE = [
    {
        legacyKey: 'cashless',
        key: 'cashless',
        value: `Credit / Debit Card Only (Scan & Pay via ${process.env.SITE_NAME} mobile app)`,
    },
];

export const FCFS_TYPE = [...FIRST_COME_FIRST_SERVE_TYPE, ...CASHLESS_TYPE];

// Campsite Amenities
export const AMENITIES_CAMPSITE_TYPE = [
    {
        legacyKey: 'campsite-amenities-water-hookup',
        value: 'Water Hookup',
        key: 'water-hookup',
    },
    {
        legacyKey: 'campsite-amenities-waterfront',
        value: 'Waterfront',
        key: 'waterfront',
    },
    {
        legacyKey: 'campsite-amenities-sewer-hookup',
        value: 'Sewer Hookup',
        key: 'sewer-hookup',
    },
    {
        legacyKey: 'campsite-amenities-flush-toilet',
        value: 'Flush Toilet',
        key: 'toilet',
    },
    {
        legacyKey: 'campsite-amenities-electricity-hookup',
        value: 'Electricity Hookup',
        key: 'electricity-hookup',
    },
    {
        legacyKey: 'campsite-amenities-fire-pit',
        value: 'Fire Pit',
        key: 'firepit',
    },
    {
        legacyKey: 'campsite-amenities-type-shower',
        value: 'Showers',
        key: 'showers',
    },
    {
        legacyKey: 'campsite-amenities-shade',
        value: 'Shade',
        key: 'shade',
    },
];

// Campsite Electric
export const SEARCH_FILTER_CAMPSITE_ELECTRICAL_SHORT = [
    {
        legacyKey: 'campsite-elec-100',
        value: '100 amp',
        key: '100-amp',
    },
    {
        legacyKey: 'campsite-elec-20',
        value: '20 amp',
        key: '20-amp',
    },
    {
        legacyKey: 'campsite-elec-50',
        value: '50 amp',
        key: '50-amp',
    },
    {
        legacyKey: 'campsite-elec-15',
        value: '15 amp',
        key: '15-amp',
    },
    {
        legacyKey: 'campsite-elec-30',
        value: '30 amp',
        key: '30-amp',
    },
    {
        legacyKey: 'campsite-elec-5',
        value: '5 amp',
        key: '5-amp',
    },
];

// Campsite legacyKey Characterisitics
export const KEY_CHARACTERISTICS_CAMPSITE_TYPE = [
    {
        legacyKey: 'campsite-amenities-pets-allowed',
        value: 'Pet Friendly',
        key: 'pets',
    },
    {
        legacyKey: 'amenities-accessibility',
        value: 'Accessibility',
        key: 'accessible',
        accessibility: true,
    },
    {
        legacyKey: 'campsite-type-group-standard',
        value: 'Group Site',
        key: 'group-site',
    },
];

// Campsite Access
export const ACCESS_CAMPSITE_TYPE = [
    {
        legacyKey: 'access-type-walkto',
        value: 'Walk/Hike To',
        key: 'walk',
    },
    {
        legacyKey: 'amenities-pull-through-driveway',
        value: 'Pull Through Driveway',
        key: 'pull-through',
    },
    {
        legacyKey: 'campsite-type-boat-sites',
        value: 'Boat-in',
        key: 'boat-in',
    },
];

// Campsite Site use
export const SITE_USE_CAMPSITE_TYPE = [
    {
        legacyKey: 'camping',
        value: 'Camping',
        key: CAMPSITE_CAMPING_TYPE,
    },
    {
        legacyKey: 'dayuse',
        value: 'Day',
        key: CAMPSITE_DAYUSE_TYPE,
    },
];

export const OCCUPANTS_TYPE = {
    legacyKey: 'group-size',
    value: 'Group Size',
    key: 'group-size',
};

export const VEHICLE_LENGTH_TYPE = {
    legacyKey: 'vehicle-length',
    value: 'Vehicle Length',
    key: 'vehicle-length',
};

export const FLAT_CAMPSITE_FILTERS = [
    ...CAMPING_TYPES,
    ...ELECTRIC_TYPE,
    ...ACCESS_CAMPSITE_TYPE,
    ...AMENITIES_CAMPSITE_TYPE,
    ...KEY_CHARACTERISTICS_CAMPSITE_TYPE,
    ...SEARCH_FILTER_CAMPSITE_ELECTRICAL_SHORT,
    ...SITE_USE_CAMPSITE_TYPE,
    ...SPECIAL_SITE_TYPE,
    OCCUPANTS_TYPE,
    VEHICLE_LENGTH_TYPE,
];

export const FLAT_CAMPING_FILTERS = [
    ...INVENTORY_TYPES_FILTERS,
    ...CAMPING_TYPES,
    ...ELECTRIC_TYPE,
    ...AMENITIES_TYPE,
    ...KEY_CHARACTERISTICS_TYPE,
    ...ACCESS_TYPE,
    ...SPECIAL_SITE_TYPE,
    ...FIRST_COME_FIRST_SERVE_TYPE,
    ...CASHLESS_TYPE,
    ...SEARCH_FILTER_CAMPSITE_ELECTRICAL,
    OCCUPANTS_TYPE,
    VEHICLE_LENGTH_TYPE,
];

// Trip Preferences

export const AMENITIES_TYPE_TRIP_PREFERENCES = [
    {
        legacyKey: 'campsite-amenities-water-hookup',
        value: 'Water Hookup',
        key: 'water-hookup',
    },
    {
        legacyKey: 'campsite-amenities-sewer-hookup',
        value: 'Sewer Hookup',
        key: 'sewer-hookup',
    },
    {
        legacyKey: 'campsite-amenities-electricity-hookup',
        value: 'Electricity Hookup',
        key: 'electricity-hookup',
    },
];

export const FG_FILTERS_GLOSARY = {
    'Camping Type': CAMPING_TYPES,
    Electricity: ELECTRIC_TYPE,
    Amenities: AMENITIES_TYPE,
    'Key Characteristics': KEY_CHARACTERISTICS_TYPE,
    Access: ACCESS_TYPE,
    'Special Site': SPECIAL_SITE_TYPE,
    FCFS: FCFS_TYPE,
    'Electrical Hookup': SEARCH_FILTER_CAMPSITE_ELECTRICAL,
    Occupants: [OCCUPANTS_TYPE],
    'Vehicle Length': [VEHICLE_LENGTH_TYPE],
    'Inventory Type': INVENTORY_TYPES_FILTERS,
};
