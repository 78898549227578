/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { Heading, TextBlock } from 'sarsaparilla';

interface RefundsSectionProps {
    refundsAnchor: string;
    submittingRefundAnchor: string;
    refundsRequestAnchor: string;
    refundsDeliveredAnchor: string;
}

export default function RefundsSection({
    refundsAnchor = '',
    submittingRefundAnchor = '',
    refundsRequestAnchor = '',
    refundsDeliveredAnchor = '',
}: RefundsSectionProps) {
    return (
        <section className="mb-8" id={refundsAnchor}>
            <Heading
                headingLevel={2}
                appearance="h1"
                className="rules-reservation-policies-section-heading mb-4"
            >
                Refunds and Refund Requests
            </Heading>
            <Heading headingLevel={2} appearance="h2" id={submittingRefundAnchor}>
                Submitting a Refund Request
            </Heading>
            <TextBlock width="xl">
                <p>
                    Customers may submit a refund request online through their
                    Recreation.gov profile within 7 calendar days of the end date of their
                    reservation. Customers may also request a refund request through the
                    contact center at any time after their reservation has ended.
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h2" id={refundsRequestAnchor}>
                Refund Request Approvals
            </Heading>
            <TextBlock width="xl">
                <p>
                    Once the refund request has been submitted, all decisions regarding
                    refunds and refund amounts are made by the facility managers at the
                    location of the reservation.
                </p>
            </TextBlock>
            <Heading
                headingLevel={2}
                appearance="h2"
                className="mb-4"
                id={refundsDeliveredAnchor}
            >
                How Refunds Are Delivered
            </Heading>
            <Heading headingLevel={2} appearance="h3">
                Debit or Credit Card Purchases
            </Heading>
            <TextBlock width="xl">
                <p>
                    Refunds for debit or credit card payments will be credited to the
                    original bank or credit card used to pay.
                </p>
                <p>
                    In the event that a customer cancels the card before the refund is
                    issued, the customer will need to contact their card company to have
                    the funds released.
                </p>
                <p>
                    We cannot issue refunds to any card other than the original card used
                    to pay.
                </p>
                <p>
                    In some instances where a card refund is not possible, we will issue a
                    US Treasury check. In this case, customers will receive notification
                    at the email address associated with the user account to input their
                    mailing address. If your account mailing address is incomplete, you
                    will have 120 days to respond to recurring email communications from
                    Recreation.gov requesting you add your mailing address to your
                    Recreation.gov customer profile. Without your mailing address, a
                    Treasury check with your refund cannot be mailed. If by 120 days you
                    have not provided your mailing address, your Treasury check refund
                    will not be sent and Recreation.gov will close the request. You may
                    contact the Recreation.gov Contact Center at any time to provide your
                    mailing address and reopen a closed Treasury check refund request.
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h3">
                Check or Cash Purchases
            </Heading>
            <TextBlock width="xl">
                <p>
                    Recreation.gov does not accept cash or check payment for advance
                    reservations. Some locations may accept cash or check on-site. Refunds
                    for cash or check payments will be sent by{' '}
                    <strong>
                        Treasury check to the mailing address associated with the
                        reservation.
                    </strong>
                </p>
                <p>
                    Please ensure your Recreation.gov{' '}
                    <a href="https://www.recreation.gov/account/profile">
                        customer profile
                    </a>{' '}
                    includes your mailing address and that mailing address is up to date.
                    If your account mailing address is incomplete, you will have 120 days
                    to respond to recurring email communications from Recreation.gov
                    requesting you add your mailing address to your Recreation.gov{' '}
                    <a href="https://www.recreation.gov/account/profile">
                        customer profile
                    </a>
                    . Without your mailing address, a Treasury check with your refund
                    cannot be mailed. If by 120 days you have not provided your mailing
                    address, your Treasury check refund will not be sent and
                    Recreation.gov will close the request. You may contact the
                    Recreation.gov Contact Center at any time to provide your mailing
                    address and reopen a closed Treasury check refund request.
                </p>
                <p>It may take up to 6-8 weeks for the Treasury check to arrive.</p>
            </TextBlock>
        </section>
    );
}
