/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { Icon, Checkbox } from 'sarsaparilla';

import { updateSearchCriteria } from 'ui-search/src/actions/search';
import { toggleFilterGroups } from 'ui-search/src/utils/filters';

export default function AccessibleCheckbox({ iconSize = 'md' }) {
    const search = useSelector((state) => state?.search);
    const dispatch = useDispatch();

    const handleChange = () => {
        const params = toggleFilterGroups({ key: 'accessible' }, search);
        dispatch(updateSearchCriteria('fg', params));
    };
    const fgValues = get(search, 'fg', []);

    const isChecked = fgValues.indexOf('accessible') !== -1;

    return (
        <div className="accessible-camping-wrap">
            <Checkbox
                id={'accessible-camping'}
                label={
                    <>
                        <Icon iconName="accessible" size={iconSize} />
                        <span>Accessible camping</span>
                    </>
                }
                isChecked={isChecked}
                onChange={handleChange}
            />
        </div>
    );
}

AccessibleCheckbox.propTypes = {
    iconSize: PropTypes.string,
};
