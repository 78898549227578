/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { TextSlider, Divider, HtmlParser } from 'sarsaparilla';

type QuoteSlide = {
    quote: string;
    author: string;
};

type ValueQuotesSliderProps = {
    hasNavButtons?: boolean;
    shouldAutoRotate?: boolean;
    isQuote?: boolean;
    slideInstructionsIdSuffix?: string;
    cycleDuration?: number;
    className?: string;
    quotes: QuoteSlide[];
};

export default function ValueQuotesSlider({
    hasNavButtons = false,
    shouldAutoRotate = true,
    isQuote = false,
    slideInstructionsIdSuffix = '',
    cycleDuration = 8000,
    className = '',
    quotes = [],
}: ValueQuotesSliderProps) {
    return (
        <TextSlider
            hasNavButtons={hasNavButtons}
            shouldAutoRotate={shouldAutoRotate}
            isQuote={isQuote}
            slideInstructionsIdSuffix={slideInstructionsIdSuffix}
            cycleDuration={cycleDuration}
            className={className}
        >
            {quotes.map((quote, idx) => {
                return (
                    <div className="value-slide-quote" key={idx}>
                        <p className="slide-quote-mark" />
                        <Divider />

                        <div className="slide-quote">
                            <HtmlParser html={quote.quote} />
                        </div>

                        <Divider />

                        <p className="slide-author mb-0">{quote.author}</p>
                    </div>
                );
            })}
        </TextSlider>
    );
}
