/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { Heading } from 'sarsaparilla';

const jawsActions = [
    {
        action: 'Virtual HTML Features',
        command: 'INSERT + F3',
    },
    {
        action: 'List Links',
        command: 'INSERT + F7',
    },
    {
        action: 'Next Link',
        command: 'TAB',
    },
    {
        action: 'Prior Link',
        command: 'SHIFT + TAB',
    },
    {
        action: 'List Headings',
        command: 'INSERT + F6',
    },
    {
        action: 'List of Form Fields',
        command: 'INSERT + F5',
    },
    {
        action: 'List Buttons',
        command: 'INSERT + CTRL + B',
    },
    {
        action: 'List Combo Boxes',
        command: 'INSERT + CTRL + C',
    },
    {
        action: 'List Edit Boxes',
        command: 'INSERT + CTRL + E',
    },
    {
        action: 'List Radio Buttons',
        command: 'INSERT + CTRL + A',
    },
    {
        action: 'List Check Boxes',
        command: 'INSERT + CTRL + X',
    },
    {
        action: 'List Tables',
        command: 'INSERT + CTRL + T',
    },
    {
        action: 'Read from Beginning of Row to Current Cell',
        command: 'INSERT + SHIFT + HOME',
    },
    {
        action: 'Read from Current Cell to End of Row',
        command: 'INSERT + SHIFT + PAGE UP',
    },
    {
        action: 'Read the current row',
        command: 'SHIFT + UP ARROW',
    },
    {
        action: 'Read the current column',
        command: 'SHIFT + NUMPAD 5',
    },
    {
        action: 'List Frames',
        command: 'INSERT + F9',
    },
    {
        action: 'List All Ordered, Unordered, and Definition Lists',
        command: 'INSERT + CTRL + L',
    },
];

const tecName = 'JAWS';

export default function JawsActionsSection() {
    return (
        <section>
            <Heading
                headingLevel={3}
                appearance="h4"
                className="accessibility-table-heading mt-4 mb-2"
            >
                {tecName} Actions and Commands
            </Heading>
            <p>
                Recreation.gov uses ARIA elements, including data tables. Earlier versions
                of JAWS may not recognize ARIA tables, and we recommend using JAWS 17 or
                later for the website.
            </p>
            <table
                className="table-no-border mb-4"
                aria-label={`${tecName} Actions and Commands Table`}
            >
                <thead>
                    <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Commands</th>
                    </tr>
                </thead>
                <tbody>
                    {jawsActions.map((jawsAction) => (
                        <tr key={jawsAction.action}>
                            <td width="70%">{jawsAction.action}</td>
                            <td width="30%">{jawsAction.command}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
}
