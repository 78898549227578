/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    FEEDBACK_SUCCESS,
    FEEDBACK_FAILED,
    NEW_INVENTORY_SUCCESS,
    NEW_INVENTORY_FAILED,
    NEW_IMP_SUCCESS,
    NEW_IMP_FAILED,
} from '../constants/actionTypes';

const initialState = {
    sendErr: false,
    sendSuccess: false,
    sendNewImpRequestSuccess: false,
    sendNewImpRequestError: false,
    sendNewInventoryRequestSuccess: false,
    sendNewInventoryRequestError: false,
};

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEEDBACK_SUCCESS:
            return { ...state, sendSuccess: true, sendErr: false };
        case FEEDBACK_FAILED:
            return { ...state, sendSuccess: false, sendErr: true };
        case NEW_INVENTORY_SUCCESS:
            return {
                ...state,
                sendNewInventoryRequestSuccess: true,
                sendNewInventoryRequestError: false,
            };
        case NEW_INVENTORY_FAILED:
            return {
                ...state,
                sendNewInventoryRequestSuccess: false,
                sendNewInventoryRequestError: true,
            };
        case NEW_IMP_SUCCESS:
            return {
                ...state,
                sendNewImpRequestSuccess: true,
                sendNewIRequestError: false,
            };
        case NEW_IMP_FAILED:
            return {
                ...state,
                sendNewImpRequestSuccess: false,
                sendNewImpRequestError: true,
            };

        default:
            return state;
    }
};

export default contactReducer;
