/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { FlexRow, FlexCol, Heading } from 'sarsaparilla';
import ValueQuotesSlider from '../ValueQuotesSlider';

const quotes = [
    {
        quote: "When it's time to get away and reconnect with nature, <b>the reservation process and app make paradise accessible</b>",
        author: 'Miller S.',
    },
    {
        quote: `I was very surprised [with ${process.env['SITE_NAME']}]. <b>This was smoother than most private reservation sites.</b> The government are better at booking campsites and tickets than most of the private companies.`,
        author: 'Joe P.',
    },
    {
        quote: "<b>The [website] is beautiful and it's so easy to use</b>. We just booked a weekend in Sequoia for the 4th of July and are stoked to go!",
        author: 'Corey C.',
    },
];

export default function ValuesForVisitors() {
    return (
        <FlexRow className="values-visitors-wrapper">
            <FlexCol className="values-visitors-section">
                <FlexRow>
                    <FlexCol
                        lg={10}
                        lgOffset={1}
                        xl={8}
                        xlOffset={2}
                        xxl={6}
                        xxlOffset={3}
                    >
                        <Heading headingLevel={2} appearance="h4">
                            Value for Visitors
                        </Heading>

                        <p className="mt-1 mb-0">
                            For millions of visitors, {process.env['SITE_NAME']} is the
                            one-stop resource to discover, plan, reserve, and enjoy
                            thousands of Federal recreation destinations across the
                            country.
                        </p>
                    </FlexCol>
                </FlexRow>
            </FlexCol>
            <FlexCol className="values-visitors-section">
                <FlexRow>
                    <FlexCol
                        lg={10}
                        lgOffset={1}
                        xl={8}
                        xlOffset={2}
                        xxl={6}
                        xxlOffset={3}
                    >
                        <ValueQuotesSlider quotes={quotes} />
                    </FlexCol>
                </FlexRow>
            </FlexCol>
        </FlexRow>
    );
}
