/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import './GetInvolvedPage.scss';

import {
    Heading,
    ExternalLink,
    HeroImageSectionFront,
    ContentContainerBodyShift,
    useWindowSize,
} from 'sarsaparilla';

// Hero Images
import lgAvif from './images/hero/get-involved-hero-lg.avif';
import mdAvif from './images/hero/get-involved-hero-md.avif';
import smAvif from './images/hero/get-involved-hero-sm.avif';
import lgWebp from './images/hero/get-involved-hero-lg.webp';
import mdWebp from './images/hero/get-involved-hero-md.webp';
import smWebp from './images/hero/get-involved-hero-sm.webp';
import lgJpg from './images/hero/get-involved-hero-lg.jpg';
import mdJpg from './images/hero/get-involved-hero-md.jpg';
import smJpg from './images/hero/get-involved-hero-sm.jpg';

// Partner Images
import vgBrand from './images/vg-brand.png';
import neefBrand from './images/neef-brand.png';
import npfBrand from './images/npf-brand.png';
import nffBrand from './images/nff-brand.png';
import nfwfBrand from './images/nfwf-brand.png';
import plfBrand from './images/plf-brand.png';
import cfBrand from './images/cf-brand.png';

const imageSrcConfig = {
    avif: {
        lg: lgAvif,
        md: mdAvif,
        sm: smAvif,
    },
    webp: {
        lg: lgWebp,
        md: mdWebp,
        sm: smWebp,
    },
    jpg: {
        lg: lgJpg,
        md: mdJpg,
        sm: smJpg,
    },
};

const getInvolvedItems = [
    {
        name: 'Volunteer.gov',
        url: 'https://www.volunteer.gov/',
        aria: '', // decorative images require empty alt
        image: vgBrand,
        description:
            "Since its initial deployment in 2002, the Volunteer.gov Portal has grown into a strategic alliance of governmental partners from all levels - local, State, and Federal Government dedicated to serving the volunteer community by populating this e-Government site with volunteer positions and events for citizens interested in volunteer service benefitting our Nation's resources.",
    },
    {
        name: 'National Environmental Education Foundation',
        url: 'https://www.neefusa.org/',
        aria: '', // decorative images require empty alt
        image: neefBrand,
        description:
            'The National Environmental Education Foundation seeks to reach people where they are and inspire them to learn about their relationship to the environment and, in turn, increase their environmental engagement. With increased environmental involvement of individuals on a national scale, their actions, taken collectively, will have a tremendous environmental impact.',
    },
    {
        name: 'National Park Foundation',
        url: 'https://www.nationalparks.org/',
        aria: '', // decorative images require empty alt
        image: npfBrand,
        description:
            "As the official charitable partner of the National Park Service, the National Park Foundation enriches America's national parks and programs through private support, safeguarding our heritage and inspiring generations of national park enthusiasts.",
    },
    {
        name: 'National Forest Foundation',
        url: 'https://www.nationalforests.org/',
        aria: '', // decorative images require empty alt
        image: nffBrand,
        description:
            'As a nonprofit partner of the U.S. Forest Service, the National Forest Foundation works with the agency and thousands of Americans each year to promote the health and public enjoyment of the 193-million-acre National Forest System.  They focus their efforts on connecting communities and the people who comprise them, to the tools, knowledge, and funding to become active and devoted stewards of this incredible public estate.',
    },
    {
        name: 'National Fish and Wildlife Foundation',
        url: 'http://www.nfwf.org/Pages/default.aspx',
        aria: '', // decorative images require empty alt
        image: nfwfBrand,
        description:
            "Created by Congress in 1984, the National Fish and Wildlife Foundation has grown to become the nation's largest conservation grant-maker. They work with both the public and private sectors to protect and restore our nation's fish, wildlife, plants and habitats.",
    },
    {
        name: 'Public Lands Foundation',
        url: 'https://publicland.org/',
        aria: '', // decorative images require empty alt
        image: plfBrand,
        description:
            'The Public Lands Foundation (PLF) is a national membership organization that advocates and works to keep public lands in public hands. The public lands that they focus on are those that are managed by the Bureau of Land Management (BLM).  PLF endorses and embraces the multiple use mission of the BLM.',
    },
    {
        name: 'The Corps Foundation',
        url: 'http://corpsfoundation.org/',
        aria: '', // decorative images require empty alt
        image: cfBrand,
        description:
            'The nonprofit Corps Foundation, working with the U.S. Army Corps of Engineers and its partners, engages a variety of individuals, businesses, community and “Friends” organizations whose support provides increased benefits to our visitors on the ground at Corps Lake projects. The Foundation also contributes marketing and promotional benefits to the partners.',
    },
];

export function GetInvolvedPage() {
    const { width } = useWindowSize();
    const isMobile = width && width < 768;

    return (
        <main className="r1s-get-involved-page">
            <HeroImageSectionFront
                className="mb-6"
                title="Get Involved"
                text="Getting involved provides the opportunity to see our National treasures in a new light, and there are plenty of ways
                        that you can help preserve and support our public lands and waters. Check out the information below to learn more about
                        organizations that work closely with federal agencies, and see how your support can help you earn a Volunteer Pass!"
                imageSrcConfig={imageSrcConfig}
                backgroundImageAltText="A lake with a mountainscape in the background"
                backgroundImageColorOverlay="#0374fd"
                backgroundImageColorOverlayOpacity={0.6}
                semanticElementName="div"
            />

            <ContentContainerBodyShift>
                <section className="r1s-section-partners">
                    <div className="r1s-partner-wrap">
                        {getInvolvedItems.map((getInvolvedItem) => (
                            <div className="r1s-partner" key={getInvolvedItem.aria}>
                                {!isMobile && (
                                    <div className="r1s-partner-media">
                                        <img
                                            src={getInvolvedItem.image}
                                            alt={getInvolvedItem.aria}
                                        />
                                    </div>
                                )}
                                <div className="r1s-partner-content">
                                    <Heading
                                        headingLevel={2}
                                        appearance="h5"
                                        className="mb-1"
                                    >
                                        {getInvolvedItem.name}
                                    </Heading>
                                    <div className="r1s-partner-description-wrap">
                                        {isMobile && (
                                            <img
                                                src={getInvolvedItem.image}
                                                alt={getInvolvedItem.name}
                                            />
                                        )}
                                        <div>
                                            <p>{getInvolvedItem.description}</p>

                                            <div className="r1s-partner-actions">
                                                <ExternalLink
                                                    url={`${getInvolvedItem.url}`}
                                                    aria-label={`Volunteer opportunities with ${getInvolvedItem.aria}`}
                                                    className="rec-button-link"
                                                    hasExternalIcon
                                                >
                                                    Visit Website
                                                </ExternalLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </ContentContainerBodyShift>
        </main>
    );
}

// cSpell:ignore webP, neef, nfwf, mountainscape
