/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';

import { FlexCol, InventoryPromoLink } from 'sarsaparilla';

export function ItemsPlaceholder() {
    return (
        <FlexCol sm={6} md={4} xl={3} className="mb-4">
            <InventoryPromoLink
                href="#"
                imagePath=""
                imageAlt=""
                heading=""
                location=""
                availabilityTag=""
                isLoading
            />
        </FlexCol>
    );
}
