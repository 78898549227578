/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { InventoryPromoLink, FlexRow, FlexCol, MapHelper } from 'sarsaparilla';
import { ItemsPlaceholder } from './ItemsPlaceholder';

export default function Items({ items, isLoading, tag }) {
    if (isLoading) {
        return (
            <FlexRow>
                {[...Array(16)].map((x, i) => (
                    <ItemsPlaceholder key={i} />
                ))}
            </FlexRow>
        );
    }

    function NonCampingTags(item) {
        const Tags = {
            day: 'DayUse',
            overnight: 'Camping',
            ticketfacility: 'Ticket',
            tour: 'Ticket',
            timedentry: 'Ticket',
            timedentry_tour: 'Ticket',
            recarea: 'RecArea',
            lottery: 'Lottery',
            vehiclePermit: 'VehiclePermit',
            permit: 'Permit',
            activitypass: 'ActivityPass',
            none: 'POI',
        };
        return Tags[item] || Tags.none;
    }

    const whatsNewBlocks = items.map((item, index) => {
        const tags = [];

        // Assign Inventory Tags
        if (
            item.campsite_type_of_use &&
            item.campsite_type_of_use.length > 0 &&
            item.campsite_type_of_use[0] !== 'na'
        ) {
            item.campsite_type_of_use.forEach((type) => {
                if (type === 'Day') {
                    tags.push('DayUse');
                } else if (type === 'Overnight') {
                    tags.push('Camping');
                } else if (type === '') {
                    tags.push('Camping');
                }
            });
        } else {
            tags.push([NonCampingTags(`${item.entity_type}`)]);
        }

        // Set URL Path
        const targetUri = MapHelper.getTargetUri(item);

        const hasParent = item.parent_name === undefined ? '' : `${item.parent_name}`;
        const hasCity = item.city === undefined ? '' : `${item.city}`;
        const hasStateCode = item.state_code === undefined ? '' : item.state_code;
        const hasComma = hasCity && hasStateCode ? ',' : '';
        const hasParentAndCity = hasParent && hasCity ? ' | ' : '';

        return (
            <FlexCol sm={6} md={4} xl={3} className="mb-4" key={index}>
                <InventoryPromoLink
                    headingLevel={2}
                    imagePath={item.preview_image_url}
                    imageAlt={item.name}
                    href={`${targetUri}`}
                    heading={item.name}
                    location={`${hasParent}${hasParentAndCity}${hasCity}${hasComma} ${hasStateCode}`}
                    hasStarRating={item.number_of_ratings && item.number_of_ratings > 0}
                    rating={item.average_rating}
                    reviewCount={item.number_of_ratings}
                    inventoryTags={tags}
                    availabilityTag={tag}
                />
            </FlexCol>
        );
    });

    return <FlexRow>{whatsNewBlocks}</FlexRow>;
}

Items.propTypes = {
    items: PropTypes.array,
    isLoading: PropTypes.bool,
    tag: PropTypes.string,
};
