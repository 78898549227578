/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import './AccessibilityPage.scss';

import {
    HelmetWrapper,
    ContentContainerBodyShift,
    Heading,
    HeroImageSectionFront,
    useWindowSize,
    Stack,
    NumberBullet,
    ExternalLink,
} from 'sarsaparilla';
import {
    AnchorPageWithSidebarLayout,
    AnchorPageSection,
} from 'shared-ui/components/AnchorPageWithSidebarLayout';
import JawsActions from './assistive-technology/JawsActions';
import VoiceOverActions from './assistive-technology/VoiceOverActions';
import ZoomActions from './assistive-technology/ZoomActions';
import DragonActions from './assistive-technology/DragonActions';
import TalkBackActions from './assistive-technology/TalkBackActions';

import lgAvif from './images/accessibility-hero-lg.avif';
import mdAvif from './images/accessibility-hero-md.avif';
import smAvif from './images/accessibility-hero-sm.avif';
import lgWebp from './images/accessibility-hero-lg.webp';
import mdWebp from './images/accessibility-hero-md.webp';
import smWebp from './images/accessibility-hero-sm.webp';
import lgJpg from './images/accessibility-hero-lg.jpg';
import mdJpg from './images/accessibility-hero-md.jpg';
import smJpg from './images/accessibility-hero-sm.jpg';

const imageSrcConfig = {
    avif: {
        lg: lgAvif,
        md: mdAvif,
        sm: smAvif,
    },
    webp: {
        lg: lgWebp,
        md: mdWebp,
        sm: smWebp,
    },
    jpg: {
        lg: lgJpg,
        md: mdJpg,
        sm: smJpg,
    },
};

export function AccessibilityPage() {
    const { width } = useWindowSize();
    const isMobile = width && width < 768;

    return (
        <div className="r1s-accessibility-page" role="main">
            <HelmetWrapper title="Accessibility" />
            {/* @ts-ignore */}
            <HeroImageSectionFront
                className="mb-5"
                title="Accessibility"
                text="Making the outdoors accessible to all"
                imageSrcConfig={imageSrcConfig}
                backgroundImageAltText="Ships in a harbor at sunrise"
                backgroundImageColorOverlay="#593820"
                backgroundImageColorOverlayOpacity={0.5}
            />

            <ContentContainerBodyShift className="mb-8">
                <Stack>
                    <AnchorPageWithSidebarLayout>
                        <Stack space={isMobile ? 'lg' : 'xl'}>
                            <AnchorPageSection
                                heading="Accessibility"
                                headingLevel={2}
                                headingAppearance={'h2'}
                                className="hide-top-heading"
                            >
                                <p>
                                    Recreation.gov is committed to making every possible
                                    effort to ensure that all information is accessible to
                                    people with disabilities, including both employees and
                                    customers we serve. Our site is designed to meet or
                                    exceed the requirements of&nbsp;
                                    <ExternalLink
                                        url="https://section508.gov/"
                                        hasExternalIcon
                                    >
                                        Section 508 of the Rehabilitation Act of 1973
                                    </ExternalLink>
                                    &nbsp;(29 U.S.C. &sect; 794d) and was tested for
                                    conformance with&nbsp;
                                    <ExternalLink
                                        url="https://www.w3.org/WAI/standards-guidelines/wcag/"
                                        hasExternalIcon
                                    >
                                        WCAG 2.0
                                    </ExternalLink>
                                    &nbsp;A and AA success criteria.
                                </p>
                                <p>
                                    If you experience any difficulty accessing the
                                    information on our site, please{' '}
                                    <a href="/feedback" target="_blank" rel="noreferrer">
                                        send us feedback
                                    </a>
                                    . We will try to assist you as best we can. Our Help
                                    Center team is also available to assist people with
                                    disabilities in making reservations, answering
                                    questions about the website, and providing support or
                                    information in an alternate format.
                                </p>
                                <p>
                                    If you require assistance using Recreation.gov, please
                                    visit our{' '}
                                    <a href="https://recreationonestopprod.servicenowservices.com/external?id=external_contact_us">
                                        Contact Us
                                    </a>{' '}
                                    page or call our TDD Line at 877-833-6777 from 10 a.m.
                                    – 12 a.m. (Eastern Time Zone).
                                </p>
                            </AnchorPageSection>
                            <AnchorPageSection
                                heading="Accessibility Initiative"
                                headingLevel={2}
                                headingAppearance={'h3'}
                            >
                                <p>
                                    Recreation.gov is working to improve the information
                                    we provide about campsites that are accessible by
                                    people of all physical abilities.
                                </p>
                                <p>This initiative has 3 parts:</p>

                                <ul className={'accessibility-numbered-list ml-0'}>
                                    <li className={'mt-2 mb-2'}>
                                        <NumberBullet isInverse>{1}</NumberBullet>
                                        <b>
                                            Easier path to finding accessible campgrounds
                                            and campsites on Recreation.gov.
                                        </b>{' '}
                                        We are making changes to our home page, search,
                                        and camping pages to increase the visibility of
                                        campsites with accessible amenities or features.
                                        This will allow visitors to find and reserve sites
                                        with amenities and features that meet their needs
                                        more quickly and easily.
                                    </li>
                                    <li className={'mt-2 mb-2'}>
                                        <NumberBullet isInverse>{2}</NumberBullet>
                                        <b>
                                            Crowd-sourced feedback on campground and
                                            campsite accessibility.
                                        </b>{' '}
                                        Recreation.gov currently asks visitors to provide
                                        ratings and reviews on their camping experiences.
                                        This initiative will expand on those reviews,
                                        asking visitors to comment specifically on the
                                        specific features and amenities of their campsite.
                                        Our hope is that this on-the-ground feedback will
                                        provide additional clarity for visitors with
                                        accessibility needs.
                                    </li>
                                    <li className={'mt-2 mb-2'}>
                                        <NumberBullet isInverse>{3}</NumberBullet>
                                        <b>Data collection.</b> Working across all of our
                                        partner agencies, we defined a new data standard
                                        for campground and campsite information. The&nbsp;
                                        <a
                                            href="https://ridb.recreation.gov/standards"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Federal Camping Data Standard (opens in a new tab)"
                                        >
                                            Federal Camping Data Standard
                                        </a>
                                        &nbsp;(Dec. 2021) calls for detailed information
                                        about specific features and amenities of each
                                        federal campsite for visitors who have varying
                                        degrees of ability (physical, visual, auditory,
                                        cognitive). Once this information is collected by
                                        the campground facilities, it will be viewable and
                                        searchable on Recreation.gov and other federal
                                        campground reservation portals.
                                    </li>
                                </ul>
                                <p>
                                    Having up-to-date and detailed information will allow
                                    visitors to make camping decisions with confidence and
                                    encourage visitors of all ages and abilities to enjoy
                                    the outdoors.
                                </p>
                            </AnchorPageSection>
                            <AnchorPageSection
                                heading="Provide Feedback"
                                headingLevel={2}
                                headingAppearance={'h3'}
                            >
                                <p>
                                    We are looking for people to provide feedback on the
                                    new features, through focus groups and usability
                                    testing. If you&apos;d like to participate, please
                                    sign up for a one-time, 30-minute or 45- minute event{' '}
                                    <a
                                        href="https://calendly.com/medhurst_kirsten/recreation-gov-accesssibility-feedback-session?month=2022-03"
                                        aria-label="virtual feedback session"
                                    >
                                        here
                                    </a>
                                    .
                                </p>
                            </AnchorPageSection>
                            <AnchorPageSection
                                heading="Assistive Technology Guide"
                                headingLevel={2}
                                headingAppearance={'h3'}
                            >
                                <p>
                                    This guide is intended to assist individuals with
                                    disabilities in their use of Recreation.gov. The guide
                                    will provide direction in accessing the features
                                    available across the site using specific technologies,
                                    including screen readers, screen magnifiers and voice
                                    command systems.
                                </p>

                                <JawsActions />
                                <VoiceOverActions />
                                <ZoomActions />
                                <DragonActions />
                                <TalkBackActions />

                                <Heading
                                    headingLevel={3}
                                    appearance="h4"
                                    className="accessibility-table-heading mb-2"
                                >
                                    reCaptcha
                                </Heading>

                                <p>
                                    Recreation.gov uses reCaptcha to protect the site from
                                    spam and abuse. For keyboard and screen reader
                                    instructions, please refer to the{' '}
                                    <ExternalLink
                                        url="https://support.google.com/recaptcha/answer/6175971?hl=en"
                                        hasExternalIcon
                                    >
                                        reCaptcha accessibility documentation
                                    </ExternalLink>{' '}
                                    provided by Google.
                                </p>
                            </AnchorPageSection>
                        </Stack>
                    </AnchorPageWithSidebarLayout>
                </Stack>
            </ContentContainerBodyShift>
        </div>
    );
}

// cSpell:ignore WCAG
