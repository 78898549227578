/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Heading, TextBlock } from 'sarsaparilla';

interface PermitReservationSectionProps {
    anchor: string;
    permitsAnchor: string;
}

export default function PermitReservationSection({
    anchor = '',
    permitsAnchor = '',
}: PermitReservationSectionProps) {
    return (
        <section className="mb-8" id={anchor}>
            <Heading headingLevel={2} appearance="h2" className="mb-1" id={permitsAnchor}>
                Permit Reservations
            </Heading>
            <TextBlock width="xl">
                <p>
                    Permit policies and fees are unique to each location. Please ensure
                    that you have reviewed the information provided on the facility&apos;s
                    Overview, Need to Know, and Fees & Cancellation sections before you
                    book.
                </p>
                <p>
                    After you book a permit reservation, you may not change the permit
                    holder&apos;s information. If you need to change this information you
                    must cancel and rebook.
                </p>

                <p>
                    Permit holders may not modify or cancel a permit that has been
                    printed.
                </p>
            </TextBlock>
        </section>
    );
}
