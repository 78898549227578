/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './PartnerIcon.scss';

import * as React from 'react';

import imageSrc from './partner-icons-white@2x.webp';

const iconDetail = {
    BLM: {
        title: 'Bureau of Land Management',
        offset: 0,
    },
    DOT: {
        title: 'Department of Transportation',
        offset: -50,
    },
    NOAA: {
        title: 'National Oceanic and Atmospheric Administration',
        offset: -100,
    },
    Smithsonian: {
        title: 'Smithsonian Institution',
        offset: -150,
    },
    ForestService: {
        title: 'USDA Forest Service',
        offset: -200,
    },
    USACE: {
        title: 'US Army Corps of Engineers',
        offset: -250,
    },
    FWS: {
        title: 'US Fish and Wildlife Service',
        offset: -300,
    },
    NPS: {
        title: 'National Park Service',
        offset: -350,
    },
    TRE: {
        title: 'Bureau of Engraving and Printing',
        offset: -400,
    },
    BEP: {
        title: 'Bureau of Engraving and Printing',
        offset: -400,
    },
    PT: {
        title: 'Presidio Trust',
        offset: -450,
    },
    TVA: {
        title: 'Tennessee Valley Authority',
        offset: -500,
    },
    DOI: {
        title: 'Bureau of Reclamation',
        offset: -550,
    },
    NationalArchives: {
        title: 'National Archives',
        offset: -600,
    },
    NDW: {
        title: 'Naval District Washington',
        offset: -650,
    },
} as const;

interface PartnerIconProps extends React.HTMLAttributes<HTMLDivElement> {
    iconName: keyof typeof iconDetail;
    size?: 'md' | 'lg';
    showTitle?: boolean;
}

export function PartnerIcon({
    iconName,
    size = 'md',
    showTitle = false,
    ...rest
}: PartnerIconProps) {
    if (!iconName || !iconDetail?.[iconName]) return null;

    const { title, offset } = iconDetail[iconName];

    return (
        <div
            data-component="r1s-partner-icon"
            data-size={size}
            title={showTitle ? title : undefined}
            role="img"
            aria-label={title}
            {...rest}
        >
            <div
                className="sprite"
                style={{
                    backgroundImage: `url(${imageSrc})`,
                    backgroundPosition: `${offset}px ${size === 'md' ? 0 : -34}px`,
                    backgroundSize: '698px',
                }}
            />
        </div>
    );
}
