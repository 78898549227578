/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';

export default class SearchPaginationText extends React.Component {
    static propTypes = {
        start: PropTypes.number,
        total: PropTypes.number,
        pageSize: PropTypes.number,
        placename: PropTypes.string,
    };

    static displayName = 'SearchPaginationText';

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let total = this.props.total;
        if (!total) {
            total = 0;
        }

        let totalStr = total;
        if (total > 300) {
            totalStr = '300+';
        }

        let start = this.props.start;
        if (!start) {
            start = 0;
        }

        let pageSize = this.props.pageSize;
        if (!pageSize) {
            pageSize = 20;
        }

        if (total < pageSize) {
            pageSize = total;
        }

        if (start + pageSize > total) {
            pageSize -= Math.abs(total - pageSize - start);
        }

        if (total > 0) {
            return (
                <div className="search-pagination-text">
                    <span>
                        <var data-var="search-pagination-page">
                            {start + 1} - {start + pageSize}
                        </var>{' '}
                        results of{' '}
                        <var data-var="search-pagination-total">{totalStr}</var>
                        {this.props.placename && (
                            <span>
                                {' '}
                                near <span translate="no">{this.props.placename}</span>
                            </span>
                        )}
                    </span>
                </div>
            );
        }
        return null;
    }
}
