/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';

function SimpleStaticMapImage() {
    const mapImage = `${process.env.MAPBOX_STATIC_STYLE}/-98.5795,39.8283,4,0,0/800x500@2x?access_token=${process.env.MAPBOX_ACCESS_TOKEN}`;

    return (
        <div className="static-map-container">
            <div className="static-map-image-container">
                <img alt="" src={mapImage} className="static-map-image" />
            </div>

            <div className="static-map-image-backdrop">
                <div className="static-map-image-overlay">
                    <p>
                        We apologize but maps for this location are not available at this
                        time.
                    </p>
                    <p>
                        We are working with this location to roll out new maps to help you
                        with travel planning. Check back soon as we are continuously
                        updating our data.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SimpleStaticMapImage;
