/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * InventoryTypesSection.jsx
 * Created by Sameer Dhar on 11/04/2021
 */

import {
    FlexRow,
    FlexCol,
    Heading,
    Tabs,
    TabPanel,
    ContentContainer,
    Button,
    HtmlParser,
} from 'sarsaparilla';

import { NewInventoryIcon } from './NewInventoryIcon';
import INVENTORY_TYPES from './InventoryTypes';

type InventoryTypeItem = (typeof INVENTORY_TYPES)[number];

export default function InventoryTypesSection() {
    const generateDescription = (invType: InventoryTypeItem) => {
        if (invType.id !== 'camping') {
            const invDesc = [];

            for (const [i, desc] of invType.description.entries()) {
                invDesc.push(
                    <div key={i}>
                        <HtmlParser html={desc} />
                    </div>
                );
            }
            return invDesc;
        }

        const invBullets = [];
        for (const [i, bullet] of invType.bullets.entries()) {
            invBullets.push(
                <li key={i}>
                    <HtmlParser html={bullet} />
                </li>
            );
        }

        return <ul>{invBullets}</ul>;
    };

    const getInventoryTabPanels = () => {
        const invTypePanels = [];

        for (const [i, invType] of INVENTORY_TYPES.entries()) {
            invTypePanels.push(
                <TabPanel key={i} label={invType.label} gaTrackingId="997127979960">
                    <FlexRow>
                        <FlexCol lg="variable" className="nav-ni-inventory-types-icon">
                            <NewInventoryIcon iconName={invType.icon} />
                        </FlexCol>
                        <FlexCol className="nav-ni-inventory-types-copy">
                            {generateDescription(invType)}
                        </FlexCol>
                    </FlexRow>
                    <FlexRow justifyContent="center">
                        <Button
                            appearance="primary"
                            to={`/learnmore/${invType.id}`}
                            gaTrackingId="550762512214"
                        >
                            Learn More About {invType.label} on {process.env.SITE_NAME}
                        </Button>
                    </FlexRow>
                </TabPanel>
            );
        }
        return invTypePanels;
    };

    return (
        <section id="inventory-types" className="nav-ni-inventory-types-wrap">
            <ContentContainer className="my-0">
                <FlexRow>
                    <FlexCol sm={12} alignSelf="center">
                        <Heading headingLevel={2} appearance="h4" className="mb-3">
                            We Offer Solutions for Multiple Inventory Types
                        </Heading>
                    </FlexCol>
                    <FlexCol xlOffset={1} xl={10}>
                        <Tabs>{getInventoryTabPanels()}</Tabs>
                    </FlexCol>
                </FlexRow>
            </ContentContainer>
        </section>
    );
}

// cSpell:ignore learnmore
