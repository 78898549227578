/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, Checkbox, Badge } from 'sarsaparilla';

export default function WhatsNewFilter({ aggregations, selectedFilters, onApply }) {
    const [filters, setFilters] = useState([...selectedFilters]);

    function getCount(attribute, value) {
        if (aggregations) {
            const att = aggregations[attribute];
            if (att) {
                const count = att
                    .filter((val) => val.name === value)
                    .map((val) => val.count)
                    .reduce((prev, curr) => prev + curr, 0);
                if (count) {
                    return count;
                }
            }
        }
        return 0;
    }

    // Find the total amount of overnight campgrounds
    const overnightTotal = getCount('campsite_type_of_use', 'Overnight');

    // Find the total number of dayUse facilities.
    const dayUseTotal = getCount('campsite_type_of_use', 'Day');

    // Find the total number of Ticket facilities
    const tour = getCount('entity_type', 'tour');
    const timedEntry = getCount('entity_type', 'timedentry');
    const ticketFacility = getCount('entity_type', 'ticketfacility');
    const timedentryTour = getCount('entity_type', 'timedentry_tour');
    const toursTotal = tour + timedEntry + ticketFacility + timedentryTour;

    // Find the total number of Permit facilities
    const permit = getCount('entity_type', 'permit');
    const vehiclePermit = getCount('entity_type', 'vehiclepermit');
    const permitsTotal = permit + vehiclePermit;

    // Find the total number of POI facilities
    const recareaPOI = getCount('entity_type', 'recarea');
    const facilityPOI = getCount('entity_type', 'facility');
    const cemeteryandmemorialPOI = getCount('entity_type', 'cemeteryandmemorial');
    const libraryPOI = getCount('entity_type', 'library');
    const archivesPOI = getCount('entity_type', 'archives');
    const museumPOI = getCount('entity_type', 'museum');
    const kioskPOI = getCount('entity_type', 'kiosk');
    const nationalfishhatcheryPOI = getCount('entity_type', 'nationalfishhatchery');
    const POItotal =
        recareaPOI +
        facilityPOI +
        cemeteryandmemorialPOI +
        libraryPOI +
        archivesPOI +
        museumPOI +
        kioskPOI +
        nationalfishhatcheryPOI;

    // Find the total number of Activity Passes
    const activityPassTotal = getCount('entity_type', 'activitypass');

    const onFilterChange = (event) => {
        if (event.target.checked) {
            const newFilters = [...filters];
            newFilters.push(event.target.value);
            setFilters(newFilters);
        } else {
            setFilters(filters.filter((f) => f !== event.target.value));
        }
    };

    const onApplyClicked = () => {
        onApply(filters);
    };

    const id = 'whats-new-filter';

    return (
        <DropdownMenu
            label="Inventory Type"
            id="basic-example"
            badgeCount={selectedFilters.length}
            onApply={onApplyClicked}
            className="mb-2 mb-md-0"
        >
            <div style={{ minWidth: '275px' }}>
                <div className="whats-new-checkbox-wrap">
                    <Checkbox
                        id={`${id}-camping`}
                        label="Camping"
                        value="camping"
                        isChecked={filters.includes('camping')}
                        isDisabled={overnightTotal === 0}
                        onChange={onFilterChange}
                    />
                    <Badge>{overnightTotal}</Badge>
                </div>
                <div className="whats-new-checkbox-wrap">
                    <Checkbox
                        id={`${id}-day-use`}
                        label="Day-use"
                        value="dayuse"
                        isChecked={filters.includes('dayuse')}
                        isDisabled={dayUseTotal === 0}
                        onChange={onFilterChange}
                    />
                    <Badge>{dayUseTotal}</Badge>
                </div>
                <div className="whats-new-checkbox-wrap">
                    <Checkbox
                        id={`${id}-tours`}
                        label="Tours"
                        value="tickettours"
                        isChecked={filters.includes('tickettours')}
                        isDisabled={toursTotal === 0}
                        onChange={onFilterChange}
                    />
                    <Badge>{toursTotal}</Badge>
                </div>
                <div className="whats-new-checkbox-wrap">
                    <Checkbox
                        id={`${id}-permits`}
                        label="Permits"
                        value="permits"
                        isChecked={filters.includes('permits')}
                        isDisabled={permitsTotal === 0}
                        onChange={onFilterChange}
                    />
                    <Badge>{permitsTotal}</Badge>
                </div>
                <div className="whats-new-checkbox-wrap">
                    <Checkbox
                        id={`${id}-poi`}
                        label="Points of Interest"
                        value="recarea"
                        isChecked={filters.includes('recarea')}
                        isDisabled={POItotal === 0}
                        onChange={onFilterChange}
                    />
                    <Badge>{POItotal}</Badge>
                </div>
                <div className="whats-new-checkbox-wrap">
                    <Checkbox
                        id={`${id}-activity-pass`}
                        label="Activity Pass"
                        value="pass"
                        isChecked={filters.includes('pass')}
                        isDisabled={activityPassTotal === 0}
                        onChange={onFilterChange}
                    />
                    <Badge>{activityPassTotal}</Badge>
                </div>
            </div>
        </DropdownMenu>
    );
}

WhatsNewFilter.propTypes = {
    aggregations: PropTypes.object,
    selectedFilters: PropTypes.array,
    onApply: PropTypes.func,
};
