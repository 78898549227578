/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

/*
 * AboutUs.jsx Created by Destin Frasier on 11/19/17
 * Updated on 09/30/23
 */

import React from 'react';
import './AboutUsPage.scss';
import { Heading, ContentContainerBodyShift, FlexRow, FlexCol } from 'sarsaparilla';
import AboutUsExperiences from './AboutUsExperiences';
import AboutUsOurPartners from './AboutUsOurPartners';

import AboutUsAgencies from './AboutUsAgencies';
import ValuesForVisitors from './ValuesForVisitors';
import ValueForAgencyFieldStaff from './ValueForAgencyFieldStaff';
import Hero from './Hero';

export function AboutUsPage() {
    return (
        <div className="r1s-about-us-page">
            <Hero />

            <div role="main">
                <section className="r1s-section-wrap my-5">
                    <ContentContainerBodyShift className="r1s-section-about">
                        <Heading hasUnderline="center" headingLevel={2} appearance="h4">
                            About {process.env['SITE_NAME']}
                        </Heading>

                        <FlexRow>
                            <FlexCol lgOffset={2} lg={8}>
                                <p className="r1s-sub-text mb-0">
                                    {process.env['SITE_NAME']} is the federal government’s
                                    centralized travel planning and reservation platform.
                                    This public program has grown and evolved since it was
                                    first conceived as an information sharing service in
                                    1995. {process.env['SITE_NAME']} is now the outdoor
                                    recreation system for 14 Federal agencies to support
                                    land management and enable visitors to discover and
                                    experience public lands and waters.
                                </p>
                            </FlexCol>
                        </FlexRow>
                    </ContentContainerBodyShift>
                </section>

                <section className="r1s-section-agencies p-10">
                    <AboutUsAgencies />
                </section>

                <section className="r1s-section-values-for-visitors">
                    <ValuesForVisitors />
                </section>

                <section className="r1s-section-bg-secondary">
                    <section className="r1s-section-wrap">
                        <AboutUsExperiences />
                    </section>
                </section>

                <section className="r1s-section-values-for-agencies">
                    <ValueForAgencyFieldStaff />
                </section>

                <section className="r1s-section-wrap mt-10">
                    <AboutUsOurPartners />
                </section>
            </div>
        </div>
    );
}

// cSpell:ignore webP
