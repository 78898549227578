/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import cx from 'classnames';

import { Button, Icon } from 'sarsaparilla';

interface PhotoInfoFlyoutProps {
    children: React.ReactNode;
    isOpen: boolean;
    close: () => void;
    open: () => void;
}

export function PhotoInfoFlyout({ children, isOpen, close, open }: PhotoInfoFlyoutProps) {
    const outerRef = React.useRef<HTMLDivElement>(null);
    const popupRef = React.useRef<HTMLDivElement>(null);
    const triggerRef = React.useRef<HTMLButtonElement>(null);
    const closeButtonRef = React.useRef<HTMLButtonElement>(null);

    const label = 'Information about the background photo';

    const closePopup = React.useCallback(() => {
        close();

        triggerRef.current?.focus();
    }, [close]);

    const openPopup = () => {
        open();
        window.setTimeout(() => {
            popupRef.current?.focus();
        }, 0);
    };

    React.useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (isOpen && event.key === 'Escape') {
                closePopup();
            }
        }

        function handleClickOutside(event: MouseEvent) {
            if (isOpen && !outerRef.current?.contains(event.target as Node)) {
                closePopup();
            }
        }

        function focusSniffer() {
            if (!popupRef.current) return; // It's already closed

            const focusedElement = document.activeElement as HTMLElement;

            if (isOpen && !popupRef.current.contains(focusedElement)) {
                close();
            }
        }

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('focusin', focusSniffer);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('focusin', focusSniffer);
        };
    }, [close, closePopup, isOpen]);

    return (
        <div className="nav--photo-info-flyout" ref={outerRef}>
            <Button
                innerRef={triggerRef}
                id="photoFlyoutTrigger"
                onClick={openPopup}
                className="nav-hero-photo-info"
                aria-label="View details about the background image"
                clickTagCategory="Homepage"
                clickTagAction="Image Location Overlay"
                clickTagLabel="Hero"
            >
                <Icon iconName="camera" />
            </Button>

            <div
                ref={popupRef}
                data-component="NavPhotoFlyout"
                tabIndex={-1}
                aria-label={label}
                role="group"
                className={cx('nav-hero-photo-flyout-wrap', {
                    'is-open': isOpen,
                })}
            >
                {isOpen && (
                    <div className={cx('nav-hero-photo-flyout-inner')}>
                        <div
                            data-component="NavPhotoFlyoutContent"
                            className="nav-hero-photo-flyout-content"
                        >
                            {children}
                        </div>

                        <Button
                            innerRef={closeButtonRef}
                            data-testid="close-button"
                            size="xs"
                            appearance="subtle"
                            iconBeforeElement={<Icon iconName="close" />}
                            screenReaderTextAfter={`Close ${label}`}
                            onClick={closePopup}
                            className="photo-flyout-open-close-button"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
