/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

export function RestoreScroll() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
