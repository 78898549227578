/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

export const INVENTORY_TYPES_CAMPING = 'camping';
export const INVENTORY_TYPES_DAY_USE = 'dayuse';
export const INVENTORY_TYPES_TICKETS = 'tickets';
export const INVENTORY_TYPES_PERMITS = 'permits';
export const INVENTORY_TYPES_POI = 'poi';
export const INVENTORY_TYPES_ACTIVITYPASS = 'activitypass';
export const INVENTORY_TYPES_TREE_PERMITS = 'treepermits';
export const INVENTORY_TYPES_RECAREA = 'recarea';
export const INVENTORY_TYPES_VENUES = 'venues';

export const INVENTORY_KEYS = [
    INVENTORY_TYPES_CAMPING,
    INVENTORY_TYPES_DAY_USE,
    INVENTORY_TYPES_TICKETS,
    INVENTORY_TYPES_PERMITS,
    INVENTORY_TYPES_POI,
    INVENTORY_TYPES_ACTIVITYPASS,
    INVENTORY_TYPES_TREE_PERMITS,
    INVENTORY_TYPES_RECAREA,
    INVENTORY_TYPES_VENUES,
];

export const INVENTORY_TYPES_FILTERS = [
    {
        key: INVENTORY_TYPES_CAMPING,
        legacyKey: 'camping',
        value: 'Camping',
    },
    {
        key: INVENTORY_TYPES_DAY_USE,
        value: 'Day Use',
        legacyKey: 'dayuse',
    },
    {
        key: INVENTORY_TYPES_TICKETS,
        value: 'Tickets & Tours',
        legacyKey: 'tours',
    },
    {
        key: INVENTORY_TYPES_PERMITS,
        value: 'Permits',
        legacyKey: 'permits',
    },
    {
        key: INVENTORY_TYPES_POI,
        value: 'Points of Interest',
        legacyKey: 'poi',
    },
    {
        key: INVENTORY_TYPES_ACTIVITYPASS,
        value: 'Activity Pass',
        legacyKey: 'activitypass',
    },
    {
        key: INVENTORY_TYPES_TREE_PERMITS,
        value: 'Tree Permits',
        legacyKey: 'treepermit',
    },
    {
        key: INVENTORY_TYPES_VENUES,
        value: 'Venues',
        legacyKey: 'venuereservations',
    },
    {
        key: INVENTORY_TYPES_RECAREA,
        value: 'Rec Areas',
        legacyKey: 'recarea',
    },
];
