/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';

import aboutLGAvif from '../images/about-us-hero-lg.avif';
import aboutLGWebp from '../images/about-us-hero-lg.webp';
import aboutLGJpg from '../images/about-us-hero-lg.jpg';
import aboutMDAvif from '../images/about-us-hero-md.avif';
import aboutMDWebp from '../images/about-us-hero-md.webp';
import aboutMDJpg from '../images/about-us-hero-md.jpg';
import aboutSMAvif from '../images/about-us-hero-sm.avif';
import aboutSMWebp from '../images/about-us-hero-sm.webp';
import aboutSMJpg from '../images/about-us-hero-sm.jpg';
import aboutXSAvif from '../images/about-us-hero-xs.avif';
import aboutXSWebp from '../images/about-us-hero-xs.webp';
import aboutXSJpg from '../images/about-us-hero-xs.jpg';

export default function Hero() {
    return (
        <div className="about-us-hero-gallery">
            <picture>
                <source
                    type="image/avif"
                    media="(min-width: 1200px)"
                    srcSet={`${aboutLGAvif} 2000w`}
                />
                <source
                    type="image/avif"
                    media="(min-width: 992px)"
                    srcSet={`${aboutMDAvif} 1440w`}
                />
                <source
                    type="image/avif"
                    media="(min-width: 768px)"
                    srcSet={`${aboutSMAvif} 768w`}
                />
                <source type="image/avif" srcSet={`${aboutXSAvif} 576w`} />

                <source
                    type="image/webp"
                    media="(min-width: 1200px)"
                    srcSet={`${aboutLGWebp} 2000w`}
                />
                <source
                    type="image/webp"
                    media="(min-width: 992px)"
                    srcSet={`${aboutMDWebp} 1440w`}
                />
                <source
                    type="image/webp"
                    media="(min-width: 768px)"
                    srcSet={`${aboutSMWebp} 768w`}
                />
                <source type="image/webp" srcSet={`${aboutXSWebp} 576w`} />

                <source
                    type="image/jpeg"
                    media="(min-width: 1200px)"
                    srcSet={`${aboutLGJpg} 2000w`}
                />
                <source
                    type="image/jpeg"
                    media="(min-width: 992px)"
                    srcSet={`${aboutMDJpg} 1440w`}
                />
                <source
                    type="image/jpeg"
                    media="(min-width: 768px)"
                    srcSet={`${aboutSMJpg} 768w`}
                />
                <source type="image/jpeg" srcSet={`${aboutXSJpg} 576w`} />

                <img
                    className="hero-background-image"
                    alt="People enjoying outdoor adventures with friends and family."
                    src={aboutLGJpg}
                    srcSet={`${aboutLGJpg} 2000w`}
                />
            </picture>
        </div>
    );
}

// cSpell:ignore webp
