/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import cx from 'classnames';
import { NoMapboxGlNotice, Spinner } from 'sarsaparilla';

function LoadingSpinner({ mapRef, className, isMapLoaded }) {
    className = className || 'mapbox-basic-map';
    const modifierClass = !isMapLoaded ? 'loading' : '';

    return (
        <div
            ref={mapRef}
            className={cx(
                'maps-loading-spinner',
                {
                    [`maps-loading-spinner--${modifierClass}`]: modifierClass,
                },
                { [className]: className }
            )}
        >
            {!isMapLoaded && <Spinner />}
            {!mapboxgl.supported() && <NoMapboxGlNotice />}
        </div>
    );
}

LoadingSpinner.propTypes = {
    className: PropTypes.string,
    mapRef: PropTypes.func,
    isMapLoaded: PropTypes.bool,
};

export default LoadingSpinner;
