/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

/*
 * AboutUsOurPartners.jsx
 * Created by Destin Frasier on 09/20/18
 * Updated on 04/27/23
 */

import * as React from 'react';
import {
    Heading,
    ContentContainerBodyShift,
    ExternalLink,
    useBreakpoint,
    useFlags,
} from 'sarsaparilla';

import {
    PartnerColorIcon,
    type PartnerColorIconName,
} from '../Icons/Partner/PartnerColorIcon';

const partners = [
    {
        iconName: 'BLM',
        name: 'Bureau of Land Management',
        description:
            'The BLM manages more than 245 million acres located primarily in 12 Western states, including Alaska. The public lands we manage offer more recreational opportunities than lands managed by any other federal agency, with more than 99 percent available for no fee. The BLM works closely with partners so visitors of all ages can enjoy countless types of outdoor activities as widely varied as camping and hiking to hunting, fishing, whitewater rafting and off - highway vehicle driving. With more than 55 million people living within 25 miles of these lands, BLM managed lands are recognized as America\'s Great Outdoors, a "Backyard to Backcountry" treasure.',
        link: 'https://www.blm.gov/',
        shortName: 'blm',
    },

    {
        iconName: 'DOI',
        name: 'Bureau of Reclamation',
        description:
            "Bureau of Reclamation water projects are among the Nation's most popular for water-based outdoor recreation and include approximately 6.5 million acres of land and water that is, for the most part, available for public outdoor recreation. In addition to offering water-based outdoor recreation opportunities, these recreation areas often include important natural and cultural resources and provide unique educational and interpretive opportunities. Twelve Reclamation water projects have been designated as National Recreation Areas that are managed by the National Park Service or the US Forest Service.",
        link: 'https://www.usbr.gov/',
        shortName: 'doi',
    },

    {
        iconName: 'NationalArchives',
        name: 'National Archives & Records Administration',
        description:
            'The National Archives and Records Administration serves American democracy by safeguarding and preserving the records of our Government, ensuring that the people can discover, use, and learn from this documentary heritage. We ensure continuing access to the essential documentation of the rights of American citizens and the actions of their government. We support democracy, promote civic education, and facilitate a historical understanding of our national experience.',
        link: 'https://www.archives.gov/',
        shortName: 'archives',
    },

    {
        iconName: 'NPS',
        name: 'National Park Service',
        description:
            'The National Park System covers more than 84 million acres and is comprised of 417 sites with at least 19 different designations. These include 129 historical parks or sites, 87 national monuments, 59 national parks, 25 battlefields or military parks, 19 preserves, 18 recreation areas, 10 seashores, four parkways, four lakeshores, and two reserves. The NPS also helps administer dozens of affiliated sites, the National Register of Historic Places, National Heritage Areas, National Wild and Scenic Rivers, National Historic Landmarks, and National Trails.',
        link: 'https://www.nps.gov/index.htm',
        shortName: 'nps',
    },

    {
        iconName: 'NDW',
        name: 'Naval District Washington',
        description:
            'Naval District Washington (NDW) is known as the “Quarterdeck of the Navy”. With the nation’s Capital at its heart, NDW encompasses more than 4,000 square miles in Virginia and Maryland in the National Capital Region (NCR). Naval District Washington delivers shore readiness through assigned installations to enable mission execution, and support foreign and domestic visits and ceremonies. Naval District Washington is a proactive and anticipatory component to the NCR Joint Task Force, and partners with local, state, and federal agencies within the Region. The Morale, Welfare and Recreation program is an extension of NDW and conducts activities and events, and delivers high-quality, innovative recreation programs.',
        link: 'https://www.navyregionmwrndw.com/',
        shortName: 'ndw',
    },

    {
        iconName: 'PT',
        name: 'Presidio Trust',
        description:
            'The Presidio Trust is an innovative federal agency that stewards and shares the history, beauty, and wonder of the Presidio for everyone to enjoy forever. The Trust pursues a triple bottom line of people, planet, and performance. It works to ensure the Presidio is visited and loved by all, models outstanding practices to protect nature and reduce environmental impact, and operates efficiently and effectively in service to the public. The Trust earns the funds to manage the Presidio by operating park businesses, from leasing homes and workplaces to offering visitor amenities. Unlike other national parks, the Trust does not receive a direct annual appropriation. The Trust manages the Presidio in partnership with the National Park Service and with support from the Golden Gate National Parks Conservancy.',
        link: 'https://presidio.gov/about/presidio-trust',
        shortName: 'pt',
    },

    {
        iconName: 'FWS',
        name: 'US Fish and Wildlife Service',
        description:
            "For boundless opportunities to discover nature in all its splendor, national wildlife refuges are unsurpassed. From all parts of the globe, more than 47 million visitors flock to these natural treasure troves each year. The National Wildlife Refuge System's extensive trails, auto tour routes, boardwalks, observation decks, hunting and photography blinds, fishing piers and boat launches offer great opportunities to learn and have fun.",
        link: 'https://www.fws.gov/',
        shortName: 'fws',
    },

    {
        iconName: 'USACE',
        name: 'US Army Corps of Engineers',
        description:
            "The U.S. Army Corps of Engineers is one of the nation's leading federal providers of outdoor recreation with more than 400 lake and river projects in 43 states. Visitors of all ages can enjoy traditional activities like hiking, boating, fishing, camping and hunting, and for those slightly more adventurous there is snorkeling, windsurfing, whitewater rafting, mountain biking and geocaching. With 90 percent of our recreation areas located within 50 miles of a major metropolitan center, there is a Corps site near you.",
        link: 'https://www.usace.army.mil/Missions/Civil-Works/Recreation/',
        shortName: 'usace',
    },

    {
        iconName: 'ForestService',
        name: 'US Forest Service',
        description:
            'The U.S. Forest Service manages forests and grasslands across the country that offer vast, scenic beauty waiting for you to discover. Visitors who choose to recreate on these public lands can find more than 158,000 miles of trails, 10,000 developed recreation sites, 57,000 miles of streams, 122 alpine ski areas, 338,000 heritage sites, and specially designated sites that include 9,100 miles of byways, 22 recreation areas, 11 scenic areas, 439 wilderness areas, 122 wild and scenic rivers, nine monuments, and one preserve.',
        link: 'https://www.fs.usda.gov/',
        shortName: 'fs',
    },
] as const;

const partnersWhoShareData = [
    {
        iconName: 'BEP',
        name: 'Bureau of Engraving and Printing',
        description:
            'The mission of the BEP is to design and manufacture high-quality security documents that deter counterfeiting and meet customer requirements for quality, quantity and performance. The Bureau of Engraving and Printing designs, prints, and furnishes a large variety of security documents, including Federal Reserve Notes, identification cards, naturalization certificates, and other special security documents.',
        link: 'https://www.moneyfactory.gov/',
        shortName: 'tre',
    },

    {
        iconName: 'DOT',
        name: 'Federal Highway Administration',
        description:
            "America's Byways is the umbrella term we use for the collection of 150 distinct and diverse roads designated by the U.S. Secretary of Transportation. America's Byways include the National Scenic Byways and All - American Roads. America's Byways are gateways to adventures where no two experiences are the same. Through the National Scenic Byways Program, the Federal Highway Administration invites you to come closer to America's heart and soul.",
        link: 'https://www.fhwa.dot.gov/',
        shortName: 'dot',
    },

    {
        iconName: 'NOAA',
        name: 'National Oceanic & Atmospheric Administration',
        description:
            'We enrich life through science. People rely on us for weather forecasts and warnings. Our scientists look at the world up close and far away. We also protect sanctuaries and research reserves for people to enjoy today and in the future. This includes a network of underwater parks – 13 national marine sanctuaries that provide beautiful settings for recreational activities. From wreck diving to whale watching, you can find an adventure! NOAA also partners with coastal states to manage 29 national estuarine research reserves that serve as living laboratories as well as protected areas for kayaking and bird watching. We hope you join us in our blue parks to celebrate the rich diversity of environments along our coasts and Great Lakes!',
        link: 'https://www.noaa.gov/',
        shortName: 'noaa',
    },

    {
        iconName: 'Smithsonian',
        name: 'Smithsonian Institution',
        description:
            'Visitors of Smithsonian can enjoy 19 world-class museums, galleries, gardens, and a zoo. The Smithsonian offers eleven museums and galleries on the National Mall and six other museums and the National Zoo in the greater National Capital Area. In New York City, we invite you to tour two museums in historic settings.',
        link: 'https://www.si.edu/',
        shortName: 'smithsonian',
    },

    {
        iconName: 'TVA',
        name: 'Tennessee Valley Authority',
        description:
            "TVA was created by Congress in 1933 and charged with a unique mission—to improve the quality of life in the Valley through the integrated management of the region's resources. For more than eight decades, we have worked tirelessly to carry out that mission, and we are working just as hard to make life better for the nine million people who live, work and play in the Valley today. We serve the people of the Tennessee Valley by focusing on three key areas: energy, environment and economic development.",
        link: 'https://www.tva.gov/',
        shortName: 'tva',
    },
] as const;

function IconLink({
    url,
    name,
    iconName,
    size,
}: {
    url: string;
    name: string;
    iconName: PartnerColorIconName;
    size: 'xl' | 'xxl';
}) {
    return (
        <ExternalLink
            url={url}
            aria-label={`Visit the ${name} website`}
            hasExternalIcon={false}
        >
            <PartnerColorIcon iconName={iconName} size={size} />
        </ExternalLink>
    );
}

export default function AboutUsOurPartners() {
    const { isMdAndUp, isSmAndDown } = useBreakpoint();

    // Set the LD Flag
    const { ndwContent } = useFlags();

    // Use the LD flag to show the Naval District Washington partner block on a specific date
    const newPartners = ndwContent
        ? partners.filter((partner) => partner.name !== 'Naval District Washington')
        : partners;

    const logoSize = isMdAndUp ? 'xxl' : 'xl';

    return (
        <ContentContainerBodyShift className="mb-10">
            <Heading hasUnderline="center" headingLevel={2} appearance="h4">
                Agencies That Offer Reservations on Recreation.gov
            </Heading>

            <div className="r1s-partner-wrap">
                {newPartners.map((partner) => (
                    <div className="r1s-partner" key={partner.shortName}>
                        {/* Show the icon above the title when the width of the page is more than 768px */}
                        {isMdAndUp ? (
                            <div className="r1s-partner-media">
                                <IconLink
                                    url={partner.link}
                                    name={partner.name}
                                    iconName={partner.iconName}
                                    size={logoSize}
                                />
                            </div>
                        ) : null}

                        <div className="r1s-partner-content">
                            <Heading headingLevel={3} appearance="h5" className="mb-1">
                                {partner.name}
                            </Heading>
                            <div className="r1s-partner-description">
                                {/* Show the icon below the title when the width of the page is less than 768px */}
                                {isSmAndDown ? (
                                    <div className="r1s-partner-media">
                                        <IconLink
                                            url={partner.link}
                                            name={partner.name}
                                            iconName={partner.iconName}
                                            size={logoSize}
                                        />
                                    </div>
                                ) : null}
                                <p>{partner.description}</p>
                            </div>

                            <div className="r1s-partner-actions">
                                <ExternalLink
                                    className="rec-button-link"
                                    url={partner.link}
                                    aria-label={`Visit the ${partner.name} website`}
                                >
                                    Visit the {partner.name} website
                                </ExternalLink>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Heading
                hasUnderline="center"
                headingLevel={2}
                appearance="h4"
                className="rec-section-heading mt-10"
            >
                Agencies That Share Data on Recreation.gov
            </Heading>

            <div className="r1s-partner-wrap">
                {partnersWhoShareData.map((partner) => (
                    <div className="r1s-partner" key={partner.shortName}>
                        {/* Show the icon above the title when the width of the page is more than 768px */}
                        {isMdAndUp && (
                            <div className="r1s-partner-media">
                                <IconLink
                                    url={partner.link}
                                    name={partner.name}
                                    iconName={partner.iconName}
                                    size={logoSize}
                                />
                            </div>
                        )}

                        <div className="r1s-partner-content">
                            <Heading headingLevel={3} appearance="h5" className="mb-1">
                                {partner.name}
                            </Heading>
                            <div className="r1s-partner-description">
                                {/* Show the icon below the title when the width of the page is less than 768px */}
                                {isSmAndDown && (
                                    <div className="r1s-partner-media">
                                        <IconLink
                                            url={partner.link}
                                            name={partner.name}
                                            iconName={partner.iconName}
                                            size={logoSize}
                                        />
                                    </div>
                                )}
                                <p>{partner.description}</p>
                            </div>

                            <div className="r1s-partner-actions">
                                <ExternalLink
                                    className="rec-button-link"
                                    url={partner.link}
                                    aria-label={`Visit the ${partner.name} website`}
                                >
                                    Visit the {partner.name} website
                                </ExternalLink>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </ContentContainerBodyShift>
    );
}

// cSpell:ignore noaa, lakeshores, usace
