/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * NewsletterModal.jsx
 * Created by michaelbray on 2019-04-10.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StyledModal, ModalActions, Button } from 'sarsaparilla';

export default class NewsletterModal extends React.PureComponent {
    static propTypes = {
        isModalOpen: PropTypes.bool,
        closeModal: PropTypes.func,
    };

    render() {
        return (
            <StyledModal
                shouldActionsStickInMobile
                shouldShowCloseButton={false}
                size="md"
                isOpen={this.props.isModalOpen}
                heading={`Thanks for signing up to stay in touch with ${process.env.SITE_NAME}!`}
                onRequestClose={this.closeModal}
            >
                <p>
                    You&#39;ve been added to our mailing list, so be on the lookout for
                    inspiration in your inbox.
                </p>

                <ModalActions>
                    <Button onClick={this.props.closeModal}>Ok</Button>
                </ModalActions>
            </StyledModal>
        );
    }
}
