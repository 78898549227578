/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

/*
 * AboutUsExperiences.jsx
 * Created by Destin Frasier on 09/20/18
 */

import * as React from 'react';
import {
    Heading,
    ContentContainerBodyShift,
    FlexRow,
    FlexCol,
    BrandingIcon,
} from 'sarsaparilla';

const experiences = [
    {
        icon: <BrandingIcon iconName="camping" />,
        title: 'Camping',
        shortName: 'camping',
    },
    {
        icon: <BrandingIcon iconName="picnicking" />,
        title: 'Picnicking',
        shortName: 'picnicking',
    },
    {
        icon: <BrandingIcon iconName="hiking" />,
        title: 'Hiking',
        shortName: 'hiking',
    },
    {
        icon: <BrandingIcon iconName="rafting" />,
        title: 'Rafting',
        shortName: 'rafting',
    },
    {
        icon: <BrandingIcon iconName="ranger" />,
        title: 'Ranger-Led Experiences',
        shortName: 'experiences',
    },
    {
        icon: <BrandingIcon iconName="historic-tour" />,
        title: 'Historic & Cultural Tours',
        shortName: 'tours',
    },
    {
        icon: <BrandingIcon iconName="rv" />,
        title: 'RVing',
        shortName: 'rv-ing',
    },
    {
        icon: <BrandingIcon iconName="binoculars" />,
        title: 'Wildlife Viewing',
        shortName: 'wildlife',
    },
    {
        icon: <BrandingIcon iconName="events" />,
        title: 'Events & Programs',
        shortName: 'events',
    },
    {
        icon: <BrandingIcon iconName="hunting" />,
        title: 'Hunting & Fishing',
        shortName: 'hunting',
    },
    {
        icon: <BrandingIcon iconName="biking" />,
        title: 'Biking',
        shortName: 'biking',
    },
    {
        icon: <BrandingIcon iconName="lodging" />,
        title: 'Unique Lodging',
        shortName: 'lodging',
    },
    {
        icon: <BrandingIcon iconName="riding" />,
        title: 'Recreational Riding',
        shortName: 'riding',
    },
    {
        icon: <BrandingIcon iconName="horseback-riding" />,
        title: 'Horseback Riding',
        shortName: 'horseback',
    },
    {
        icon: <BrandingIcon iconName="boating" />,
        title: 'Boating',
        shortName: 'boating',
    },
];

export default function AboutUsExperiences() {
    return (
        <ContentContainerBodyShift className="r1s-section-experiences">
            <Heading hasUnderline="center" headingLevel={2} appearance="h4">
                Experiences & Activities
            </Heading>
            <FlexRow>
                <FlexCol lgOffset={1} lg={10}>
                    <p className="r1s-sub-text">
                        The term recreation is about as broad as the great outdoors
                        itself. To better clarify some of the activities available through
                        Recreation.gov, below is a sample of the offerings provided by the
                        14 federal agencies.
                    </p>
                </FlexCol>
            </FlexRow>

            <div className="r1s-media-wrap">
                {experiences.map((experience) => (
                    <div className="r1s-media" key={experience.shortName}>
                        <div className="r1s-media-object">{experience.icon}</div>
                        <div className="r1s-media-description">{experience.title}</div>
                    </div>
                ))}
            </div>

            <div className="r1s-tagline-row">
                <div className="r1s-tagline">
                    And many more experiences to help you{' '}
                    <span className="strong">bring home a story!</span>
                </div>
            </div>
        </ContentContainerBodyShift>
    );
}
