/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * SiteMapItem.jsx
 * Created by Destin Frasier on 09/16/19
 */

import React from 'react';
import PropTypes from 'prop-types';
import { SecurityHelper, Icon } from 'sarsaparilla';

export default class SiteMapItem extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        url: PropTypes.string,
        requiresLogin: PropTypes.bool,
        openNewTab: PropTypes.bool,
    };

    static defaultProps = {
        name: '',
        url: '',
        requiresLogin: false,
        openNewTab: false,
    };

    render() {
        const loggedIn = SecurityHelper.isLoggedIn();
        const toggleFiltersButton = (
            <span className="nav-site-map-requires-login">
                <Icon iconName="lock" aria-label="You must login to access this page" />
            </span>
        );

        return (
            <li key={this.name}>
                {this.props.openNewTab ? (
                    <a href={this.props.url} target="_blank" rel="noopener noreferrer">
                        {this.props.name}{' '}
                        {this.props.requiresLogin && !loggedIn
                            ? toggleFiltersButton
                            : null}
                    </a>
                ) : (
                    <a href={this.props.url}>
                        {this.props.name}{' '}
                        {this.props.requiresLogin && !loggedIn
                            ? toggleFiltersButton
                            : null}
                    </a>
                )}
            </li>
        );
    }
}
