/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom-v5-compat';

import { LaunchDarklyProvider } from 'sarsaparilla';
import { RestoreScroll } from 'shared-ui/components/RestoreScroll/RestoreScroll';

import App from './App';
import { store } from '../src/redux/store';
import './index.scss';

const root = createRoot(document.getElementById('recApp'));

root.render(
    <Provider store={store}>
        <BrowserRouter
            future={{
                v7_startTransition: true,
                v7_relativeSplatPath: true,
            }}
        >
            <RestoreScroll />
            <LaunchDarklyProvider>
                <App />
            </LaunchDarklyProvider>
        </BrowserRouter>
    </Provider>
);
