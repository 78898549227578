/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './PartnerColorIcon.scss';

import * as React from 'react';

import imageSrc from './partner-icons-color@2x.webp';

const iconDetail = {
    BLM: {
        title: 'Bureau of Land Management',
        offset: 0,
    },
    DOT: {
        title: 'Department of Transportation',
        offset: -100,
    },
    FWS: {
        title: 'US Fish and Wildlife Service',
        offset: -200,
    },
    NOAA: {
        title: 'National Oceanic and Atmospheric Administration',
        offset: -300,
    },
    ForestService: {
        title: 'USDA Forest Service',
        offset: -400,
    },
    NPS: {
        title: 'National Park Service',
        offset: -500,
    },
    NationalArchives: {
        title: 'National Archives',
        offset: -600,
    },
    Smithsonian: {
        title: 'Smithsonian Institution',
        offset: -700,
    },
    TVA: {
        title: 'Tennessee Valley Authority',
        offset: -800,
    },
    USACE: {
        title: 'US Army Corps of Engineers',
        offset: -900,
    },
    DOI: {
        title: 'Bureau of Reclamation',
        offset: -1000,
    },
    BEP: {
        title: 'Bureau of Engraving and Printing',
        offset: -1100,
    },
    PT: {
        title: 'Presidio Trust',
        offset: -1200,
    },
    NDW: {
        title: 'Naval District Washington',
        offset: -1300,
    },
} as const;

export type PartnerColorIconName = keyof typeof iconDetail;

interface PartnerColorIconProps extends React.HTMLAttributes<HTMLDivElement> {
    iconName: PartnerColorIconName;
    size?: 'xl' | 'xxl';
    showTitle?: boolean;
}

export function PartnerColorIcon({
    iconName,
    size = 'xxl',
    showTitle = false,
    ...rest
}: PartnerColorIconProps) {
    if (!iconName || !iconDetail?.[iconName]) return null;

    const { title, offset } = iconDetail[iconName];

    return (
        <div
            data-component="r1s-partner-color-icon"
            data-size={size}
            title={showTitle ? title : undefined}
            role="img"
            aria-label={title}
            {...rest}
        >
            <div
                className="sprite"
                style={{
                    backgroundImage: `url(${imageSrc})`,
                    backgroundPosition: `${offset}px ${size === 'xxl' ? 0 : -100}px`,
                    backgroundSize: '1396px',
                }}
            />
        </div>
    );
}
