/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import {
    ContentContainer,
    Heading,
    FlexRow,
    FlexCol,
    Icon,
    ExternalLink,
} from 'sarsaparilla';

import mobileHandImage from './images/mobile-section/mobile-hand.webp';
import mobileHandImageSm from './images/mobile-section/mobile-hand-sm.webp';

const listItems = [
    {
        icon: <Icon iconName="tent-open" size="lg" />,
        title: 'Recreation.gov (iOS and Android)',
        url: 'https://www.recreation.gov/mobile-app',
        text: 'Public-facing app that allows visitors to find and reserve campsites, review location details for a trip, and quickly access information on past and upcoming reservations. The app also features Scan & Pay to pay directly from your mobile device for activity passes and first-come, first-served campsites.',
        isExternal: false,
    },
    {
        icon: <Icon iconName="phone-iphone" size="lg" />,
        title: 'R1S Ranger App (iOS)',
        url: 'https://apps.apple.com/us/app/r1s-ranger/id1523343701',
        text: 'Agency Facility Manager app for collecting and updating campground and campsite GIS and photos.',
        isExternal: true,
    },
    {
        icon: <Icon iconName="scan" size="lg" />,
        title: 'R1S Digital Scanner (iOS)',
        url: 'https://apps.apple.com/kn/app/r1s-digital-scanner/id1455233513',
        text: 'Agency Facility Manager app for validating and scanning QR codes for tickets and site-specific passes.',
        isExternal: true,
    },
];

export default function MobileSection() {
    return (
        <section className="nav-ni-mobile-section-wrap">
            <ContentContainer className="my-0">
                <FlexRow className="mb-4 nav-ni-mobile-section-content-wrap">
                    <FlexCol md={8} xl={7} mdOrder={2}>
                        <Heading
                            headingLevel={2}
                            appearance="h4"
                            className="nav-ni-mobile-section-sub-heading"
                        >
                            Overview of Recreation.gov Mobile Applications
                        </Heading>

                        {listItems.map((listItem, index) => (
                            <div
                                className="nav-ni-mobile-section-content-list-item"
                                key={index}
                            >
                                {listItem.icon}

                                <div className="nav-ni-mobile-section-content-list-text">
                                    <div className="item-title">
                                        {listItem.isExternal ? (
                                            <ExternalLink
                                                url={listItem.url}
                                                hasExternalIcon
                                            >
                                                {listItem.title}
                                            </ExternalLink>
                                        ) : (
                                            <a href={listItem.url}>{listItem.title}</a>
                                        )}
                                    </div>
                                    <p>{listItem.text}</p>
                                </div>
                            </div>
                        ))}
                    </FlexCol>

                    <FlexCol
                        md={4}
                        xl={5}
                        mdOrder={1}
                        className="nav-ni-mobile-section-content-picture-wrap"
                    >
                        <picture>
                            <source
                                media="(max-width: 768px)"
                                srcSet={mobileHandImageSm}
                                type="image/webp"
                            />
                            <img
                                src={mobileHandImage}
                                height={482}
                                width={334}
                                alt="Hand holding a phone displaying the Recreation.gov app"
                            />
                        </picture>
                    </FlexCol>
                </FlexRow>
            </ContentContainer>
        </section>
    );
}
