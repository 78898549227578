/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * Populates the conte of a single article (type = article, location-spotlight, journal)
 * @url /version/content/type/id
 */
export const ARTICLE_VERSION = 'g_ba2';

/**
 * Populates the articles on the Article Landing page, 9 per page (limit = Max 9, and offset = 0, 9, 18, 27...)
 * @url /version/content/all?offset=0&limit=9
 */
export const ARTICLES_VERSION = 'g_ba2';

/**
 * Populates the featured article at the top of the Article Landing page
 * @url /version/content/featured
 */
export const FEATURED_VERSION = 'g_ba2';

/**
 * Populates the 10 articles on the Homepage
 * @url /version/content/homepage
 */
export const HOMEPAGE_FEATURED_VERSION = 'g_ba2';
