/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'sarsaparilla';
import LayersPanel from '../controls/LayersPanel';
import { NONE, VISIBILITY, VISIBLE } from '../constants';
import LayersPanelButton from '../controls/LayersPanelButton';

function useLayerPanel({
    map,
    basicControls,
    hasTrails,
    hasEv,
    hasReservableLocations,
    updateReservableLocationsLayer,
    updateEvLayer,
    mapEvFilters,
    mapFacilitiesToDisplay,
    iconsToDisplay,
}) {
    const { width } = useWindowSize();
    const isMobile = width && width < 768;
    const trailsLayerName = 'trailsSourceNameLineLayer';
    const trailsBorderLayerName = 'trailsSourceNameBorderLineLayer';
    const evLayerName = 'evSourceNameLayer';
    const evLabelName = 'evSourceNameCustomLabelLayer';
    const evCircleName = 'evSourceNameCircleLayer';
    const evClusterCircleName = 'evSourceNameClusterCircleLayer';
    const evClusterLabelLayer = 'evSourceNameClusterLabelLayer';
    const trailsElementId = 'map-layers-panel-control';
    const mapSource = 'mapSource';

    const [showPanel, setShowPanel] = useState(false);
    const [isTrailVisible, setIsTrailVisible] = useState(false);
    const [isEvVisible, setIsEvVisible] = useState(false);
    const [displayTrailsMessage, setDisplayTrailsMessage] = useState(true);
    const [isSatelliteView, setIsSatelliteView] = useState(false);

    const onTrailsClick = () => {
        if (!map?.current) return;

        [trailsLayerName, trailsBorderLayerName].forEach((trailLayer) => {
            const visibility = map.current.getLayoutProperty(trailLayer, VISIBILITY);
            const isLayerVisible = visibility === VISIBLE;
            map.current.setLayoutProperty(
                trailLayer,
                VISIBILITY,
                isLayerVisible ? NONE : VISIBLE
            );
            if (trailLayer === trailsLayerName) setIsTrailVisible(!isLayerVisible);
        });
    };

    const onEvClick = () => {
        if (!map.current) return;
        [
            evLabelName,
            evCircleName,
            evLayerName,
            evClusterCircleName,
            evClusterLabelLayer,
        ].forEach((evLayer) => {
            const visibility = map.current.getLayoutProperty(evLayer, VISIBILITY);
            const isLayerVisible = visibility === VISIBLE;
            map.current.setLayoutProperty(
                evLayer,
                VISIBILITY,
                isLayerVisible ? NONE : VISIBLE
            );
            if (evLayer === evLabelName) setIsEvVisible(!isLayerVisible);
        });
    };

    const onMapStyleClick = (isSatellite) => {
        if (!map.current) return;
        map.current.setLayoutProperty(
            'fixed-satellite',
            VISIBILITY,
            isSatellite ? VISIBLE : NONE
        );
        setIsSatelliteView(isSatellite);
    };

    const getLayersPanelButton = () => {
        const props = {
            hasTrails,
            showPanel,
            setShowPanel,
            displayTrailsMessage,
            setDisplayTrailsMessage,
        };
        return LayersPanelButton(props);
    };

    // On mount, add the layers panel
    useEffect(
        () => {
            if (basicControls?.current?.layersPanelControl) {
                basicControls.current.layersPanelControl.addElement(
                    getLayersPanelButton(),
                    trailsElementId
                );
            }
        },
        // Should run only when the control changes ... basically on mount ... so we don't need to memoize the functions or props
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [basicControls?.current?.layersPanelControl]
    );

    // On trails result, panel visibility status or trail visibility status,
    // update panel content
    useEffect(
        () => {
            if (basicControls?.current?.layersPanelControl) {
                basicControls.current.layersPanelControl.updateElementById(
                    getLayersPanelButton(),
                    trailsElementId
                );
            }
        },
        // Should run when getting trails results, changing panel or trail data visibility status
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showPanel, isSatelliteView, hasTrails]
    );

    useEffect(() => {
        map?.current?.setFilter(`${mapSource}Layer`, ['in', 'icon', ...iconsToDisplay]);
        map?.current?.setFilter(`${mapSource}CircleLayer`, [
            'in',
            'icon',
            ...iconsToDisplay,
        ]);
    }, [iconsToDisplay]);

    return (
        <LayersPanel
            onTrailsClick={onTrailsClick}
            onEvClick={onEvClick}
            onMapStyleClick={onMapStyleClick}
            hasTrails={hasTrails}
            hasEv={hasEv}
            hasReservableLocations={hasReservableLocations}
            showPanel={showPanel}
            setShowPanel={setShowPanel}
            isTrailVisible={isTrailVisible}
            isEvVisible={isEvVisible}
            updateReservableLocationsLayer={updateReservableLocationsLayer}
            updateEvLayer={updateEvLayer}
            mapEvFilters={mapEvFilters}
            mapFacilitiesToDisplay={mapFacilitiesToDisplay}
            displayTrailsMessage={displayTrailsMessage}
            setDisplayTrailsMessage={setDisplayTrailsMessage}
            isMobile={isMobile}
            isSatelliteView={isSatelliteView}
        />
    );
}

export default useLayerPanel;
