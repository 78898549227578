/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { useState } from 'react';
import {
    FlexRow,
    FlexCol,
    Heading,
    Icon,
    Button,
    Tabs,
    TabPanel,
    ButtonGroup,
} from 'sarsaparilla';

// 2022 Newsletter Archive
const newsletterArchive2022s = [
    {
        month: 'January',
        downloadLink: '507&m=39375',
    },
];

// 2021 Newsletter Archive
const newsletterArchive2021s = [
    {
        month: 'December',
        downloadLink: '712&m=53078',
    },
    {
        month: 'November',
        downloadLink: '681&m=52077',
    },
    {
        month: 'October',
        downloadLink: '662&m=51666',
    },
    {
        month: 'September',
        downloadLink: '652&m=50912',
    },
    {
        month: 'August',
        downloadLink: '633&m=50084',
    },
    {
        month: 'July',
        downloadLink: '619&m=49350',
    },
    {
        month: 'June',
        downloadLink: '599&m=48732',
    },
    {
        month: 'May',
        downloadLink: '564&m=48049',
    },
    {
        month: 'April',
        downloadLink: '555&m=46684',
    },
    {
        month: 'March',
        downloadLink: '541&m=44398',
    },
    {
        month: 'February',
        downloadLink: '523&m=41623',
    },
    {
        month: 'January',
        downloadLink: '507&m=39375',
    },
];

// 2020 Newsletter Archive
const newsletterArchive2020s = [
    {
        month: 'December',
        downloadLink: '489&m=37030',
    },
    {
        month: 'November',
        downloadLink: '462&m=33970',
    },
    {
        month: 'October',
        downloadLink: '451&m=31522',
    },
    {
        month: 'September',
        downloadLink: '442&m=28979',
    },
    {
        month: 'Summer Edition',
        downloadLink: '407&m=21939',
    },
    {
        month: 'May',
        downloadLink: '394&m=20883',
    },
    {
        month: 'April',
        downloadLink: '365&m=19530',
    },
    {
        month: 'March',
        downloadLink: '348&m=18924',
    },
    {
        month: 'February',
        downloadLink: '336&m=17211',
    },
    {
        month: 'January',
        downloadLink: '326&m=14920',
    },
];

// 2019 Newsletter Archive
const newsletterArchive2019s = [
    {
        month: 'December',
        downloadLink: '320&m=13507',
    },
    {
        month: 'November',
        downloadLink: '296&m=9853',
    },
    {
        month: 'October',
        downloadLink: '289&m=7881',
    },
    {
        month: 'September',
        downloadLink: '263&m=5743',
    },
    {
        month: 'August',
        downloadLink: '231&m=3148',
    },
    {
        month: 'July',
        downloadLink: '198&m=2887',
    },
    {
        month: 'June',
        downloadLink: '191&m=2479',
    },
    {
        month: 'May',
        downloadLink: '162&m=2214',
    },
    {
        month: 'April',
        downloadLink: '160&m=1015',
    },
    {
        month: 'March',
        downloadLink: '141&m=932',
    },
    {
        month: 'February',
        downloadLink: '102&m=451',
    },
    {
        month: 'January',
        downloadLink: '101&m=450',
    },
];

export default function MediaCenterNewsletterArchive() {
    const pageSize = 4;
    const [visibleNewsletterArchives, setVisibleNewsletterArchives] = useState(pageSize);
    const showMoreNewsletterItems = () =>
        setVisibleNewsletterArchives(visibleNewsletterArchives + pageSize);

    return (
        <section className="nav-media-center-news archive mt-4 px-3 px-md-5 mb-8">
            <FlexRow justifyContent="center">
                <FlexCol xl={10} lg={12}>
                    <FlexRow>
                        <FlexCol lg={5}>
                            <Heading
                                headingLevel={2}
                                appearance="h4"
                                className="mt-3 mb-2"
                            >
                                Newsletter Archive
                            </Heading>
                            <p>Read past editions of The Story Board.</p>
                        </FlexCol>
                        <FlexCol lg={7}>
                            <Tabs id="newsletter-items" stretchTabsToFit={false}>
                                <TabPanel label="2022">
                                    <ul className="nav-media-center-news-list">
                                        {newsletterArchive2022s
                                            .slice(0, visibleNewsletterArchives)
                                            .map((newsletterArchive2022) => (
                                                <li
                                                    className="nav-media-center-news-item archive-item"
                                                    key={
                                                        newsletterArchive2022.downloadLink
                                                    }
                                                >
                                                    {newsletterArchive2022.month}{' '}
                                                    Newsletter
                                                    <Button
                                                        appearance="link"
                                                        iconAfterElement={
                                                            <Icon iconName="visibility" />
                                                        }
                                                        href={`https://recreationgov.activehosted.com/index.php?action=social&c=${newsletterArchive2022.downloadLink}`}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                        aria-label={`${newsletterArchive2022.month} Newsletter`}
                                                    >
                                                        View
                                                    </Button>
                                                </li>
                                            ))}
                                    </ul>

                                    {newsletterArchive2022s.length >= 5 && (
                                        <ButtonGroup align="right" className="mt-3">
                                            <Button
                                                appearance="tertiary"
                                                onClick={showMoreNewsletterItems}
                                                isDisabled={
                                                    visibleNewsletterArchives >=
                                                    newsletterArchive2022s.length
                                                }
                                            >
                                                {visibleNewsletterArchives >=
                                                newsletterArchive2022s.length
                                                    ? 'All Newsletters From 2022 Have Been Loaded'
                                                    : 'Load More'}
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </TabPanel>
                                <TabPanel label="2021">
                                    <ul className="nav-media-center-news-list">
                                        {newsletterArchive2021s
                                            .slice(0, visibleNewsletterArchives)
                                            .map((newsletterArchive2021) => (
                                                <li
                                                    className="nav-media-center-news-item archive-item"
                                                    key={
                                                        newsletterArchive2021.downloadLink
                                                    }
                                                >
                                                    {newsletterArchive2021.month}{' '}
                                                    Newsletter
                                                    <Button
                                                        appearance="link"
                                                        iconAfterElement={
                                                            <Icon iconName="visibility" />
                                                        }
                                                        href={`https://recreationgov.activehosted.com/index.php?action=social&c=${newsletterArchive2021.downloadLink}`}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                        aria-label={`${newsletterArchive2021.month} Newsletter`}
                                                    >
                                                        View
                                                    </Button>
                                                </li>
                                            ))}
                                    </ul>

                                    {newsletterArchive2021s.length >= 5 && (
                                        <ButtonGroup align="right" className="mt-3">
                                            <Button
                                                appearance="tertiary"
                                                onClick={showMoreNewsletterItems}
                                                isDisabled={
                                                    visibleNewsletterArchives >=
                                                    newsletterArchive2021s.length
                                                }
                                            >
                                                {visibleNewsletterArchives >=
                                                newsletterArchive2021s.length
                                                    ? 'All Newsletters From 2021 Have Been Loaded'
                                                    : 'Load More'}
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </TabPanel>
                                <TabPanel label="2020">
                                    <ul className="nav-media-center-news-list">
                                        {newsletterArchive2020s
                                            .slice(0, visibleNewsletterArchives)
                                            .map((newsletterArchive2020) => (
                                                <li
                                                    className="nav-media-center-news-item archive-item"
                                                    key={
                                                        newsletterArchive2020.downloadLink
                                                    }
                                                >
                                                    {newsletterArchive2020.month}{' '}
                                                    Newsletter
                                                    <Button
                                                        appearance="link"
                                                        iconAfterElement={
                                                            <Icon iconName="visibility" />
                                                        }
                                                        href={`https://recreationgov.activehosted.com/index.php?action=social&c=${newsletterArchive2020.downloadLink}`}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        View
                                                    </Button>
                                                </li>
                                            ))}
                                    </ul>
                                    {newsletterArchive2020s.length >= 5 && (
                                        <ButtonGroup align="right" className="mt-3">
                                            <Button
                                                appearance="tertiary"
                                                onClick={showMoreNewsletterItems}
                                                isDisabled={
                                                    visibleNewsletterArchives >=
                                                    newsletterArchive2020s.length
                                                }
                                            >
                                                {visibleNewsletterArchives >=
                                                newsletterArchive2020s.length
                                                    ? 'All Newsletters From 2020 Have Been Loaded'
                                                    : 'Load More'}
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </TabPanel>
                                <TabPanel label="2019">
                                    <ul className="nav-media-center-news-list">
                                        {newsletterArchive2019s
                                            .slice(0, visibleNewsletterArchives)
                                            .map((newsletterArchive2019) => (
                                                <li
                                                    className="nav-media-center-news-item archive-item"
                                                    key={
                                                        newsletterArchive2019.downloadLink
                                                    }
                                                >
                                                    {newsletterArchive2019.month}{' '}
                                                    Newsletter
                                                    <Button
                                                        appearance="link"
                                                        iconAfterElement={
                                                            <Icon iconName="visibility" />
                                                        }
                                                        href={`https://recreationgov.activehosted.com/index.php?action=social&c=${newsletterArchive2019.downloadLink}`}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                    >
                                                        View
                                                    </Button>
                                                </li>
                                            ))}
                                    </ul>
                                    <ButtonGroup align="right" className="mt-3">
                                        <Button
                                            appearance="tertiary"
                                            onClick={showMoreNewsletterItems}
                                            isDisabled={
                                                visibleNewsletterArchives >=
                                                newsletterArchive2019s.length
                                            }
                                        >
                                            {visibleNewsletterArchives >=
                                            newsletterArchive2019s.length
                                                ? 'All Newsletters From 2019 Have Been Loaded'
                                                : 'Load More'}
                                        </Button>
                                    </ButtonGroup>
                                </TabPanel>
                            </Tabs>
                        </FlexCol>
                    </FlexRow>
                </FlexCol>
            </FlexRow>
        </section>
    );
}
