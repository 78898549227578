/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * RulesReservationNotification.jsx
 * Created by Destin Frasier on 01/19/21
 * This is controlled via the launchdarkly "rules-reservation-policies-alert" flag.
 */

import { DisplayHtmlContent, useFlags, Alert } from 'sarsaparilla';

export default function RulesReservationNotification() {
    const { rulesReservationPoliciesAlert } = useFlags();

    // Bail if no data from LaunchDarkly
    if (!rulesReservationPoliciesAlert) {
        return null;
    }

    const [title, headingLevel, alertContent, alertType, className] =
        rulesReservationPoliciesAlert;

    const isOff =
        !Array.isArray(rulesReservationPoliciesAlert) ||
        rulesReservationPoliciesAlert.length === 0;

    // Don't show if the flag is set to off
    if (isOff) {
        return null;
    }

    let decodedAlertBody = '';
    try {
        decodedAlertBody = decodeURIComponent(alertContent);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        decodedAlertBody = '';
    }

    return (
        <Alert
            type={alertType}
            headingLevel={headingLevel}
            heading={title}
            className={className}
        >
            <DisplayHtmlContent html={decodedAlertBody} />
        </Alert>
    );
}
