/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import cx from 'classnames';
import { FlexRow, FlexCol, useWindowSize, HtmlParser } from 'sarsaparilla';

export default function LateCancellationTable() {
    const { width = 0 } = useWindowSize();

    const cancellationTable = [
        {
            colA: '<strong>Individual Campsites</strong>',
            colB: '$10.00 cancellation fee AND forfeit the first night&apos;s fees if cancelled after 12:00am facility&apos;s local time the day before the reservation start date (not to exceed the total paid for the original reservation). Late cancellations for a one-night reservation will forfeit the entire amount paid and will not be subject to an additional service fee.',
        },
        {
            colA: '<strong>Cabins / Lookouts / Yurts</strong>',
            colB: '$10.00 cancellation fee AND forfeit the first night&apos;s fees if cancelled less than 14 days before the arrival date (not to exceed the total paid for the original reservation).',
        },
        {
            colA: '<strong>Group Facilities - Overnight</strong>',
            colB: '$10.00 cancellation fee AND forfeit the first night&apos;s fees if cancelled less than 14 days before the arrival date (not to exceed the total paid for the original reservation).',
        },
        {
            colA: '<strong>Group Facilities - Day Use</strong>',
            colB: 'Forfeit the Day Use fees if cancelled less than 14 days before the arrival date (not to exceed the total paid for the original reservation).',
        },
        {
            colA: '<strong>Scan & Pay First-Come, First-Served Payments</strong>',
            colB: 'There are no refunds, modifications, cancellations, or other fee changes allowed with Scan & Pay payments.',
        },
    ];

    const colA = cancellationTable.map((cancel) => cancel.colA);
    const colB = cancellationTable.map((cancel) => cancel.colB);

    const inventoryListItems = (
        colTitle: string,
        colContent: string,
        setClass?: string
    ) => {
        return (
            <FlexRow className={cx('table-item-list-view', setClass)}>
                <FlexCol sm={4}>
                    <p>
                        <HtmlParser html={colTitle} />:
                    </p>
                </FlexCol>
                <FlexCol sm={8}>
                    <p>
                        <HtmlParser html={colContent} />
                    </p>
                </FlexCol>
            </FlexRow>
        );
    };

    return (
        <>
            {/* show the data as a table */}
            {width >= 768 && (
                <FlexRow className="cancellation-wrap">
                    <FlexCol sm={12}>
                        <table className="rec-table-light mb-4">
                            <caption className="rec-sr-only">Late Cancellations</caption>
                            <tbody>
                                <tr>
                                    <td className="first-col">
                                        <HtmlParser html={colA[0]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[0]} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="first-col">
                                        <HtmlParser html={colA[1]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[1]} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="first-col">
                                        <HtmlParser html={colA[2]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[2]} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="first-col">
                                        <HtmlParser html={colA[3]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[3]} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="first-col">
                                        <HtmlParser html={colA[4]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[4]} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </FlexCol>
                </FlexRow>
            )}

            {/* show the data as a list */}
            {width <= 769 && (
                <>
                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colB[0])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[1], colB[1])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[2], colB[2])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[3], colB[3])}
                    </div>

                    <div className="fees-info-wrap mb-4">
                        {inventoryListItems(colA[4], colB[4])}
                    </div>
                </>
            )}
        </>
    );
}
