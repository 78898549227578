/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import cx from 'classnames';
import { FlexRow, FlexCol, useWindowSize } from 'sarsaparilla';

export default function ServiceFees() {
    const { width = 0 } = useWindowSize();

    const serviceFeesTable = [
        {
            colA: 'Reservable Inventory',
            colB: 'Online website',
            colC: 'Contact Center',
            colD: 'In Person',
            colE: 'Scan & Pay',
        },
        {
            colA: 'Campsites, Day Use, Group Sites, Cabins, Lookouts, and Yurts',
            colB: '$8',
            colC: '$9',
            colD: '$3',
            colE: 'Variable – Check facility pages for additional information',
        },
        {
            colA: 'Tickets – Per Person',
            colB: '$1',
            colC: '$1',
            colD: 'Variable – Check facility pages for additional information',
            colE: 'Variable – Check facility pages for additional information',
        },
        {
            colA: 'Timed Entry Tickets',
            colB: '$2',
            colC: '$2',
            colD: 'Variable – Check facility pages for additional information',
            colE: 'Variable – Check facility pages for additional information',
        },
        {
            colA: 'Permits',
            colB: 'Variable – Check facility pages for additional information',
            colC: '',
            colD: '',
            colE: '',
        },
    ];

    const colA = serviceFeesTable.map((fee) => fee.colA);
    const colB = serviceFeesTable.map((fee) => fee.colB);
    const colC = serviceFeesTable.map((fee) => fee.colC);
    const colD = serviceFeesTable.map((fee) => fee.colD);
    const colE = serviceFeesTable.map((fee) => fee.colE);

    const inventoryListItems = (
        colTitle: string,
        colContent: string,
        setClass?: string
    ) => {
        return (
            <FlexRow className={cx('table-item-list-view', setClass)}>
                <FlexCol sm={4}>
                    <p>
                        <strong>{colTitle}:</strong>
                    </p>
                </FlexCol>
                <FlexCol sm={8}>
                    <p>{colContent}</p>
                </FlexCol>
            </FlexRow>
        );
    };

    return (
        <>
            {/* show the data as a table */}
            {width >= 768 && (
                <FlexRow>
                    <FlexCol sm={12}>
                        <table className="rec-table-light mb-4">
                            <caption className="rec-sr-only">Service Fees</caption>
                            <thead>
                                <tr>
                                    <th>{colA[0]}</th>
                                    <th>{colB[0]}</th>
                                    <th>{colC[0]}</th>
                                    <th>{colD[0]}</th>
                                    <th>{colE[0]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{colA[1]}</td>
                                    <td>{colB[1]}</td>
                                    <td>{colC[1]}</td>
                                    <td>{colD[1]}</td>
                                    <td>{colE[1]}</td>
                                </tr>
                                <tr>
                                    <td>{colA[2]}</td>
                                    <td>{colB[2]}</td>
                                    <td>{colC[2]}</td>
                                    <td>{colD[2]}</td>
                                    <td>{colE[2]}</td>
                                </tr>
                                <tr>
                                    <td>{colA[3]}</td>
                                    <td>{colB[3]}</td>
                                    <td>{colC[3]}</td>
                                    <td>{colD[3]}</td>
                                    <td>{colE[3]}</td>
                                </tr>
                                <tr>
                                    <td>{colA[4]}</td>
                                    <td colSpan={3}>{colB[4]}</td>
                                </tr>
                            </tbody>
                        </table>
                    </FlexCol>
                </FlexRow>
            )}

            {/* show the data as a list */}
            {width <= 769 && (
                <>
                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[1])}
                        {inventoryListItems(colB[0], colB[1])}
                        {inventoryListItems(colC[0], colC[1])}
                        {inventoryListItems(colD[0], colD[1])}
                        {inventoryListItems(colE[0], colE[1])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[2])}
                        {inventoryListItems(colB[0], colB[2])}
                        {inventoryListItems(colC[0], colC[2])}
                        {inventoryListItems(colD[0], colD[2])}
                        {inventoryListItems(colE[0], colE[2])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[3])}
                        {inventoryListItems(colB[0], colB[3])}
                        {inventoryListItems(colC[0], colC[3])}
                        {inventoryListItems(colD[0], colD[3])}
                        {inventoryListItems(colE[0], colE[3])}
                    </div>

                    <div className="fees-info-wrap mb-4">
                        {inventoryListItems(colA[0], colA[4])}
                        {inventoryListItems(colB[0], colB[4])}
                    </div>
                </>
            )}
        </>
    );
}
