/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { useState } from 'react';
import {
    FlexRow,
    FlexCol,
    Heading,
    Tabs,
    TabPanel,
    ButtonGroup,
    Button,
    ExternalLink,
} from 'sarsaparilla';

type YearItem = {
    title: string;
    date: string;
    url: string;
    type: string;
    className: string;
    source?: string;
    isInternalLink?: boolean;
};

const newsItems2022s: YearItem[] = [
    {
        title: 'Why Visitors Should Start Planning Adventure and Outdoor Vacations Now: National Plan for Vacation Day is January 25, 2022',
        date: 'Jan. 24, 2022',
        url: 'https://newsdirect.com/news/why-visitors-should-start-planning-adventure-and-outdoor-vacations-now-national-plan-for-vacation-day-is-january-25-2022-383391347',
        type: 'Press Release',
        className: 'press-release',
    },
    {
        title: 'Recreation.gov Announces 2022 "Share Your Story" Writing Contest for Outdoor Adventurists',
        date: 'Jan. 11, 2022',
        url: 'https://www.prnewswire.com/news-releases/recreationgov-announces-2022-share-your-story-writing-contest-for-outdoor-adventurists-301458072.html',
        type: 'Press Release',
        className: 'press-release',
    },
];

const newsItems2021s: YearItem[] = [
    {
        title: 'How to (Legally) Cut Down Your Own Christmas Tree in a National Forest',
        date: 'Dec. 13, 2021',
        url: 'https://lifehacker.com/how-to-legally-cut-down-your-own-christmas-tree-in-a-1848204151',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'You Can Now Cut Your Own Christmas Tree From a National Forest — and Help the Environment',
        date: 'Dec. 8, 2021',
        url: 'https://www.travelandleisure.com/holiday-travel/cut-your-own-christmas-tree-national-forest-permit',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'You Can Cut Your Own Christmas Tree From a National Forest for Just 5 Bucks',
        date: 'Dec. 6, 2021',
        url: 'https://www.fatherly.com/news/cut-christmas-tree-national-forest/',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'Can’t find a Christmas tree? Get One From a National Forest',
        date: 'Dec. 3, 2021',
        url: 'https://fortune.com/2021/12/03/christmas-tree-farm-national-forest/',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'How to safely and legally cut down your own Christmas tree',
        date: 'Nov. 19, 2021',
        url: 'https://www.insider.com/cut-your-own-christmas-tree',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'U.S. Forest Service Christmas Tree Permits Available through Recreation.gov for the 2021 Season',
        date: 'Oct. 07, 2021',
        url: 'https://cdn.recreation.gov/downloads/ChristmasTreePermits_NationalRelease.pdf',
        type: 'Press Release',
        className: 'press-release',
        isInternalLink: true,
    },
    {
        title: 'Share Your Story Contest Winners Announced',
        date: 'June 28, 2021',
        url: 'https://cdn.recreation.gov/downloads/SYS_Winners-Press-Release.pdf',
        type: 'Press Release',
        className: 'press-release',
        isInternalLink: true,
    },
    {
        title: 'Share Your Story Contest Launch',
        date: 'Jan. 20, 2021',
        url: 'https://cdn.recreation.gov/downloads/Share_Your_Story_Press_Release_1.20.pdf',
        type: 'Press Release',
        className: 'press-release',
        isInternalLink: true,
    },
];

const newsItems2020s: YearItem[] = [
    {
        title: 'How technology is helping to reshape fitness and outdoor recreation',
        date: 'Oct. 20, 2020',
        url: 'https://www.fastcompany.com/90566919/how-technology-is-helping-to-reshape-fitness-and-outdoor-recreation',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'USDA Forest Service Moves Christmas Tree Permits to Recreation.gov for the 2020 Season',
        date: 'Oct. 8, 2020',
        url: 'https://www.fs.usda.gov/news/releases/usda-forest-service-moves-christmas-tree-permits-recreationgov-2020-season',
        type: 'Press Release',
        className: 'press-release',
    },
    {
        title: 'The Partnership for Public Service publishes the annual Government for the People: Profiles on the Customer Experience report featuring Recreation.gov',
        date: 'Oct. 6, 2020',
        url: 'https://ourpublicservice.org/our-work/customer-experience/outdoor-recreation-reservations/',
        type: 'In the News',
        className: 'news',
    },
];

const newsItems: YearItem[] = [
    {
        title: 'The Partnership for Public Service publishes the annual Government for the People: Profiles on the Customer Experience report featuring Recreation.gov',
        date: 'Oct. 6, 2020',
        url: 'https://ourpublicservice.org/our-work/customer-experience/outdoor-recreation-reservations/',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'The Mountains are Calling, on iOS and Android',
        date: 'Oct. 12, 2019',
        url: 'https://www.mapbox.com/showcase/recreation-gov',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'Seeking Happy Campers, Government Offers Revamped Travel Portal',
        date: 'Oct. 10, 2019',
        url: 'https://www.wsj.com/articles/seeking-happy-campers-government-offers-revamped-travel-portal-11570742343',
        type: 'In the News',
        className: 'news',
    },
    {
        title: "Why It's So Hard To Get A Reserved Camping Site In Idaho – And What You Can Do About It",
        date: 'Sep. 09, 2019',
        url: 'https://www.boisestatepublicradio.org/post/why-its-so-hard-get-reserved-camping-site-idaho-and-what-you-can-do-about-it',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'BATTLE OF THE BULGE U.S. Recreation Site Can Help Ease Over-Tourism',
        date: 'Aug. 29, 2019',
        url: 'https://travelindustrytoday.com/2019-08-29-battle-of-the-bulge::31666',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'Recreation.gov Provides Digital Wilderness Permits for Olympic National Park',
        date: 'May 31, 2019',
        url: 'https://cdn.recreation.gov/downloads/Recgov_Olympic_Case_Study_V12_FINAL.pdf',
        type: 'Case Study',
        className: 'case-study',
        isInternalLink: true,
    },
    {
        title: "11 Reasons to Get Out and Explore America's Public Lands",
        date: 'May 30, 2019',
        url: 'https://www.nationalparks.org/about/pressroom/press-releases/11-reasons-get-out-and-explore-americas-public-lands',
        source: 'National Park Foundation',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'Love Your Users – Federal Websites We Love: Recreation.gov',
        date: 'Mar. 25, 2019',
        url: 'https://www.excella.com/resource/love-your-users-federal-websites-we-love-recreation-gov',
        source: 'ExcellaCo',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'Transition to Recreation.Gov for Wilderness Backpacking Permit Reservations to Launch March 18',
        date: 'Mar. 01, 2019',
        url: 'https://www.nps.gov/olym/learn/news/transition-to-recreation-gov-for-wilderness-backpacking-permit-reservations-to-launch-march-18.htm',
        source: 'National Park Service',
        type: 'In the News',
        className: 'news',
    },
    {
        title: 'First Lady Melania Trump Announces 2019 White House Easter Egg Roll',
        date: 'Feb. 25, 2019',
        url: 'https://www.whitehouse.gov/briefings-statements/first-lady-melania-trump-announces-2019-white-house-easter-egg-roll/',
        source: 'White House',
        type: 'In the News',
        className: 'news',
    },
];

export default function MediaCenterNews() {
    const pageSize = 4;
    const news2019Length = newsItems.length;
    const news2020Length = newsItems2020s.length;
    const news2021Length = newsItems2021s.length;
    const news2022Length = newsItems2022s.length;

    const [visibleNewsItems, setVisibleNewsItems] = useState(pageSize);
    const showMoreNewsItems = () => setVisibleNewsItems(visibleNewsItems + pageSize);

    const checkExternalLink = (arrayName: YearItem) => {
        return arrayName.isInternalLink ? (
            <Button
                appearance="link"
                className="nav-media-center-news-item-title"
                href={arrayName.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                {arrayName.title}
            </Button>
        ) : (
            <ExternalLink
                className="nav-media-center-news-item-title"
                url={arrayName.url}
            >
                {arrayName.title}
            </ExternalLink>
        );
    };

    return (
        <section className="nav-media-center-news px-3 px-md-5 mb-5">
            <FlexRow justifyContent="center">
                <FlexCol xl={10} lg={12}>
                    <FlexRow>
                        <FlexCol lg={5}>
                            <Heading
                                headingLevel={2}
                                appearance="h4"
                                className="mt-3 mb-2"
                            >
                                Recreation.gov News
                            </Heading>
                            <p>
                                Read the latest news and media stories about
                                Recreation.gov.
                            </p>
                        </FlexCol>
                        <FlexCol lg={7}>
                            <Tabs id="news-items" stretchTabsToFit={false}>
                                <TabPanel label="2022">
                                    <ul className="nav-media-center-news-list">
                                        {newsItems2022s
                                            .slice(0, visibleNewsItems)
                                            .map((newsItems2022) => (
                                                <li
                                                    className="nav-media-center-news-item"
                                                    key={newsItems2022.title}
                                                >
                                                    {checkExternalLink(newsItems2022)}
                                                    <ul className="nav-media-center-news-item-metadata mt-1">
                                                        <li>{newsItems2022.date}</li>
                                                        {newsItems2022.source && (
                                                            <li>
                                                                {newsItems2022.source}
                                                            </li>
                                                        )}
                                                        <li>
                                                            <span
                                                                className={`nav-media-center-news-item-type ${newsItems2022.className}`}
                                                            >
                                                                {newsItems2022.type}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </li>
                                            ))}
                                    </ul>
                                    {news2022Length >= 5 && (
                                        <ButtonGroup align="right" className="mt-3">
                                            <Button
                                                appearance="tertiary"
                                                onClick={showMoreNewsItems}
                                                aria-label="Load more media stories"
                                                isDisabled={
                                                    visibleNewsItems >= newsItems.length
                                                }
                                            >
                                                Load More
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </TabPanel>

                                <TabPanel label="2021">
                                    <ul className="nav-media-center-news-list">
                                        {newsItems2021s
                                            .slice(0, visibleNewsItems)
                                            .map((newsItems2021) => (
                                                <li
                                                    className="nav-media-center-news-item"
                                                    key={newsItems2021.title}
                                                >
                                                    {checkExternalLink(newsItems2021)}
                                                    <ul className="nav-media-center-news-item-metadata mt-1">
                                                        <li>{newsItems2021.date}</li>
                                                        {newsItems2021.source && (
                                                            <li>
                                                                {newsItems2021.source}
                                                            </li>
                                                        )}
                                                        <li>
                                                            <span
                                                                className={`nav-media-center-news-item-type ${newsItems2021.className}`}
                                                            >
                                                                {newsItems2021.type}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </li>
                                            ))}
                                    </ul>
                                    {news2021Length >= 5 && (
                                        <ButtonGroup align="right" className="mt-3">
                                            <Button
                                                appearance="tertiary"
                                                onClick={showMoreNewsItems}
                                                isDisabled={
                                                    visibleNewsItems >= newsItems.length
                                                }
                                            >
                                                Load More
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </TabPanel>

                                <TabPanel label="2020">
                                    <ul className="nav-media-center-news-list">
                                        {newsItems2020s
                                            .slice(0, visibleNewsItems)
                                            .map((newsItems2020) => (
                                                <li
                                                    className="nav-media-center-news-item"
                                                    key={newsItems2020.title}
                                                >
                                                    {checkExternalLink(newsItems2020)}
                                                    <ul className="nav-media-center-news-item-metadata mt-1">
                                                        <li>{newsItems2020.date}</li>
                                                        {newsItems2020.source && (
                                                            <li>
                                                                {newsItems2020.source}
                                                            </li>
                                                        )}
                                                        <li>
                                                            <span
                                                                className={`nav-media-center-news-item-type ${newsItems2020.className}`}
                                                            >
                                                                {newsItems2020.type}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </li>
                                            ))}
                                    </ul>
                                    {news2020Length >= 5 && (
                                        <ButtonGroup align="right" className="mt-3">
                                            <Button
                                                appearance="tertiary"
                                                onClick={showMoreNewsItems}
                                                isDisabled={
                                                    visibleNewsItems >= newsItems.length
                                                }
                                            >
                                                Load More
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </TabPanel>

                                <TabPanel label="2019">
                                    <ul className="nav-media-center-news-list">
                                        {newsItems
                                            .slice(0, visibleNewsItems)
                                            .map((newsItem) => (
                                                <li
                                                    className="nav-media-center-news-item"
                                                    key={newsItem.title}
                                                >
                                                    {checkExternalLink(newsItem)}
                                                    <ul className="nav-media-center-news-item-metadata mt-1">
                                                        <li>{newsItem.date}</li>
                                                        {newsItem.source && (
                                                            <li>{newsItem.source}</li>
                                                        )}
                                                        <li>
                                                            <span
                                                                className={`nav-media-center-news-item-type ${newsItem.className}`}
                                                            >
                                                                {newsItem.type}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </li>
                                            ))}
                                    </ul>
                                    {news2019Length >= 5 && (
                                        <ButtonGroup align="right" className="mt-3">
                                            <Button
                                                appearance="tertiary"
                                                onClick={showMoreNewsItems}
                                                isDisabled={
                                                    visibleNewsItems >= newsItems.length
                                                }
                                            >
                                                {visibleNewsItems >= newsItems.length
                                                    ? 'No More Articles to Load'
                                                    : 'Load More'}
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </TabPanel>
                            </Tabs>
                        </FlexCol>
                    </FlexRow>
                </FlexCol>
            </FlexRow>
        </section>
    );
}

// cSpell:ignore Excella Melania
