/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ArticleQuote.jsx
 * Created by Destin Frasier on 03/29/18.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DisplayHtmlContent } from 'sarsaparilla';

export default class ArticleQuote extends React.PureComponent {
    static propTypes = {
        quoteText: PropTypes.string,
        quoteAuthor: PropTypes.string,
    };

    static defaultProps = {
        quoteText: '',
        quoteAuthor: '',
    };

    render() {
        if (this.props.quoteText === '') {
            return null;
        }

        return (
            <div className="nav-article-body-quote-wrap">
                <div className="nav-article-body-quote-text">
                    <div className="nav-article-body-quote">
                        <DisplayHtmlContent html={this.props.quoteText} />
                    </div>
                    <div className="nav-article-body-quote-author">
                        <DisplayHtmlContent html={this.props.quoteAuthor} />
                    </div>
                </div>
            </div>
        );
    }
}
