/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './mediaCenter.scss';

import MediaCenterHero from './MediaCenterHero';
import MediaCenterNews from './MediaCenterNews';
import MediaCenterPhotosVideo from './MediaCenterPhotosVideo';
import MediaCenterPhotoDownloads from './MediaCenterPhotoDownloads';
import MediaCenterNewsletterArchive from './MediaCenterNewsletterArchive';
import LogoGuidelinesAndCampaignAssets from '../NewImplementationsPage/LogoGuidelinesAndCampaignAssets';

export function MediaCenterPage() {
    return (
        <div className="nav-media-center-page" role="main">
            <MediaCenterHero />
            <MediaCenterNews />
            <LogoGuidelinesAndCampaignAssets />
            <MediaCenterPhotosVideo />
            <MediaCenterPhotoDownloads />
            <MediaCenterNewsletterArchive />
        </div>
    );
}
