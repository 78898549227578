/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

// patterns of urls to hide footer component on
const hideFooterOnPaths = [
    /^\/search.*/,
    /^\/trips.*/,
    /^\/campgrounds\/(.+)\/campsites/,
    /^\/camping\/campgrounds\/(.+)\/campsites/,
    /^\/camping\/campgrounds\/(.+)\/availability/,
    /^\/camping\/campgrounds\/(.+)\/itinerary/,
    /^\/ticket\/(.+)\/tours/,
    /\/availability\//,
    /^\/vehiclepermits\/(.+)\/availability/,
];

// patterns of urls to included footer component on (these will override
// anything matching in hideFooterOnPaths)
const showFooterOnPaths = [/^\/trips\/new/];

export function getFooterVisibilityByLocation(pathname: string) {
    // cache of previous evaluations
    const showFooterOnCache: Record<string, any> = {};
    let result = true;

    if (pathname) {
        // check cached result first
        result = showFooterOnCache[pathname];

        if (!result) {
            result = true;
            let found = false;
            // check if pathname is listed in showFooterOnPaths list
            for (let i = 0, len = showFooterOnPaths.length; i < len; i += 1) {
                if (showFooterOnPaths[i].exec(pathname) !== null) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                // if pathname is not listed on showFooterOnPaths, then check whether it's included
                // into hideFooterOnPaths
                for (let i = 0, len = hideFooterOnPaths.length; i < len; i += 1) {
                    if (hideFooterOnPaths[i].exec(pathname) !== null) {
                        result = false;
                        break;
                    }
                }
            }
            showFooterOnCache[pathname] = result;
        }
    }
    return result;
}

// cSpell:ignore vehiclepermits
