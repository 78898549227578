/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { FlexRow, FlexCol, Heading } from 'sarsaparilla';
import ValueQuotesSlider from '../ValueQuotesSlider';

const quotes = [
    {
        quote: '<b>We needed a system that could handle reservations online 24 hours a day and offer a call center for those needing to speak to a person</b>. We just did not have staff bandwidth to manage Picnic Place with a manual process for these reservations.',
        author: 'Aricia M., Volunteer Manager, Presidio Trust',
    },
    {
        quote: `<b>Transitioning the Keys Ranch tour to ${process.env['SITE_NAME']} has been one of the best decisions we have made...</b> The amount of staff hours that we are saving and the improved customer service and convenience are a true testament to the many benefits of ${process.env['SITE_NAME']}.`,
        author: 'David L., Park Ranger, Joshua Tree National Park',
    },
    {
        quote: `<b>${process.env['SITE_NAME']} provides a huge win for the public</b>. With the new digital platform, individuals now know immediately whether they can complete their trip or not.`,
        author: 'Brooke L., Wilderness Information Center Supervisor, Olympic National Park',
    },
];

export default function ValueForAgencyFieldStaff() {
    return (
        <FlexRow className="values-agency-field-staff-wrapper">
            <FlexCol className="values-agency-field-staff-section">
                <FlexRow>
                    <FlexCol
                        lg={10}
                        lgOffset={1}
                        xl={8}
                        xlOffset={2}
                        xxl={6}
                        xxlOffset={3}
                    >
                        <Heading headingLevel={2} appearance="h4">
                            Value for Agency Field Staff
                        </Heading>

                        <p className="mt-1 mb-0">
                            For public land and water managers, {process.env['SITE_NAME']}{' '}
                            is a secure platform to manage operations, communicate with
                            visitors, and understand visitor trends and sentiment.
                        </p>
                    </FlexCol>
                </FlexRow>
            </FlexCol>
            <FlexCol className="values-agency-field-staff-section">
                <FlexRow>
                    <FlexCol
                        lg={10}
                        lgOffset={1}
                        xl={8}
                        xlOffset={2}
                        xxl={6}
                        xxlOffset={3}
                    >
                        <ValueQuotesSlider quotes={quotes} />
                    </FlexCol>
                </FlexRow>
            </FlexCol>
        </FlexRow>
    );
}
