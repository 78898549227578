/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import './RulesReservationPoliciesPage.scss';

import {
    HelmetWrapper,
    Heading,
    TextBlock,
    BackToTop,
    SingleColumnContainer,
} from 'sarsaparilla';

import RulesReservationNotification from './sub-components/RulesReservationNotification';
import { TableOfContents } from './sub-components/TableOfContents';

// Page Sections
import FeesSection from './sub-components/sections/FeesSection';
import PoliciesSection from './sub-components/sections/PoliciesSection';
import PassSection from './sub-components/sections/PassSection';
import TicketsToursSection from './sub-components/sections/TicketsToursSection';
import PermitReservationSection from './sub-components/sections/PermitReservationSection';
import ChristmasTreeSection from './sub-components/sections/ChristmasTreeSection';
import SiteActivityPassSection from './sub-components/sections/SiteActivityPassSection';
import VenuesSection from './sub-components/sections/VenuesSection';
import RefundsSection from './sub-components/sections/RefundsSection';
import RefundableSection from './sub-components/sections/RefundableSection';
import BillingSection from './sub-components/sections/BillingSection';
import ChangesSection from './sub-components/sections/ChangesSection';

export function RulesReservationPoliciesPage() {
    return (
        <SingleColumnContainer
            role="main"
            className="site-kit-rules-reservation-policies-page"
        >
            <HelmetWrapper title="Rules &amp; Reservation Policies" />

            <Heading headingLevel={1} appearance="h1">
                Rules &amp; Reservation Policies
            </Heading>
            <RulesReservationNotification />

            <TableOfContents />

            <TextBlock width="xl">
                <p className="mb-4">
                    This page explains the rules and policies that apply to most
                    locations. Keep in mind there are exceptions, so it is best to review
                    reservation policies on individual facility pages pertinent to your
                    trip. Any policies listed on specific locations&apos; pages supersede
                    the Recreation.gov site-wide policies.
                </p>
            </TextBlock>

            <FeesSection feesAnchor={'feesAnchor'} />

            <PoliciesSection
                policiesAnchor={'policiesAnchor'}
                campingAnchor={'campingAnchor'}
                cancelAnchor={'cancelAnchor'}
                noShowAnchor={'noShowAnchor'}
                modifyAnchor={'modifyAnchor'}
                departuresAnchor={'departuresAnchor'}
                dueNowAnchor={'dueNowAnchor'}
                downPaymentAnchor={'downPaymentAnchor'}
                securityDepositAnchor={'securityDepositAnchor'}
            />

            <TicketsToursSection
                anchor={'ticketsSectionAnchor'}
                ticketsAnchor={'ticketsAnchor'}
                ticketsModifyAnchor={'ticketsModifyAnchor'}
                ticketsCancellationAnchor={'ticketsCancellationAnchor'}
                ticketsLateCancellationAnchor={'ticketsLateCancellationAnchor'}
            />

            <PermitReservationSection
                anchor="permitReservationSection"
                permitsAnchor={'permitsAnchor'}
            />

            <ChristmasTreeSection christmasTreeAnchor={'christmasTreeAnchor'} />

            <SiteActivityPassSection activityPassAnchor={'activityPassAnchor'} />

            <VenuesSection venuesAnchor={'venuesAnchor'} />

            <PassSection
                interagencyPassesAnchor={'interagencyPassesAnchor'}
                passesDiscountAnchor={'passesDiscountAnchor'}
                passRefundsAnchor={'passRefundsAnchor'}
            />

            <RefundsSection
                refundsAnchor={'refundsAnchor'}
                submittingRefundAnchor={'submittingRefundAnchor'}
                refundsRequestAnchor={'refundsRequestAnchor'}
                refundsDeliveredAnchor={'refundsDeliveredAnchor'}
            />

            <RefundableSection
                notRefundableAnchor={'notRefundableAnchor'}
                refundableAnchor={'refundableAnchor'}
                nonRefundableAnchor={'nonRefundableAnchor'}
            />

            <BillingSection billingAnchor={'billingAnchor'} />

            <ChangesSection changeAnchor={'changeAnchor'} />

            <BackToTop />

            <div className="mb-8" />
        </SingleColumnContainer>
    );
}
