/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DividerPromo, ButtonGroup, Button, useWindowSize } from 'sarsaparilla';

export default function UGCPromoContainer(props) {
    const targetRef = useRef();
    const { width } = useWindowSize();

    const href = '/shareyourstory';

    const dynamicGrid = width >= 1350 ? 7 : width >= 1200 ? 8 : 12;

    let promoImageBackground;
    let promoClassName;
    let promoOverlayImagePath;
    let screenReaderTextMobile;

    if (width <= 1200) {
        promoImageBackground = 'ugc-promo-banner-bg-small-2023';
        promoOverlayImagePath = 'share_your_story_horz_2023';
        promoClassName = 'homepage-ugc-promo-pre-stacked';
        screenReaderTextMobile = 'share your story';
    } else {
        promoImageBackground = 'ugc-promo-banner-bg-2023';
        promoOverlayImagePath = 'share_your_story_large_2023';
        promoClassName = 'homepage-ugc-promo';
    }

    return (
        <DividerPromo
            heading="2023 Outdoor Adventure Writing Contest"
            backgroundImagePath={`${process.env.CDN}/img/homepage/divider-promo-section/${promoImageBackground}.jpg`}
            copy={`Whether it's a first-time experience or an adventure you've enjoyed over the years, Share Your Story. We are accepting story submissions June 1, 2023 – September 30, 2023. Enter now for a chance to win great prizes and have your story featured on ${props.articlesDomain}!`}
            height="md"
            contentContainerRight
            contentCopyAlignRight
            showOverlayTopOnMobile
            contentCopyAlignCenterOnMobile
            overlayImagePath={`${process.env.CDN}/img/homepage/divider-promo-section/${promoOverlayImagePath}`}
            overlayImageAlt="2023 Share Your Story Contest"
            className={promoClassName}
            refForIntersection={targetRef}
            contentContainerGridSize={dynamicGrid}
            promoHeadingLevel={3}
            screenReaderText={screenReaderTextMobile}
        >
            <ButtonGroup isFullWidthOnMobile={false}>
                <Button
                    appearance="tertiary-white"
                    href={href}
                    gaReportName={'UGC Promo Container'}
                    gaReportCreative={'ugc promo'}
                    gaReportPosition={'HomePage'}
                    shouldReportToGA
                >
                    Share Your Story!
                </Button>
            </ButtonGroup>
        </DividerPromo>
    );
}

UGCPromoContainer.propTypes = {
    articlesDomain: PropTypes.string,
};

UGCPromoContainer.defaultProps = {
    // eslint-disable-next-line
    articlesDomain: 'Recreation.gov',
};

// cSpell:ignore horz, shareyourstory
