/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Heading } from 'sarsaparilla';

const talkBackActions = [
    {
        action: 'Navigation - Move to next item',
        command: 'Alt + Shift + Right arrow',
    },
    {
        action: 'Navigation - Move to previous item',
        command: 'Alt + Shift + Left arrow',
    },
    {
        action: 'Navigation - Move to first item',
        command: 'Alt + Shift + Up arrow',
    },
    {
        action: 'Navigation - Move to last item',
        command: 'Alt + Shift + Down arrow',
    },
    {
        action: 'Navigation - Click focused element',
        command: 'Alt + Shift + Enter',
    },
    {
        action: 'Global - Back',
        command: 'Alt + Shift + Backspace',
    },
    {
        action: 'Global - Home',
        command: 'Alt + Shift + h',
    },
    {
        action: 'Global - Recent apps/Overview',
        command: 'Alt + Shift + r',
    },
    {
        action: 'Global - Notifications',
        command: 'Alt + Shift + n',
    },
    {
        action: 'Global - Search the screen',
        command: 'Alt + Shift + / (forward slash)',
    },
    {
        action: 'Global - Next navigation setting',
        command: 'Alt + Shift + Equals (=)',
    },
    {
        action: 'Global - Previous navigation setting',
        command: 'Alt + Shift + Minus (-)',
    },
    {
        action: 'Global - Open global context menu',
        command: 'Alt + Shift + g',
    },
    {
        action: 'Global - Open local context menu',
        command: 'Alt + Shift + L',
    },
    {
        action: 'Global - Stop TalkBack speech',
        command: 'Control',
    },
    {
        action: 'Global - Pause or resume TalkBack',
        command: 'Alt + Shift + z',
    },
];

const tecName = 'Talk Back';

export default function TalkBackActionsSection() {
    return (
        <section>
            <Heading
                headingLevel={3}
                appearance="h4"
                className="accessibility-table-heading mb-2"
            >
                {tecName} Actions and Commands for Android 4.1 or higher
            </Heading>
            <table
                className="table-no-border mb-4"
                aria-label={`${tecName} Actions and Commands Table`}
            >
                <thead>
                    <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Commands</th>
                    </tr>
                </thead>
                <tbody>
                    {talkBackActions.map((talkBackAction) => (
                        <tr key={talkBackAction.action}>
                            <td width="70%">{talkBackAction.action}</td>
                            <td width="30%">{talkBackAction.command}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
}
