/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import * as React from 'react';
import { FlexRow, FlexCol, Heading, Icon, ImageWithFallback } from 'sarsaparilla';

import overviewImageLandingWebp from '../images/overview-image-landing.webp';
import overviewImageLandingPng from '../images/overview-image-landing.png';
import overviewImageTicketsWebp from '../images/overview-image-tickets.webp';
import overviewImageTicketsPng from '../images/overview-image-tickets.png';
import overviewImageLotteriesWebp from '../images/overview-image-lotteries.webp';
import overviewImageLotteriesPng from '../images/overview-image-lotteries.png';

import { MobileAppOverviewTab } from './MobileAppOverviewTab';

const overviewItems = [
    {
        title: 'Search Thousands of Campgrounds',
        description:
            "Book your favorite spot or choose your search criteria and filter results based on what's important to you to find the perfect campsite for your next trip.",
        icon: <Icon iconName="tent" />,
        image: overviewImageLandingWebp,
        fallbackImage: overviewImageLandingPng,
        imageAlt: 'mobile app search screen',
    },
    {
        title: 'Find Tickets & Tours for Amazing Activities',
        description:
            'From ranger-led experiences to historical reenactments to cave exploration, reserve tours and tickets to participate in special events and experiences across the country.',
        icon: <Icon iconName="pin-drop" />,
        image: overviewImageTicketsWebp,
        fallbackImage: overviewImageTicketsPng,
        imageAlt: 'mobile app search results',
    },
    {
        title: 'Enter Lotteries for High-Demand Experiences',
        description:
            'Find your ideal spot by checking out interactive maps and detailed campground and individual campsite photos.',
        icon: <Icon iconName="photo" />,
        image: overviewImageLotteriesWebp,
        fallbackImage: overviewImageLotteriesPng,
        imageAlt: 'mobile app map screen',
    },
];

export function MobileAppOverview() {
    const [activeTab, setActiveTab] = React.useState<string>(overviewItems[0].title);

    function onClickTabItem(tab: string) {
        setActiveTab(tab);
    }

    const activeItem =
        overviewItems.find((item) => item.title === activeTab) || overviewItems[0];

    return (
        <section className="nav-mobile-app-overview">
            <div className="nav-mobile-app-overview-content">
                <FlexRow justifyContent="center">
                    <FlexCol xl={10} lg={12}>
                        <FlexRow>
                            <FlexCol lgOrder={2} lg={7}>
                                <div className="nav-mobile-app-overview-header">
                                    <Heading
                                        headingLevel={2}
                                        appearance="h2"
                                        className="nav-mobile-app-overview-title"
                                    >
                                        Discover Incredible Destinations
                                    </Heading>
                                    <p>
                                        The Recreation.gov app is your on-the-go solution
                                        for making reservations or purchasing passes for
                                        incredible destinations all over the United
                                        States. Return to your favorite spot or find a new
                                        one by searching for the location that&#39;s right
                                        for you.
                                    </p>
                                </div>
                                <div className="nav-mobile-app-overview-tabs">
                                    {overviewItems.map((item) => {
                                        return (
                                            <MobileAppOverviewTab
                                                key={item.title}
                                                activeTab={activeTab}
                                                title={item.title}
                                                onClick={onClickTabItem}
                                                description={item.description}
                                                icon={item.icon}
                                            />
                                        );
                                    })}
                                </div>
                            </FlexCol>
                            <FlexCol lgOrder={1} lg={5}>
                                <div className="nav-mobile-app-overview-panel">
                                    <div className="nav-mobile-app-overview-panel-media">
                                        <div className="nav-mobile-app-overview-panel-media-item">
                                            <ImageWithFallback
                                                src={activeItem.image}
                                                fallbackSrc={activeItem.fallbackImage}
                                                alt={activeItem.imageAlt}
                                            />
                                        </div>
                                    </div>
                                    <div className="nav-mobile-app-overview-panel-content">
                                        <div className="nav-mobile-app-overview-panel-title">
                                            {activeItem.title}
                                        </div>
                                        <p>{activeItem.description}</p>
                                    </div>
                                </div>
                            </FlexCol>
                        </FlexRow>
                    </FlexCol>
                </FlexRow>
            </div>
        </section>
    );
}
