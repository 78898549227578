/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';

import {
    Box,
    Button,
    ContentBlock,
    FlexCol,
    FlexRow,
    Heading,
    Icon,
    Spacer,
    useBreakpoint,
} from 'sarsaparilla';

import { useFetchHomepageArticlesQuery } from '../../redux/articles-api-slice';

import ArticleItemList from '../../components/homepage/inspirationInfo/ArticleItemList';
import FeaturedArticle from '../../components/homepage/inspirationInfo/FeaturedArticle';
import QuickLinks from '../../components/homepage/inspirationInfo/QuickLinks';

import { makeArticles } from './InspirationAndInformation.utils';

// interface InspirationInfoProps {}

export function InspirationAndInformation() {
    const { data } = useFetchHomepageArticlesQuery();
    const { isMdAndUp } = useBreakpoint();

    const articleData = React.useMemo(() => {
        return makeArticles(data);
    }, [data]);

    if (!data || !articleData) {
        return null;
    }

    const { showQuickLinks } = process.env.HOMEPAGE_OPTIONS;
    const { featuredArticle, otherArticles } = articleData;

    return (
        <ContentBlock className="nav-articles">
            <section className="nav-inspiration-info-outer-wrap">
                <Heading headingLevel={2} appearance="h4" hasUnderline="left">
                    Inspiration & Information
                </Heading>

                <Spacer size="md" />

                <FlexRow
                    className="nav-inspiration-info-body"
                    key="nav-inspiration-info-body"
                >
                    <FlexCol>
                        <FlexRow>
                            <FlexCol md={8} className="nav-articles-featured-container">
                                <FeaturedArticle article={featuredArticle} />
                            </FlexCol>
                            <FlexCol md={4} className="rec-thumbs-container">
                                <div className="nav-articles-thumbs-wrap">
                                    <ArticleItemList articles={otherArticles} />
                                </div>

                                <Box textAlign="center">
                                    <Button
                                        appearance="tertiary"
                                        to="/articles"
                                        className="mt-3 mb-6 mb-lg-0"
                                        shouldFitContainer={isMdAndUp}
                                        iconAfterElement={
                                            <Icon iconName="arrow-forward" />
                                        }
                                        clickTagCategory="Homepage"
                                        clickTagAction="View All Articles Link"
                                        clickTagLabel="Inspiration & Information Section"
                                    >
                                        View All Articles
                                    </Button>
                                </Box>
                            </FlexCol>
                        </FlexRow>
                    </FlexCol>
                    {showQuickLinks ? (
                        <FlexCol md={12} lg={4} xl={3}>
                            <QuickLinks />
                        </FlexCol>
                    ) : null}
                </FlexRow>
            </section>
        </ContentBlock>
    );
}
