/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { Heading, TextBlock } from 'sarsaparilla';

interface BillingSectionProps {
    billingAnchor: string;
}

export default function BillingSection({ billingAnchor = '' }: BillingSectionProps) {
    return (
        <section className="mb-8" id={billingAnchor}>
            <Heading
                headingLevel={2}
                appearance="h1"
                className="rules-reservation-policies-section-heading"
            >
                Recreation.gov Billing Information
            </Heading>

            <TextBlock width="xl">
                <p>
                    Reservation transactions will appear on customer&quot;s credit card
                    statements as &quot;<strong>Recreation.gov 877-444-6777 NM</strong>
                    &quot;.
                </p>
            </TextBlock>
        </section>
    );
}
