/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import DiscoverResultsContainer from './DiscoverResultsContainer';

export default function DiscoverCampingLocation() {
    const { location } = useParams();
    const locationName = decodeURIComponent(location)
        .replace(/\+/g, ' ')
        .replace(/-/g, ' ');
    return (
        <DiscoverResultsContainer
            titlePrefix={`Discover `}
            location={locationName}
            state={null}
        />
    );
}
