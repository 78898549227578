/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { Heading, FlexRow, FlexCol, HtmlParser } from 'sarsaparilla';
import ServiceFees from '../feeTables/ServiceFees';
import InventoryTypeFees from '../feeTables/InventoryTypeFees';

interface FeesSectionProps {
    feesAnchor: string;
}

export default function FeesSection({ feesAnchor = '' }: FeesSectionProps) {
    const fees = [
        {
            title: 'Recreation Use Fee',
            text: 'Recreation Use fees are the fees a visitor pays to camp, experience a day-use activity, reserve group facilities, join a guided tour, or purchase a permit. Recreation Use fee prices vary by location and activity.',
            class: '',
        },
        {
            title: 'Reservation Service Fee',
            text: 'Visitors pay a fee in addition to the recreation or use fee to make a reservation and guarantee a spot for any of the activities mentioned above. Reservation fees are non-refundable.',
            class: 'mb-1',
        },
        {
            title: 'Change Fee',
            text: 'A fee that may apply if you modify your reservation.',
            class: '',
        },
        {
            title: 'Cancellation Fee',
            text: 'A fee that is withheld from a reservation cancellation refund. If the fees collected are less than the cancellation fees, no fees may be returned when a reservation is cancelled.',
            class: '',
        },
        {
            title: 'Cut-Off Window',
            text: 'The latest date and/or time that you can make an advanced reservation or a change to an existing reservation. Cut-off windows vary by location, but are typically between 0 to 4 days before your arrival date. More details below.',
            class: '',
        },
        {
            title: 'Late Cancellation Fees',
            text: 'If you cancel your reservation close to the start date, additional fees to the ones outlined above may apply. Late cancellation time periods vary by type of inventory such as reservations made at a standard campsite versus a group campsite or cabin.',
            class: '',
        },
        {
            title: 'No-Show',
            text: 'A <strong>no-show</strong> is when a customer with an advanced reservation does not arrive at the reserved location or fails to cancel a reservation by a specified date/time. No-show customers may not be recorded at unstaffed locations and all reservations at those locations are treated as checked-in.',
            class: '',
        },
        {
            title: 'Refund',
            text: 'A full or partial refund of recreation fees paid for through Recreation.gov.',
            class: 'mb-6',
        },
    ];

    return (
        <section className="mb-8" id={feesAnchor}>
            <Heading
                headingLevel={2}
                appearance="h1"
                className="rules-reservation-policies-section-heading mb-4"
            >
                Fees and Definitions
            </Heading>

            {fees.slice(0, 2).map((fee, index) => (
                <FlexRow key={index}>
                    <FlexCol sm={4} md={3}>
                        <p className="mb-1">
                            <strong>{fee.title}:</strong>
                        </p>
                    </FlexCol>
                    <FlexCol>
                        <p className={fee.class}>
                            <HtmlParser html={fee.text} />
                        </p>
                    </FlexCol>
                </FlexRow>
            ))}

            <ServiceFees />

            {fees.slice(2).map((fee, index) => (
                <FlexRow key={index}>
                    <FlexCol sm={4} md={3}>
                        <p className="mb-1">
                            <strong>{fee.title}:</strong>
                        </p>
                    </FlexCol>
                    <FlexCol>
                        <p className={fee.class}>
                            <HtmlParser html={fee.text} />
                        </p>
                    </FlexCol>
                </FlexRow>
            ))}

            <InventoryTypeFees />
        </section>
    );
}
