/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import omitBy from 'lodash/omitBy';
import uniq from 'lodash/uniq';
import { CalendarDate } from '@internationalized/date';
import {
    ALL_FILTERS,
    ALL_FILTER_KEYS,
    CAMPSITE_AMENITY_ELECTRICAL_HOOKUP,
    SEARCH_FILTER_ACTIVITIES,
} from '../constants';
import { searchDate } from './dateUtils';
import {
    parseFgParams as parseFgFromUiFilters,
    removeCampingFromUiFilters,
} from './filters';
import { FiltersTypeProps, UiFilterProps, UiFiltersProps } from '../types/filters';
import { ParsedAiSearchResponse, SearchResponse, SearchType } from '../types/search';

interface Filter {
    attribute: string;
    filterValue: string | string[];
}

interface FilterObject {
    key: string;
    value?: string;
    filters?: Filter[];
}

const coordinateKeys = [
    'lat',
    'long',
    'lng',
    'radius',
    'lat_sw',
    'lng_sw',
    'lat_ne',
    'lng_ne',
    'polygon',
] as const;

const paramKeys = [
    'entity_id',
    'entity_type',
    'exact',
    'l',
    'location',
    'start_date',
    'end_date',
    'flex_start_range',
    'flex_end_range',
    'avail_months',
    'avail_nights',
    'tour_times',
    'sort',
    'start',
    'size',
    'include_unavailable',
    'include_notreservable',
    'include_partially_available',
] as const;

type SearchParamKeys = (typeof paramKeys)[number] & keyof Partial<SearchResponse>;
type CoordinateParamKeys = (typeof coordinateKeys)[number] &
    keyof Partial<SearchResponse>;

const searchParamKeys: readonly SearchParamKeys[] = paramKeys;

const coordinateParamKeys: readonly CoordinateParamKeys[] = coordinateKeys;

const findReduxFilterObject = (
    params: UiFilterProps,
    searchParam: [string, string]
): FiltersTypeProps | null => {
    const correctFilter = params;
    const [label, data] = searchParam;
    let correct: FiltersTypeProps | null = null;

    for (const filter in correctFilter) {
        if (Object.hasOwn(correctFilter, filter)) {
            const current = correctFilter[filter];
            // @ts-expect-error
            if (current?.attribute === label && current?.filterValue === data) {
                correct = correctFilter[filter];
                break;
            }
            // @ts-expect-error
            if (current?.attribute === label && current?.filterValue.includes(data)) {
                correct = correctFilter[filter];
                break;
            }
        }
    }
    return correct;
};

const findReduxFilterName = (label: string) => {
    let correct = null;
    for (const value in ALL_FILTER_KEYS) {
        if (Object.hasOwn(ALL_FILTER_KEYS, value)) {
            // @ts-expect-error
            const current = ALL_FILTER_KEYS[value];
            if (current.indexOf(label) !== -1) {
                correct = value;
                return value;
            }
        }
    }
    return correct;
};

const parseRegularFqParams = (value: string) => {
    const parsedParams: Partial<UiFilterProps> = {};
    let name: string = '';
    const searchParam: string[] = value.split(':');
    const clearLabel: string = searchParam[0];

    for (const filter in ALL_FILTERS) {
        if (Object.hasOwn(ALL_FILTERS, filter)) {
            const current: FilterObject = ALL_FILTERS[filter];
            if (current.filters) {
                // @ts-expect-error
                const filterValue = findReduxFilterObject(current.filters, [
                    clearLabel,
                    searchParam[1],
                ]);
                const filterObject = findReduxFilterName(clearLabel);
                if (filterValue) {
                    // @ts-expect-error
                    name = filterObject;
                    // @ts-expect-error
                    parsedParams[filterObject] = {
                        // @ts-expect-error
                        ...parsedParams[filterObject],
                        [current.key]: current,
                    };
                    break;
                }
            }
        }
    }
    return [name, parsedParams];
};

const parseAssetActivities = (value: string): [string, FilterObject] => {
    const [key, data] = value.split(':');
    let label: string = '';
    const filterObject: FilterObject = {
        key: '',
        value: '',
        filters: [
            {
                attribute: key,
                filterValue: '',
            },
        ],
    };

    for (const activity in SEARCH_FILTER_ACTIVITIES) {
        if (Object.hasOwn(SEARCH_FILTER_ACTIVITIES, activity)) {
            const currentActivity: FilterObject = SEARCH_FILTER_ACTIVITIES[activity];
            if (currentActivity.value?.toLowerCase() === data?.toLowerCase()) {
                label = currentActivity.key;
                filterObject.key = label;
                filterObject.value = currentActivity.value;
                // @ts-expect-error
                filterObject.filters[0].filterValue = data;
                break;
            }
        }
    }
    return [label, filterObject];
};

const parseFqAmountParams = (value: string, sign: string) => {
    const [key, data] = value.split(sign);
    const name = findReduxFilterName(key);
    const filterObject = {
        key: name,
        value: `${sign}${data}`,
        filters: [
            {
                attribute: key,
                filterValue: `${sign}${data}`,
            },
        ],
    };
    return [name, filterObject];
};

const parseTourTimes = (times: any) => {
    let filters = {};
    for (const time in times) {
        if (Object.hasOwn(times, time)) {
            const value = `tour_times:${times[time]}`;
            const filter = parseRegularFqParams(value);
            // @ts-expect-error
            if (filter[1]?.tourTimes) {
                // @ts-expect-error
                filters = { ...filters, ...filter[1]?.tourTimes };
            }
        }
    }
    return filters;
};

const parseFqParams = (params: string[]) => {
    let parsedParams = { activities: {} };
    for (const key in params) {
        if (Object.hasOwn(params, key)) {
            const value = params[key];
            if (value.includes('>=')) {
                const [label, newFilters] = parseFqAmountParams(value, '>=');
                // @ts-expect-error
                parsedParams[label] = {
                    // @ts-expect-error
                    ...parsedParams[label],
                    // @ts-expect-error
                    [label]: newFilters,
                };
                continue;
            }
            if (value.includes('<=')) {
                const [label, newFilters] = parseFqAmountParams(value, '<=');
                if (label) {
                    // @ts-expect-error
                    parsedParams[label] = {
                        // @ts-expect-error
                        ...parsedParams[label],
                        // @ts-expect-error
                        [label]: newFilters,
                    };
                }
                continue;
            }
            if (value.includes('asset_activities')) {
                const [label, newActivityParam] = parseAssetActivities(value);
                if (label) {
                    parsedParams.activities = {
                        ...parsedParams.activities,
                        [label]: newActivityParam,
                    };
                }
                continue;
            } else {
                const [name, newFilters] = parseRegularFqParams(value);
                parsedParams = {
                    ...parsedParams,
                    // @ts-expect-error
                    [name]: { ...parsedParams[name], ...newFilters[name] },
                };
            }
        }
    }
    return parsedParams;
};

const parseFgFromAiParsed = (params?: ParsedAiSearchResponse) => {
    if (!params) {
        return [];
    }
    const values = [];
    if (params.electric_amp) {
        values.push(`${params.electric_amp}-amp`);
    }
    if (params.groupsize) {
        values.push(`group-size:${params.groupsize}`);
    }
    if (params.electric_hookup) {
        values.push('electricity-hookup');
    }
    if (params.sewer_hookup) {
        values.push('sewer-hookup');
    }
    if (params.water_hookup) {
        values.push('water-hookup');
        values.push('showers');
    }
    if (params.waterfront) {
        values.push('waterfront');
    }
    if (params.length) {
        values.push(`vehicle-length:${params.length}`);
    }
    if (params.sitetypes) {
        params.sitetypes.forEach((item) => {
            const value = item.toLowerCase();
            if (value.includes('tent')) {
                values.push('tent', 'camping');
            }
            if (value.includes('rv') || value.includes('trailer')) {
                values.push('rmt', 'camping');
            }
            if (
                value.includes('lookout') ||
                value.includes('yurt') ||
                value.includes('cabin')
            ) {
                values.push('lodging');
            }
            if (value.includes('horse') && value.includes('camping')) {
                values.push('equestrian', 'camping');
            }
        });
    }
    return uniq(values);
};

const validateDates = (
    start: string | Date | CalendarDate,
    end: string | Date | CalendarDate
): { start: string; end: string } => {
    const startDate = searchDate(start);
    const endDate = searchDate(end);
    const now = searchDate(new Date());

    if (startDate.compare(now) < 0 && endDate.compare(now) >= 0) {
        startDate.set(now);
    }

    if (startDate.compare(now) < 0 && endDate.compare(now) < 0) {
        const yearDiff = now.year - startDate.year + 1;
        startDate.add({ years: yearDiff });
        endDate.add({ years: yearDiff });
    }

    return {
        start: startDate.format('MM/DD/YYYY'),
        end: endDate.format('MM/DD/YYYY'),
    };
};

export const getParsedAiSearchCriterias = (
    criterias: SearchResponse,
    uiFilters: UiFiltersProps
) => {
    const v = omitBy(criterias, (value) => value === 0);
    const params: Partial<SearchType> = { hasFilters: true };
    params.sort = 'available';

    if (v.fq) {
        const newUiFilters = parseFqParams(uniq(v.fq));
        params.uiFilters = { ...uiFilters, ...newUiFilters };
        if (v?.parsed?.electric_hookup) {
            params.uiFilters['campsiteAmenities'] = {
                ...params?.uiFilters?.['campsiteAmenities'],
                att_electricity_hookup: CAMPSITE_AMENITY_ELECTRICAL_HOOKUP,
            };
        }

        const fgParamsFromUiFilters = parseFgFromUiFilters(newUiFilters, {});
        // We need to run this to ensure we don’t miss any parameters from fq.
        const fgParamsFromAiParsed = parseFgFromAiParsed(v?.parsed);

        params.fg = uniq([...fgParamsFromUiFilters, ...fgParamsFromAiParsed]);

        // Clear camping and inventory filters to avoid duplication with FG
        params.uiFilters = removeCampingFromUiFilters(params.uiFilters);
        params.inventory_type = [];
    }

    for (const key of searchParamKeys) {
        if (Object.hasOwn(v, key)) {
            if (key === 'start_date' && v.end_date) {
                params.checkin_time = v[key];
                continue;
            }
            if (key === 'end_date' && v.start_date) {
                params.checkout_time = v[key];
                continue;
            }
            if (key === 'location') {
                params.what = v[key];
                continue;
            }
            if (key === 'tour_times') {
                const tourTimes = parseTourTimes(v[key]);
                params.uiFilters = { ...params.uiFilters, tourTimes };
            } else {
                // @ts-ignore
                params[key] = v[key];
            }
        }
    }

    for (const key of coordinateParamKeys) {
        if (Object.hasOwn(v, key)) {
            if (v[key] === 0) {
                continue;
            } else {
                // @ts-ignore
                params[key] = v[key];
            }
        }
    }

    if (params.checkin_time && params.checkout_time) {
        const { start, end } = validateDates(params.checkin_time, params.checkout_time);
        params.checkin_time = start;
        params.checkout_time = end;

        params.include_unavailable = false;
        params.include_notreservable = false;
        params.include_partially_available = false;
    }

    return params;
};
