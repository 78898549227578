/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ArticleLandingBlockItem.jsx
 * Created by Destin Frasier on 03/21/18.
 * Updated by Destin Frasier on 07/20/24.
 */

import React from 'react';
import truncate from 'lodash/truncate';
import { FlexCol, Icon, HtmlParser, Heading, Button } from 'sarsaparilla';
import { ARTICLE } from '../../../constants/propTypes';
import { getContentTypeLabel, parseArticleImage } from '../../../utils/articlesHelper';
import Content from '../article/Content';

import { usePrefetch } from '../../../redux';

const propTypes = {
    article: ARTICLE,
};

function ArticleLandingBlockItem({ article }) {
    const prefetch = usePrefetch('fetchArticleById');

    function prefetchArticle(articleId) {
        if (articleId) {
            prefetch(articleId);
        }
    }

    return (
        <FlexCol xs={12} md={6} xl={4} className="mb-5">
            <article className="article-landing-article-block-wrap">
                <div className="article-landing-article-block-image">
                    <img
                        src={parseArticleImage(article)}
                        alt={article.hero_image_alt_text}
                    />
                </div>
                <div className="article-landing-article-block-overlay">
                    <div className="article-landing-article-block-overlay-content">
                        <div className="block-overlay-content-primary-text">
                            {getContentTypeLabel(article.content_type)}
                        </div>
                        <span className="read-more">
                            Read More <Icon iconName="arrow-forward" />
                        </span>
                    </div>
                </div>

                <div className="article-landing-article-block-content-wrap">
                    <Heading
                        headingLevel={2}
                        appearance="h5"
                        className="article-block-title"
                    >
                        <Button
                            to={article.url}
                            alt=""
                            isUnstyled
                            onMouseEnter={() => {
                                prefetchArticle(article?.id);
                            }}
                        >
                            <HtmlParser html={article.article_title} />
                        </Button>
                    </Heading>
                    <Content
                        content={truncate(article.article_overview, {
                            length: 260,
                        })}
                    />
                </div>
            </article>
        </FlexCol>
    );
}

ArticleLandingBlockItem.propTypes = propTypes;

export default ArticleLandingBlockItem;
