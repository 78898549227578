/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * AboutAuthor.jsx
 * Created by Destin Frasier on 03/27/18
 */
import React from 'react';
import PropTypes from 'prop-types';

class AboutAuthor extends React.PureComponent {
    static propTypes = {
        image: PropTypes.string,
        alt: PropTypes.string,
        bioText: PropTypes.string,
    };

    render() {
        return (
            <div className="nav-article-left-rail-item">
                <div className="nav-article-left-rail-item-title">About the Author</div>
                <p>
                    <span className="nav-article-about-author-img">
                        <img
                            src={this.props.image}
                            alt={this.props.alt}
                            key={this.props.alt}
                        />
                    </span>
                    {this.props.bioText}
                </p>
            </div>
        );
    }
}

export default AboutAuthor;
