/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * Rentals.jsx
 * Created by Destin Frasier on 10/07/20
 * Created by Destin Frasier on 03/24/23
 */

import React from 'react';
import { HelmetWrapper, Box } from 'sarsaparilla';

import RvRentals from '../../components/tertiary/rentals/RvRentals';
import RentalsAlert from '../../components/tertiary/rentals/RentalsAlert';

export default function Rentals() {
    const pageTitle = 'RV Rentals';

    return (
        <div className="nav-gear-rental-page" role="main">
            <HelmetWrapper title={pageTitle} />

            <Box background="gray-2" paddingY="xxl" id="rv-rentals">
                <RvRentals />
            </Box>

            <RentalsAlert />
        </div>
    );
}
