/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';

interface MobileAppFeatureItemProps {
    icon: React.ReactNode;
    secondaryIcon?: React.ReactNode;
    secondaryIconClassName?: string;
    title: string;
    children: React.ReactNode;
}

export function MobileAppFeatureItem({
    icon,
    secondaryIcon,
    secondaryIconClassName = '',
    title,
    children,
}: MobileAppFeatureItemProps) {
    return (
        <div className="nav-mobile-app-feature-item">
            <div className="nav-mobile-app-feature-item-icon">
                {icon}
                {secondaryIcon ? (
                    <span className={secondaryIconClassName}>{secondaryIcon}</span>
                ) : null}
            </div>
            <div className="nav-mobile-app-feature-item-content">
                <div className="nav-mobile-app-feature-item-title">{title}</div>
                {children}
            </div>
        </div>
    );
}
