/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { Button, FeatureFlag } from 'sarsaparilla';

export function About() {
    const gaCategory = 'Footer';
    const gaLabel = 'Get to Know Us List';

    return (
        <ul className="nav-footer-list">
            <li>
                <Button
                    appearance="link"
                    href="/about-us"
                    clickTagCategory={gaCategory}
                    clickTagAction="About Us link"
                    clickTagLabel={gaLabel}
                >
                    About Recreation.gov
                </Button>
            </li>
            <li>
                <Button
                    appearance="link"
                    href="/rules-reservation-policies"
                    clickTagCategory={gaCategory}
                    clickTagAction="Rules & Reservation Policies link"
                    clickTagLabel={gaLabel}
                >
                    Rules & Reservation Policies
                </Button>
            </li>

            <FeatureFlag flag="programAndContractFaqFooterLink">
                <li>
                    <Button
                        appearance="link"
                        href="/faq"
                        clickTagCategory={gaCategory}
                        clickTagAction="FAQs link"
                        clickTagLabel={gaLabel}
                    >
                        Program & Contract FAQs
                    </Button>
                </li>
            </FeatureFlag>

            <li>
                <Button
                    appearance="link"
                    href="/accessibility"
                    clickTagCategory={gaCategory}
                    clickTagAction="Accessibility link"
                    clickTagLabel={gaLabel}
                >
                    Accessibility
                </Button>
            </li>
            <li>
                <Button
                    appearance="link"
                    href="/media-center"
                    clickTagCategory={gaCategory}
                    clickTagAction="Media Center link"
                    clickTagLabel={gaLabel}
                >
                    Media Center
                </Button>
            </li>

            <li>
                <Button
                    appearance="link"
                    href="/site-map"
                    clickTagCategory={gaCategory}
                    clickTagAction="Site Map link"
                    clickTagLabel={gaLabel}
                >
                    Site Map
                </Button>
            </li>
        </ul>
    );
}
