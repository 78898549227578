/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './AppStoreIcon.scss';

import * as React from 'react';
import cx from 'classnames';

import svgSrc from './store-icons.svg';

export interface AppStoreIconProps extends React.HTMLAttributes<HTMLDivElement> {
    iconName: 'apple-store' | 'google-store';
}

export function AppStoreIcon({ iconName, className, ...rest }: AppStoreIconProps) {
    return (
        <div
            data-component="AppStoreIcon"
            className={cx(iconName, className)}
            style={{
                backgroundImage: `url(${svgSrc})`,
                backgroundPosition: `${iconName === 'apple-store' ? -6 : -201}px -1px `,
            }}
            {...rest}
        />
    );
}
