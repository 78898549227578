/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import isArray from 'lodash/isArray';
import { FiltersTypeProps, UiFiltersProps } from '../types/filters';
import {
    INVENTORY_KEYS,
    INVENTORY_TYPES_CAMPING,
    INVENTORY_TYPES_DAY_USE,
    INVENTORY_TYPES_TICKETS,
    INVENTORY_TYPES_TREE_PERMITS,
    INVENTORY_TYPES_VENUES,
} from '../constants/filters/inventoryTypes';
import {
    CAMPSITE_CAMPING_TYPE,
    CAMPSITE_DAYUSE_TYPE,
    FLAT_CAMPSITE_FILTERS,
    FLAT_CAMPING_FILTERS,
    OCCUPANTS_TYPE,
    VEHICLE_LENGTH_TYPE,
    FG_FILTERS_GLOSARY,
} from '../constants/filters/camping';

type QueryParamValue = string | string[];
type QueryParamResult = Record<string, QueryParamValue>;

export const getCount = (keys: Array<string>, uiFilters: { [key: string]: object }) => {
    let count = 0;
    keys.forEach((item) => {
        count += Object.keys(uiFilters[item]).length;
    });
    return count;
};

export const getGroupCount = (
    keys: Array<string>,
    search: { [key: string]: { [key: string]: FiltersTypeProps } }
) => {
    let count = 0;
    const fgKeys = get(search, 'fg', []);
    // @ts-ignore
    fgKeys.forEach((item: string) => {
        if (keys.find((v) => item.includes(v))) {
            count += 1;
        }
    });
    return count;
};

interface FilterConfig {
    key: string;
    params: string[];
    result: string;
}

// Query params from URL
type QueryParams = Record<string, string | string[]>;

interface FilterConfig {
    key: string;
    params: string[];
    result: string;
}

interface ProcessedTypeFilters {
    parsedFilters: string[];
    matchedParams: string[];
}

const filterConfigs: FilterConfig[] = [
    {
        key: 'campsiteTypes',
        params: ['campsite-type-cabin', 'campsite-type-lookout', 'campsite-type-yurts'],
        result: 'lodging',
    },
    {
        key: 'campsiteTypes',
        params: ['campsite-type-tent-only', 'campsite-type-standard'],
        result: 'tent',
    },
    {
        key: 'campsiteEquipment',
        params: ['campsite-equipment-rv', 'campsite-equipment-trailer'],
        result: 'rmt',
    },
];

const FILTER_TYPES = {
    INVENTORY: 'inventoryTypes',
    EQUIPMENT: 'campsiteEquipment',
    SITE_TYPES: 'campsiteTypes',
    USE_TYPES: 'campsiteUseTypes',
    AMENITIES: 'campsiteAmenities',
    ELECTRICITY: 'campsiteElectricityHookup',
    VEHICLE_LENGTH: 'campsiteVehicleLength',
} as const;

const INVENTORY_MAPPING = {
    tours: INVENTORY_TYPES_TICKETS,
    venuereservations: INVENTORY_TYPES_VENUES,
    treepermit: INVENTORY_TYPES_TREE_PERMITS,
    treepermits: INVENTORY_TYPES_TREE_PERMITS,
} as const;

/**
 * Checks if all parameters in a filter group are selected
 */
const areAllParamsSelected = (
    filter: { [key: string]: FiltersTypeProps } | undefined,
    params: string[]
): boolean => {
    if (!filter) return false;
    return params.every((param) => filter[param]);
};

/**
 * Processes camping UI filters excluding already matched parameters
 */
const processCampingUiFilters = (
    uiFilters: UiFiltersProps,
    matchedParams: string[]
): string[] => {
    // Combine all camping-related filters
    const campingFilters = {
        ...uiFilters[FILTER_TYPES.EQUIPMENT],
        ...uiFilters[FILTER_TYPES.SITE_TYPES],
        ...uiFilters[FILTER_TYPES.USE_TYPES],
        ...uiFilters[FILTER_TYPES.AMENITIES],
        ...uiFilters[FILTER_TYPES.ELECTRICITY],
        ...uiFilters[FILTER_TYPES.VEHICLE_LENGTH],
    };

    // Filter out any matched params from previous processing
    const filteredCampingFilters = Object.fromEntries(
        Object.entries(campingFilters).filter(
            ([key, value]) => value && !matchedParams.includes(key)
        )
    );

    if (isEmpty(filteredCampingFilters)) {
        return [];
    }

    return Object.keys(filteredCampingFilters)
        .map((key) => {
            const filter = FLAT_CAMPING_FILTERS.find((v) => v.legacyKey === key);
            return filter?.key;
        })
        .filter(Boolean) as string[];
};

/**
 * Processes URL query parameters
 */
const processCampingQueryParams = (query: QueryParams): string[] => {
    if (isEmpty(query)) return [];

    const parsedFilters: string[] = [];

    // Process flat filters from query
    const queryValues = flatten(Object.values(query));
    queryValues.forEach((value) => {
        if (typeof value === 'string') {
            const filter = FLAT_CAMPING_FILTERS.find(
                (v) =>
                    v.value.toLowerCase() === value.toLowerCase() ||
                    v.key.toLowerCase() === value.toLowerCase()
            );
            if (filter) {
                parsedFilters.push(filter.key);
            }
        }
    });

    // Handle special parameters
    if (query[OCCUPANTS_TYPE.key]) {
        parsedFilters.push(`occupants:${query[OCCUPANTS_TYPE.key]}`);
    }
    if (query[VEHICLE_LENGTH_TYPE.key]) {
        parsedFilters.push(`vehicleLength:${query[VEHICLE_LENGTH_TYPE.key]}`);
    }

    return parsedFilters;
};

/**
 * Processes camping type filters (tent, RV, lodging) this is specific for global sidebar options and returns matched parameters
 * We need to remove this once we update the sidebar uiFilters to fg
 */
const processCampingFromSidebarFilters = (
    uiFilters: UiFiltersProps
): ProcessedTypeFilters => {
    for (const config of filterConfigs) {
        if (
            uiFilters?.[config.key] &&
            areAllParamsSelected(uiFilters[config.key], config.params)
        ) {
            return {
                parsedFilters: [INVENTORY_TYPES_CAMPING, config.result],
                matchedParams: config.params,
            };
        }
    }
    return { parsedFilters: [], matchedParams: [] };
};

/**
 * Parse camping-specific filters
 */
const parseCampingToFgParams = (
    uiFilters: UiFiltersProps,
    query: QueryParams
): string[] => {
    /** First process type filters to get matched params
     * this is specific for global sidebar options and returns matched parameters
     */
    const { parsedFilters: typeFilters, matchedParams } =
        processCampingFromSidebarFilters(uiFilters);

    // Then process remaining UI filters excluding matched params
    const uiParsedFilters = processCampingUiFilters(uiFilters, matchedParams);

    // Finally process query parameters
    const queryFilters = processCampingQueryParams(query);

    return [...typeFilters, ...uiParsedFilters, ...queryFilters];
};

/**
 * Processes inventory filters from ui filters and query
 */
export const processInventoryFromUiFilters = (
    inventoryTypesFromUiFilters: { [key: string]: FiltersTypeProps } = {}
) => {
    return Object.entries(inventoryTypesFromUiFilters)
        .filter(([_, selected]) => selected)
        .map(
            ([type]) => INVENTORY_MAPPING[type as keyof typeof INVENTORY_MAPPING] || type
        );
};

const processInventoryFromQuery = (query: QueryParams) => {
    return isArray(query['inventory_type'])
        ? query['inventory_type']
        : [query['inventory_type']].map(
              (type) => INVENTORY_MAPPING[type as keyof typeof INVENTORY_MAPPING] || type
          );
};

const processInventoryToFgParams = (
    inventoryTypesFromUiFilters: { [key: string]: FiltersTypeProps } = {},
    query: QueryParams
): string[] => {
    const inventoryFromUiFilters = processInventoryFromUiFilters(
        inventoryTypesFromUiFilters
    );
    const inventoryFromQuery = processInventoryFromQuery(query);

    // validate that the keys are valid fg
    const allInventories = [...inventoryFromQuery, ...inventoryFromUiFilters].filter(
        (item) => INVENTORY_KEYS.includes(item)
    );
    return allInventories;
};

/**
 * Main function to parse all camping and inventory types to FG filters
 */
export const parseFgParams = (
    uiFilters: UiFiltersProps,
    urlQuery: QueryParams
): string[] => {
    // Process inventory filters
    const inventoryFilters = processInventoryToFgParams(
        uiFilters[FILTER_TYPES.INVENTORY],
        urlQuery
    );

    // Process camping-specific filters
    const campingFilters = parseCampingToFgParams(uiFilters, urlQuery);

    // Combine all filters
    const allFilters = [...inventoryFilters, ...campingFilters];

    // Normalize camping/dayuse types
    const normalizedFilters = allFilters.map((filter) => {
        switch (filter) {
            case 'campsite_dayuse':
                return INVENTORY_TYPES_DAY_USE;
            case 'campsite_camping':
                return INVENTORY_TYPES_CAMPING;
            default:
                return filter;
        }
    });

    return uniq(normalizedFilters);
};

export const removeCampingFromUiFilters = (uiFilters: UiFiltersProps) => {
    const filters = { ...uiFilters };
    filters['campsiteUseTypes'] = {};
    filters['campsiteTypes'] = {};
    filters['campsiteAmenities'] = {};
    filters['campsiteElectricityHookup'] = {};
    filters['campsiteVehicleLength'] = {};
    filters['campsiteEquipment'] = {};
    filters['occupants'] = {};
    filters['inventoryTypes'] = {};
    return filters;
};

export const parseCampsiteFilters = (fg: Array<string>) => {
    const params: Array<string> = [];
    const keys = FLAT_CAMPSITE_FILTERS.map((item) => item.key);
    if (fg?.length > 0) {
        fg.forEach((item: string) => {
            if (
                keys.find(
                    (v) =>
                        item.includes(v) &&
                        item !== INVENTORY_TYPES_CAMPING &&
                        item !== INVENTORY_TYPES_DAY_USE
                )
            ) {
                params.push(item);
            } else if (item === INVENTORY_TYPES_CAMPING) {
                params.push(CAMPSITE_CAMPING_TYPE);
            } else if (item === INVENTORY_TYPES_DAY_USE) {
                params.push(CAMPSITE_DAYUSE_TYPE);
            }
        });
    }
    return params;
};

export const toggleFilterGroups = (
    item: FiltersTypeProps,
    search: { fg: Array<string> }
) => {
    let searchFilterGroups: Array<string> = [];
    if (search?.fg) {
        const fgParams = get(search, 'fg', []);
        searchFilterGroups = [...fgParams];
    }

    const removeFilter = searchFilterGroups.find((key) => key === item.key);
    if (removeFilter) {
        searchFilterGroups = searchFilterGroups.filter((key) => key !== item.key);
    } else {
        searchFilterGroups = [...searchFilterGroups, item.key];
    }
    return searchFilterGroups;
};

export const getFgActiveFiltersForGa = (items: string[]) => {
    let params: object = {};
    if (items.length > 0) {
        items.forEach((value) => {
            Object.entries(FG_FILTERS_GLOSARY).forEach((item) => {
                const [key, values] = item;
                const current = values.find((v) => value.includes(v.key));
                if (current) {
                    const acc: string = get(params, key, '');
                    params = {
                        ...params,
                        [key]: isEmpty(acc) ? value : `${acc},${value}`,
                    };
                }
            });
        });
    }

    let activeFilters = '';

    if (!isEmpty(params)) {
        Object.entries(params).forEach((item) => {
            const [key, value] = item;
            activeFilters += `|${key}:${value}`;
        });
    }

    return activeFilters;
};

export const processQueryParams = (searchParams: URLSearchParams): QueryParamResult => {
    if (!searchParams || !(searchParams instanceof URLSearchParams)) {
        return {};
    }

    try {
        const result: QueryParamResult = {};

        searchParams.forEach((value, key) => {
            if (!key) return;
            if (typeof value !== 'string') return;

            if (!result[key]) {
                result[key] = [];
            }

            if (Array.isArray(result[key])) {
                (result[key] as string[]).push(value);
            }
        });

        for (const key in result) {
            if (result.hasOwnProperty(key)) {
                const value = result[key];
                if (Array.isArray(value) && value.length === 1) {
                    result[key] = value[0];
                }
            }
        }

        return result;
    } catch {
        return {};
    }
};
