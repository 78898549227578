/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * ArticleItemList.jsx
 * Created by Destin Frasier on 11/22/17
 * Updated 10/10/22
 */

import PropTypes from 'prop-types';
import { FEATURED_EXTENDED } from '../../../constants/propTypes';
import InspirationInfoItem from '../../sharedComponents/InspirationInfoItem';
import { parseArticleImage } from '../../../utils/articlesHelper';

import type { ArticleRecordWithUrl } from '../../../types';

interface ArticleItemListProps {
    articles: ArticleRecordWithUrl[];
}

export default function ArticleItemList({ articles }: ArticleItemListProps) {
    return (
        <>
            {articles.map((article, index) => {
                // Updated design will only show 2 articles on the sidebar
                // This `if` statement can be removed once the backend API only returns
                // 3 items instead of 10 (Featured article + 2 sidebar articles)
                if (index < 2) {
                    return (
                        <InspirationInfoItem
                            name={article.article_title}
                            image={parseArticleImage(article)}
                            imageAlt={article.hero_image_alt_text}
                            url={article.url}
                            key={article.id}
                            id={`${article.id}`}
                        />
                    );
                }
                return null;
            })}

            {/* Need this for testing
                    <InspirationInfoItem
                        name='test'
                        contentType='test'
                        image="test"
                        imageAlt="test"
                        url="foo"
                        key='Key1'
                        id="id"
                    />
                    <InspirationInfoItem
                        name='test'
                        contentType='test'
                        image="test"
                        imageAlt="test"
                        url="foo"
                        key='Key2'
                        id="id"
                    />
            */}
        </>
    );
}

ArticleItemList.propTypes = {
    articles: PropTypes.arrayOf(FEATURED_EXTENDED),
};
