/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Navigate, Route } from 'react-router-dom-v5-compat';
import { NotFoundPage, Spinner } from 'sarsaparilla';

import {
    AccessibilityPage,
    RulesReservationPoliciesPage,
    DisclaimersPage,
    GetInvolvedPage,
    MobileAppPage,
    PrivacyPolicyPage,
    AboutUsPage,
    GovernmentShutdownPage,
    FOIAPage,
    NewImplementationsPage,
    NewInventoryLearnMorePage,
    MediaCenterPage,
} from 'site-kit';

import { ArticlePage } from '../src/containers/articles/ArticlePage';
import { ArticlesPage } from '../src/containers/articles/ArticlesPage';
import DiscoverLocation from '../src/containers/tertiary/DiscoverLocation';
import DiscoverState from '../src/containers/tertiary/DiscoverState';
import DiscoverCampingLocation from '../src/containers/tertiary/DiscoverCampingLocation';
import DiscoverCampingState from '../src/containers/tertiary/DiscoverCampingState';
import DonationTACBLM from '../src/containers/tertiary/donation-tacs/DonationTACBLM';
import DonationTACUSACE from '../src/containers/tertiary/donation-tacs/DonationTACUSACE';
import DonationTACUSFS from '../src/containers/tertiary/donation-tacs/DonationTACUSFS';
import FAQ from '../src/containers/tertiary/faq/FAQ';
import HomePageContainer from '../src/containers/homepage/HomePageContainer';
import Rentals from '../src/containers/tertiary/Rentals';
import SiteMap from '../src/containers/tertiary/SiteMap';
import UnsupportedBrowser from '../src/containers/tertiary/UnsupportedBrowser';
import UseOurData from '../src/containers/tertiary/UseOurData';
import SubscribePage from '../src/containers/tertiary/SubscribePage';
import MobileSignUp from '../src/containers/tertiary/MobileSignUp';
import WhatsNew from '../src/containers/tertiary/WhatsNew';

import routesArray from './routesArray';

const Feedback = React.lazy(() => import('../src/containers/tertiary/Feedback'));

const GiftCardPage = React.lazy(
    () => import('../src/containers/tertiary/giftCards/GiftCardPage')
);
const GiftCardOrderDetailsPage = React.lazy(
    () => import('../src/containers/tertiary/giftCards/GiftCardOrderDetailsPage')
);

const VulnerabilityDisclosurePolicyPage = React.lazy(
    () => import('../src/containers/VulnerabilityDisclosurePolicyPage')
);

const Components = {
    AboutUsPage,
    AccessibilityPage,
    ArticlePage,
    ArticlesPage,
    DisclaimersPage,
    DiscoverLocation,
    DiscoverState,
    DiscoverCampingLocation,
    DiscoverCampingState,
    DonationTACBLM,
    DonationTACUSACE,
    DonationTACUSFS,
    FAQ,
    Feedback,
    FOIAPage,
    Rentals,
    GiftCardOrderDetailsPage,
    GiftCardPage,
    HomePageContainer,
    GovernmentShutdownPage,
    MediaCenterPage,
    MobileAppPage,
    NewImplementationsPage,
    NewInventoryLearnMorePage,
    PrivacyPolicyPage,
    GetInvolvedPage,
    VulnerabilityDisclosurePolicyPage,
    RulesReservationPoliciesPage,
    UnsupportedBrowser,
    UseOurData,
    SiteMap,
    SubscribePage,
    MobileSignUp,
    WhatsNew,
};

export default function makeSwitchRoutes(ldFlags) {
    const routeComponents = routesArray.map((route) => {
        const Component = Components[route.componentName];

        if (route.enabled === false) {
            return null;
        }

        if (route.ldFlagEnabled) {
            if (ldFlags[route.ldFlagEnabled] === undefined) {
                return <Route key={route.path} path={route.path} element={<Spinner />} />;
            }
            if (ldFlags[route.ldFlagEnabled] === false) {
                return null;
            }
        }

        return <Route key={route.path} path={route.path} element={<Component />} />;
    });

    routeComponents.push(
        <Route
            key="/new-inventory"
            path="/new-inventory"
            element={<Navigate to="/new-implementations" />}
        />
    );

    routeComponents.push(<Route path="*" key="notFound" element={<NotFoundPage />} />);

    return routeComponents;
}

// cSpell:ignore FOIA, TACBLM, TACUSACE, TACUSFS, tacs
