/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import shuffle from 'lodash/shuffle';

import { InventoryPromoLink, MapHelper, Carousel, SearchActions } from 'sarsaparilla';
import HeroCarouselPlaceholder from './HeroCarouselPlaceholder.jsx';

export default function CampingLodgingCarousel({
    itemWidth,
    data,
    dates,
    enableAvailableThisWeekend,
}) {
    const dispatch = useDispatch();
    const isMountedRef = useRef(false);
    const { isLoading, hasErrors, locations } = data;
    const gaEventCategory = 'Homepage';
    const gaEventLabel = 'Hero';

    const shuffledLocations = useMemo(() => {
        let items = [];

        if (!isMountedRef.current && !!locations.length) {
            items = shuffle(locations);
            isMountedRef.current = true;
        } else {
            items = locations;
        }

        return items;
    }, [locations]);

    if (isLoading) {
        return <HeroCarouselPlaceholder />;
    }

    if (hasErrors || (locations?.length === 0 && !isLoading)) {
        return null;
    }

    const getClickTagAction = (item) => {
        const baseAction = `Camping & Lodging Card - ${item.name}`;

        if (!item.preview_image_url) {
            return `${baseAction} - Photo Unavailable`;
        }

        return baseAction;
    };

    const sortedLocations = shuffledLocations?.sort((a, b) => a.distance - b.distance);

    const locationSlides = sortedLocations.map((item, index) => {
        const hasCity = item.city === undefined ? '' : `${item.city}`;
        const hasStateCode = item.state_code === undefined ? '' : item.state_code;
        const hasComma = hasCity && hasStateCode ? ',' : '';

        const onClickPromoLink = () => {
            if (dates.startDate && dates.endDate && enableAvailableThisWeekend) {
                dispatch(
                    SearchActions.updateSearchCriterias({
                        checkin_time: dates.startDate.format('MM/DD/YYYY'),
                        checkout_time: dates.endDate.format('MM/DD/YYYY'),
                    })
                );
            }
            SearchActions.trackRunSearch(
                {
                    category: gaEventCategory,
                    action: getClickTagAction(item),
                    label: gaEventLabel,
                },
                () => {}
            );
        };

        return (
            <InventoryPromoLink
                shouldTranslateHeading={false}
                shouldTranslateLocation={false}
                imagePath={item.preview_image_url}
                imageHeight="80px"
                onClick={onClickPromoLink}
                href={MapHelper.getTargetUri(item)}
                heading={item.name}
                location={`${hasCity}${hasComma} ${hasStateCode}`}
                mobileCoverage={null}
                key={index}
                clickTagCategory={gaEventCategory}
                clickTagAction={getClickTagAction(item)}
                clickTagLabel={gaEventLabel}
            />
        );
    });

    return (
        <Carousel
            className="nav-hero-search-carousel"
            slideTargetWidth={itemWidth}
            hasGutters
        >
            {locationSlides}
        </Carousel>
    );
}

CampingLodgingCarousel.propTypes = {
    itemWidth: PropTypes.number,
    dates: PropTypes.object,
    enableAvailableThisWeekend: PropTypes.bool,
    data: PropTypes.shape({
        locations: PropTypes.array,
        isLoading: PropTypes.bool,
        hasErrors: PropTypes.bool,
    }),
};

CampingLodgingCarousel.defaultProps = {
    itemWidth: 175,
    data: {
        locations: [],
        isLoading: true,
        hasErrors: false,
    },
};
