/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { searchDate } from 'ui-search/src/utils/dateUtils';

export const getAvailableDatesHelper = (item) => {
    const availableDates = item?.available_dates?.[0];
    const startDateValue = searchDate(availableDates?.start_date || null).utc();
    const endDateValue = searchDate(availableDates?.end_date || null).utc();

    if (!startDateValue.isValid() || !endDateValue.isValid()) {
        return '';
    }

    return [startDateValue, endDateValue];
};

export const getAvailableDates = (item) => {
    const avaliableDateValues = getAvailableDatesHelper(item);

    if (!avaliableDateValues) {
        return '';
    }

    const [startDateValue, endDateValue] = avaliableDateValues;
    const startDateMonth = startDateValue.format('MMM');
    const startDateDay = startDateValue.format('D');
    const endDateMonth = endDateValue.format('MMM');
    const endDateDay = endDateValue.format('D');

    if (startDateMonth === endDateMonth && startDateDay === endDateDay) {
        return `${startDateMonth} ${startDateDay}`;
    }

    if (startDateMonth === endDateMonth && startDateDay !== endDateDay) {
        return `${startDateMonth} ${startDateDay} - ${endDateDay}`;
    }

    return `${startDateMonth} ${startDateDay} - ${endDateMonth} ${endDateDay}`;
};
