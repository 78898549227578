/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * MapContainer.jsx
 * Created by michael bray on 10/4/17
 * Updated by Aaron Luna on 02/14/22
 */

// cSpell:ignore mapbox, mapboxgl

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import find from 'lodash/find';
import mapboxgl from 'mapbox-gl';

import { Heading, ContentBlock, Stack } from 'sarsaparilla';

import * as mapActions from '../../actions/mapActions';
import { defaultSearchLocation } from '../../constants/mapValues';
import * as StateHelper from '../../utils/stateHelper';
import HomepageMapWrapper from '../../components/homepage/map/HomepageMapWrapper';

export class MapContainer extends React.Component {
    static propTypes = {
        mapSearchSuggestions: PropTypes.array,
        mapInventory: PropTypes.array,
        mapSearchResultLocation: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
        }),
        selectedMapSearchTerm: PropTypes.string,
        selectMapSearchResult: PropTypes.func,
        updateSelectedMapSearchTerm: PropTypes.func,
        ignoreSessionStore: PropTypes.bool,
        fetchMapInventory: PropTypes.func,
        performMapSearch: PropTypes.func,
        clearSuggestions: PropTypes.func,
        hideText: PropTypes.bool,
        selectedCardItem: PropTypes.object,
        uiFilters: PropTypes.object,
        filteredMapInventory: PropTypes.array,
        inventoryIsLoaded: PropTypes.bool,
        isGatewayMap: PropTypes.bool,
    };

    static defaultProps = {
        mapSearchSuggestions: [],
        mapInventory: [],
        mapSearchResultLocation: defaultSearchLocation,
        hideText: false,
        uiFilters: {},
        filteredMapInventory: [],
        inventoryIsLoaded: false,
        isGatewayMap: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            loadedMap: false,
        };

        this.selectSearchResult = this.selectSearchResult.bind(this);
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(this.fetchMapSuggestions, {
            rootMargin: '50px',
            threshold: 0.75,
        });

        this.observer.observe(this.mapWrapper);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapSearchSuggestions !== this.props.mapSearchSuggestions) {
            this.generateMapSearchSuggestions(this.props.mapSearchSuggestions);
        }
    }

    componentWillUnmount() {
        this.observer.unobserve(this.mapWrapper);
    }

    fetchMapSuggestions = (entries) => {
        if (entries[0] && entries[0].isIntersecting && this.state.loadedMap === false) {
            this.generateMapSearchSuggestions(this.props.mapSearchSuggestions);
        }
    };

    selectSearchResult(selection, suggestion) {
        this.props.selectMapSearchResult(selection, suggestion);
    }

    generateStateAbbreviation(suggestion) {
        let state = find(StateHelper.states, { label: suggestion });

        if (suggestion === 'United States') {
            state = {
                abbreviation: 'USA',
            };
        }

        if (state === undefined) {
            state = {
                abbreviation: '',
            };
        }

        return state;
    }

    generateMapSearchSuggestions(mapSearchSuggestions) {
        const suggestions = [];

        mapSearchSuggestions.forEach((searchResultItem) => {
            const searchResultText = searchResultItem.text;
            const searchResultArray = searchResultText.split(', ');

            const city = searchResultArray[0];
            const state = this.generateStateAbbreviation(searchResultArray[1]);
            const stateAbbreviation = state.abbreviation;

            let label = `${city}, ${stateAbbreviation}`;
            if (stateAbbreviation === '') {
                label = `${city}`;
            }

            suggestions.push({
                label,
                searchResultText,
                searchResultItem,
            });
        });

        this.setState({
            suggestions,
            loadedMap: true,
        });
    }

    render() {
        const isMapboxGlSupported = mapboxgl.supported();
        const showResultsClass = this.state.suggestions.length > 0 ? '' : 'hide';

        return (
            <ContentBlock>
                <section
                    ref={(mapWrapper) => {
                        this.mapWrapper = mapWrapper;
                    }}
                >
                    <Stack space="gutter">
                        {!this.props.hideText && (
                            <Stack space={'lg'}>
                                <Heading
                                    headingLevel={2}
                                    appearance="h4"
                                    hasUnderline="left"
                                >
                                    Explore Destinations & Activities
                                </Heading>
                            </Stack>
                        )}

                        {this.state.loadedMap && (
                            <HomepageMapWrapper
                                isMapboxGlSupported={isMapboxGlSupported}
                                showResultsClass={showResultsClass}
                                selectedMapSearchTerm={this.props.selectedMapSearchTerm}
                                updateSelectedMapSearchTerm={
                                    this.props.updateSelectedMapSearchTerm
                                }
                                performMapSearch={this.props.performMapSearch}
                                clearSuggestions={this.props.clearSuggestions}
                                suggestions={this.state.suggestions}
                                selectSearchResult={this.selectSearchResult}
                                mapInventory={this.props.mapInventory}
                                mapSearchResultLocation={
                                    this.props.mapSearchResultLocation
                                }
                                fetchMapInventory={this.props.fetchMapInventory}
                                ignoreSessionStore={this.props.ignoreSessionStore}
                                mapWrapper={this.mapWrapper}
                                selectedCardItem={this.props.selectedCardItem}
                                uiFilters={this.props.uiFilters}
                                filteredMapInventory={this.props.filteredMapInventory}
                                inventoryIsLoaded={this.props.inventoryIsLoaded}
                                isGatewayMap={this.props.isGatewayMap}
                            />
                        )}
                    </Stack>
                </section>
            </ContentBlock>
        );
    }
}

export default connect(
    (state) => ({
        mapSearchSuggestions: state.navigation.map.mapSearchSuggestions,
        selectedMapSearchTerm: state.navigation.map.selectedMapSearchTerm,
        mapSearchResultLocation: state.navigation.map.mapSearchResultLocation,
        mapInventory: state.navigation.map.mapInventory,
        selectedCardItem: state.navigation.map.selectedCardItem,
        uiFilters: state.navigation.map.uiFilters,
        filteredMapInventory: state.navigation.map.filteredMapInventory,
        inventoryIsLoaded: state.navigation.map.inventoryIsLoaded,
    }),
    (dispatch) => bindActionCreators(mapActions, dispatch)
)(MapContainer);
