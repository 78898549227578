/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import cx from 'classnames';
import { FlexRow, FlexCol, ImageWithFallback, Heading } from 'sarsaparilla';

interface MobileAppFeatureProps {
    id: string;
    title: string;
    children: React.ReactNode;
    hasMediaFirst?: boolean;
    hasBackgroundColor?: boolean;
    src: string;
    fallbackSrc: string;
    imageAlt: string;
    className?: string;
}

export function MobileAppFeature({
    id,
    title,
    children,
    hasMediaFirst = false,
    hasBackgroundColor = false,
    src,
    fallbackSrc,
    imageAlt,
    className,
}: MobileAppFeatureProps) {
    return (
        <section
            className={cx('nav-mobile-app-feature', className, {
                'nav-mobile-app-feature-bg-muted': hasBackgroundColor,
            })}
        >
            <span className="nav-mobile-app-anchor-block" id={id} />
            <div className="nav-mobile-app-feature-content">
                <FlexRow justifyContent="center">
                    <FlexCol xl={10} lg={12}>
                        <FlexRow>
                            <FlexCol lg={6} lgOrder={hasMediaFirst ? 'last' : undefined}>
                                <Heading
                                    headingLevel={2}
                                    appearance="h5"
                                    className="nav-mobile-app-feature-title"
                                >
                                    {title}
                                </Heading>
                                {children}
                            </FlexCol>
                            <FlexCol lg={6}>
                                <div className="nav-mobile-app-feature-media">
                                    <div className="nav-mobile-app-feature-media-item">
                                        <ImageWithFallback
                                            src={src}
                                            fallbackSrc={fallbackSrc}
                                            alt={imageAlt}
                                        />
                                    </div>
                                </div>
                            </FlexCol>
                        </FlexRow>
                    </FlexCol>
                </FlexRow>
            </div>
        </section>
    );
}
