/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * AppDividerPromoContainer.jsx
 * Created by Destin Frasier on 01/18/22
 * Updated by Aaron Luna on 02/15/22
 */

import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { DividerPromo, ButtonGroup, Button } from 'sarsaparilla';

export default function DividerPromoContainer(props) {
    const targetRef = useRef();

    const href = '/mobile-app/';

    return (
        <DividerPromo
            heading="Adventure is at Your Fingertips"
            backgroundImagePath={`${process.env.CDN}/img/homepage/app-divider-promo-section/app-promo-bg.jpg`}
            overlayImagePath={`${process.env.CDN}/img/homepage/app-divider-promo-section/app-promo-overlay`}
            overlayImageAppearance="imageSetBottom"
            overlayImageAlt={`${process.env.SITE_NAME} mobile app`}
            topSpacerSize={props.topSpacerSize}
            copy={`Get outdoors, experience America, and bring home a story with the ${process.env.SITE_NAME} mobile app!`}
            height="sm"
            promoHeadingLevel={3}
            refForIntersection={targetRef}
        >
            <ButtonGroup isFullWidthOnMobile={false}>
                <Button
                    href={href}
                    appearance="tertiary-white"
                    gaReportName={'App Divider Promo Container'}
                    gaReportCreative={'mobile app promo'}
                    gaReportPosition={'HomePage'}
                    shouldReportToGA
                    screenReaderTextAfter="about our mobile app"
                >
                    Learn More
                </Button>
            </ButtonGroup>
        </DividerPromo>
    );
}

DividerPromoContainer.propTypes = {
    topSpacerSize: PropTypes.string,
};

DividerPromoContainer.defaultProps = {
    topSpacerSize: 'xxxl',
};
