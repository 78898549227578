/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';

import {
    FlexRow,
    FlexCol,
    Placeholder,
    PlaceholderText,
    useWindowSize,
} from 'sarsaparilla';

export default function HeroCarouselPlaceholder() {
    const { width } = useWindowSize();
    let numberOfItems = 5;

    if (width > 767 && width < 927) {
        numberOfItems = 3;
    }

    if (width > 928 && width < 1149) {
        numberOfItems = 4;
    }

    if (width > 1150) {
        numberOfItems = 5;
    }

    const items = [...Array(numberOfItems)];

    const nextLastBtns = (
        <FlexCol md="variable">
            <Placeholder width="24px" height="24px" borderRadius="50%" />
        </FlexCol>
    );

    return (
        <FlexRow alignItems="center" justifyContent="center">
            {nextLastBtns}

            <FlexCol md="auto">
                <FlexRow hasGutters={false} className="mb-3">
                    {items.map((_item, index) => {
                        return (
                            <FlexCol key={index} md="auto" className="mr-1 ml-1">
                                <Placeholder
                                    height="80px"
                                    style={{ marginTop: '4px', marginBottom: '4px' }}
                                />
                                <PlaceholderText
                                    lines={1}
                                    fontSize={12}
                                    lineHeight={17}
                                    width="100%"
                                />
                                <div className="mb-1">
                                    <PlaceholderText
                                        lines={1}
                                        fontSize={12}
                                        lineHeight={17}
                                        width="40%"
                                    />
                                </div>
                                <PlaceholderText
                                    lines={1}
                                    fontSize={10}
                                    lineHeight={14}
                                    width="90%"
                                />
                            </FlexCol>
                        );
                    })}
                </FlexRow>
            </FlexCol>

            {nextLastBtns}
        </FlexRow>
    );
}
