/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';

interface MobileAppOverviewTabProps {
    activeTab: string;
    title: string;
    onClick: (t: string) => void;
    description: React.ReactNode;
    icon: React.ReactNode;
}

export function MobileAppOverviewTab({
    activeTab,
    title,
    onClick,
    description,
    icon,
}: MobileAppOverviewTabProps) {
    function handleClick() {
        onClick(title);
    }
    const className = `nav-mobile-app-overview-tab ${
        activeTab === title ? 'active' : ''
    }`;

    return (
        <div className={className}>
            <div className="nav-mobile-app-overview-tab-icon">{icon}</div>
            <div className="nav-mobile-app-overview-tab-content">
                <div className="nav-mobile-app-overview-tab-title">{title}</div>
                {description}
            </div>
            <button
                type="button"
                className="nav-mobile-app-overview-tab-button"
                onClick={handleClick}
            >
                <span className="rec-sr-only">{title}</span>
            </button>
        </div>
    );
}
