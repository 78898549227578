/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * DividerPromoContainer.jsx
 * Created by Destin Frasier on 05/13/20
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DividerPromo, ButtonGroup, Button } from 'sarsaparilla';

export default function DividerPromoContainer(props) {
    const targetRef = useRef();
    return (
        <DividerPromo
            heading="Interagency & Site Passes"
            promoHeadingLevel={3}
            backgroundImagePath={`${process.env.CDN}/img/homepage/divider-promo-section/park-entrance-banner-bg.jpg`}
            copy="Whether you're planning a single visit, coming back throughout the year, or traveling to multiple destinations, enjoy the convenience of purchasing a daily, weekly, seasonal, or annual pass to cover admissions, recreation, or amenity fees for your trip(s)."
            height="md"
            topSpacerSize={props.topSpacerSize}
            refForIntersection={targetRef}
        >
            <ButtonGroup isFullWidthOnMobile={false}>
                <Button
                    appearance="tertiary-white"
                    href="/pass/"
                    gaReportName={'Divider Promo Container'}
                    gaReportCreative={'divider promo for passes'}
                    gaReportPosition={'HomePage'}
                    shouldReportToGA
                >
                    Learn More
                </Button>
            </ButtonGroup>
        </DividerPromo>
    );
}

DividerPromoContainer.propTypes = {
    topSpacerSize: PropTypes.string,
};

DividerPromoContainer.defaultProps = {
    topSpacerSize: '',
};
