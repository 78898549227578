/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { ExternalLink, useBreakpoint, useFlags } from 'sarsaparilla';
import { PartnerIcon } from '../Icons/Partner/PartnerIcon';

const partners = [
    {
        iconName: 'BLM',
        linkUrl: 'https://www.blm.gov/',
        title: 'Bureau of Land Management',
    },
    {
        iconName: 'DOT',
        linkUrl: 'https://www.fhwa.dot.gov/',
        title: 'Federal Highway Administration',
    },
    {
        iconName: 'NOAA',
        linkUrl: 'https://www.noaa.gov/',
        title: 'National Oceanic Atmosphere Administration',
    },
    {
        iconName: 'Smithsonian',
        linkUrl: 'https://www.si.edu/',
        title: 'Smithsonian Institution',
    },
    {
        iconName: 'ForestService',
        linkUrl: 'https://www.fs.usda.gov/',
        title: 'USDA Forest Service',
    },
    {
        iconName: 'USACE',
        linkUrl: 'https://www.usace.army.mil/Missions/Civil-Works/Recreation/',
        title: 'US Army Corps of Engineers',
    },
    {
        iconName: 'FWS',
        linkUrl: 'https://www.fws.gov',
        title: 'US Fish and Wildlife Service',
    },
    {
        iconName: 'NPS',
        linkUrl: 'https://www.nps.gov/index.htm',
        title: 'National Park Service',
    },
    {
        iconName: 'TRE',
        linkUrl: 'https://www.moneyfactory.gov',
        title: 'Bureau of Engraving and Printing',
    },
    {
        iconName: 'PT',
        linkUrl: 'https://presidio.gov/about/presidio-trust',
        title: 'Presidio Trust',
    },
    {
        iconName: 'TVA',
        linkUrl: 'https://www.tva.gov',
        title: 'Tennessee Valley Authority',
    },
    {
        iconName: 'DOI',
        linkUrl: 'https://www.usbr.gov',
        title: 'Bureau of Reclamation',
    },
    {
        iconName: 'NationalArchives',
        linkUrl: 'https://www.archives.gov',
        title: 'National Archives',
    },
    {
        iconName: 'NDW',
        linkUrl: 'https://www.navyregionmwrndw.com',
        title: 'Naval District Washington',
    },
] as const;

interface OurPartnersProps {
    isFooterInline?: boolean;
}

export function OurPartners({ isFooterInline }: OurPartnersProps) {
    const { isSmAndDown, isLgAndUp } = useBreakpoint();
    const { ndwContent } = useFlags();

    // Remove the Naval District Washington partner block
    const filterOut = partners.filter(
        (partner) => partner.title !== 'Naval District Washington'
    );

    // Use the LD flag to show the Naval District Washington partner block on a specific date
    const newPartners = ndwContent ? filterOut : partners;

    return (
        <ul className="nav-footer-list">
            {newPartners.map(({ title, linkUrl, iconName }) => (
                <li key={title}>
                    {/* isFooterInline displays the name of the partner without the icon */}
                    {isFooterInline || isSmAndDown ? (
                        <ExternalLink url={linkUrl}>{title}</ExternalLink>
                    ) : (
                        <ExternalLink url={linkUrl}>
                            <PartnerIcon
                                iconName={iconName}
                                size={isLgAndUp ? 'lg' : 'md'}
                                showTitle={true}
                                className="r1s-partner-icon"
                            />
                        </ExternalLink>
                    )}
                </li>
            ))}
        </ul>
    );
}

// cSpell:ignore NOAA, USACE
