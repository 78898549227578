/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    UPDATE_MAP_SUGGESTIONS,
    CLEAR_MAP_SUGGESTIONS,
    UPDATE_MAP_SEARCH_TERM,
    UPDATE_MAP_SEARCH_RESULT_LAT_LONG,
    MAP_INVENTORY_FETCHED,
    FETCHING_MAP_INVENTORY_FAILED,
    INVENTORY_SELECTED_CARD,
    INVENTORY_DESELECTED_CARD,
    REMOVE_FILTER,
    ADD_FILTER,
    REMOVE_FILTERS,
    ADD_FILTERS,
    UPDATE_FILTERED_MAP_INVENTORY,
    UPDATE_MAP_SEARCH_RESULT,
} from '../constants/actionTypes';

import { defaultSearchLocation, inventory } from '../constants/mapValues';

const initialState = {
    mapSearchSuggestions: [],
    selectedMapSearchTerm: '',
    mapSearchResultLocation: defaultSearchLocation,
    mapSearchResult: {},
    mapInventory: [],
    selectedCardItem: null,
    uiFilters: {},
    filteredMapInventory: [],
    inventoryIsLoaded: false,
};

const mapReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_MAP_SUGGESTIONS:
            return {
                ...state,
                mapSearchSuggestions: action.suggestions,
            };
        case CLEAR_MAP_SUGGESTIONS:
            return { ...state, mapSearchSuggestions: [] };
        case UPDATE_MAP_SEARCH_TERM:
            return { ...state, selectedMapSearchTerm: action.selectedMapSearchTerm };
        case UPDATE_MAP_SEARCH_RESULT_LAT_LONG:
            return { ...state, mapSearchResultLocation: action.mapSearchResultLocation };
        case UPDATE_MAP_SEARCH_RESULT:
            return { ...state, mapSearchResult: action.mapSearchResult };
        case MAP_INVENTORY_FETCHED:
            return {
                ...state,
                mapInventory: action.mapInventory,
                inventoryIsLoaded: true,
            };
        case FETCHING_MAP_INVENTORY_FAILED:
            return { ...state, mapInventory: [], inventoryIsLoaded: true };
        case INVENTORY_SELECTED_CARD:
            return { ...state, selectedCardItem: action.selectedCardItem };
        case INVENTORY_DESELECTED_CARD:
            return { ...state, selectedCardItem: null };
        case ADD_FILTER:
            // eslint-disable-next-line
            const currentFilters = { ...state.uiFilters };
            currentFilters[action.item.key] = action.item;

            return { ...state, uiFilters: currentFilters };
        case REMOVE_FILTER:
            // eslint-disable-next-line
            const newUiFilters = { ...state.uiFilters };
            delete newUiFilters[action.item.key];

            return { ...state, uiFilters: newUiFilters };
        case ADD_FILTERS:
            return { ...state, uiFilters: inventory };
        case REMOVE_FILTERS:
            return { ...state, uiFilters: {} };
        case UPDATE_FILTERED_MAP_INVENTORY:
            return { ...state, filteredMapInventory: action.filteredMapInventory };
        default:
            return state;
    }
};

export default mapReducer;
