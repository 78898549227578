/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import cx from 'classnames';

import svgPath from './newInventoryIcons.svg';

export interface NewInventoryIconProps extends React.SVGAttributes<SVGElement> {
    iconName: string;
}

export function NewInventoryIcon({
    iconName,
    className,
    'aria-label': ariaLabel,
    ...rest
}: NewInventoryIconProps) {
    return (
        <svg
            data-component="NewInventoryIcon"
            className={cx('sarsa-icon', `rec-icon-${iconName}`, className)}
            role={ariaLabel ? 'img' : 'presentation'}
            aria-label={ariaLabel || undefined}
            focusable="false"
            {...rest}
        >
            <use
                href={`${svgPath}#rec-icon-new-inventory-${iconName}`}
                className={iconName}
            />
        </svg>
    );
}
