/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import campingPhotoDesktop from './camping-lodging_bg_194.jpg';
import campingPhotoMobile from './camping-lodging_bg-mobile.jpg';
import ticketsPhotoDesktop from './tickets-tours_bg_194.jpg';
import ticketsPhotoMobile from './tickets-tours_bg-mobile.jpg';
import permitsPhotoDesktop from './permits_bg_194.jpg';
import permitsPhotoMobile from './permits_bg-mobile.jpg';
import dayUsePhotoDesktop from './day-use-venues_bg_194.jpg';
import dayUsePhotoMobile from './day-use-venues_bg-mobile.jpg';

import type { QuickPromosType } from '../../../../types/quickPromos';

export const quickPromos: QuickPromosType = [
    {
        title: 'Camping & Lodging',
        image: campingPhotoDesktop,
        mobileImage: campingPhotoMobile,
        imageAlt: 'Camping & Lodging',
        inventoryType: ['camping'],
    },
    {
        title: 'Tickets & Tours',
        image: ticketsPhotoDesktop,
        mobileImage: ticketsPhotoMobile,
        imageAlt: 'Tickets & Tours',
        inventoryType: ['tours', 'activitypass'],
    },
    {
        title: 'Permits',
        image: permitsPhotoDesktop,
        mobileImage: permitsPhotoMobile,
        imageAlt: 'Permits',
        inventoryType: ['permits'],
    },
    {
        title: 'Day Use / Venues',
        image: dayUsePhotoDesktop,
        mobileImage: dayUsePhotoMobile,
        imageAlt: 'Day Use',
        inventoryType: ['dayuse', 'venuereservations'],
    },
];

// cSpell:ignore activitypass, dayuse, venuereservations
