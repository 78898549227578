/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

/*
 * IntroSection.jsx
 * Created by Destin Frasier on 03/31/22
 */

import { ContentContainer, Heading, FlexRow, FlexCol } from 'sarsaparilla';
import { NewInventoryIcon } from './NewInventoryIcon';

const empowerBlocks = [
    {
        alt: 'Connect',
        iconName: 'connect',
        text: 'Connect 24/7 with customers interested in reserving campsites, tickets, permits; purchasing passes; or applying for lotteries.',
        cardClass: 'card-1',
    },
    {
        alt: 'Steward',
        iconName: 'steward',
        text: 'Responsibly steward the high-demand recreation resources while simultaneously engendering trust and ease-of-mind with the public through a world-class digital experience.',
        cardClass: 'card-2',
    },
    {
        alt: 'Manage',
        iconName: 'manage',
        text: 'Manage daily operations with ease through one centralized and secure location.',
        cardClass: 'card-3',
    },
    {
        alt: 'High-Demand',
        iconName: 'lotteries',
        text: 'Manage access to high-demand resources through lotteries and permits as well as digital pass sales.',
        cardClass: 'card-4',
    },
] as const;

export default function IntroSection() {
    return (
        <section className="nav-ni-intro-section-wrap">
            <ContentContainer className="my-0">
                <FlexRow>
                    <FlexCol xlOffset={1} xl={10}>
                        <FlexRow className="mb-4">
                            <FlexCol mdOffset={1} md={10} xlOffset={2} xl={8}>
                                <Heading
                                    headingLevel={2}
                                    appearance="h5"
                                    className="nav-ni-intro-section-heading"
                                >
                                    Recreation.gov makes it simple and secure for Federal
                                    public land and water managers to manage all of their
                                    inventory needs from camping reservations, to site and
                                    activity passes, permits, tickets, lotteries, yurts,
                                    cabins, and more!
                                </Heading>
                            </FlexCol>
                        </FlexRow>

                        <FlexRow hasGutters={false}>
                            {empowerBlocks.map((empowerBlock) => (
                                <FlexCol
                                    lg={6}
                                    className="px-fourth mb-2 mb-sm-half make-flex"
                                    key={empowerBlock.alt}
                                >
                                    <div
                                        className={`nav-ni-empower-card ${empowerBlock.cardClass}`}
                                    >
                                        <FlexRow hasGutters={false}>
                                            <FlexCol xs={12} sm="variable">
                                                <div
                                                    className={`nav-ni-empower-card-icon-wrap ${empowerBlock.cardClass}`}
                                                >
                                                    <NewInventoryIcon
                                                        iconName={empowerBlock.iconName}
                                                    />
                                                </div>
                                            </FlexCol>
                                            <FlexCol className="nav-ni-empower-card-text">
                                                {empowerBlock.text}
                                            </FlexCol>
                                        </FlexRow>
                                    </div>
                                </FlexCol>
                            ))}
                        </FlexRow>
                    </FlexCol>
                </FlexRow>
            </ContentContainer>
        </section>
    );
}
