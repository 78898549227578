/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { Heading, TextBlock } from 'sarsaparilla';

interface TicketsToursSectionProps {
    anchor?: string;
    ticketsAnchor?: string;
    ticketsModifyAnchor?: string;
    ticketsCancellationAnchor?: string;
    ticketsLateCancellationAnchor?: string;
}

export default function TicketsToursSection({
    anchor = '',
    ticketsAnchor = '',
    ticketsModifyAnchor = '',
    ticketsCancellationAnchor = '',
    ticketsLateCancellationAnchor = '',
}: TicketsToursSectionProps) {
    return (
        <section className="mb-8" id={anchor}>
            <Heading headingLevel={2} appearance="h2" className="mb-4" id={ticketsAnchor}>
                Tickets, Tours and Vehicle Reservations (Timed Entry) Reservations
            </Heading>
            <Heading headingLevel={2} appearance="h3" id={ticketsModifyAnchor}>
                Modifying/Changing an Existing Reservation Prior to the Tour Time
            </Heading>
            <TextBlock width="xl">
                <p>
                    If customers want to modify a tour or timed entry tickets, they will
                    need to make that change prior to the late cut-off window. If there
                    isn&apos;t a cut-off window, they can make changes prior to the tour
                    start time.
                </p>
                <p>
                    The cut-off window varies by location. Check the Fees and
                    Cancellations section for the ticket location on Recreation.gov.
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h3" id={ticketsCancellationAnchor}>
                Cancellations
            </Heading>
            <TextBlock width="xl">
                <p>
                    Customers may cancel their reservation prior to the start time of
                    their ticket both online and through the contact center and this may
                    allow other visitors to enjoy this recreation opportunity.
                </p>
                <p>
                    All non-refundable cancellation fees will be withheld from any refund
                    amount provided.
                </p>
                <p>
                    Depending on how close to the arrival time you request the
                    cancellation, it may be considered a late cancellation (see below).
                </p>
            </TextBlock>
            <Heading headingLevel={2} appearance="h3" id={ticketsLateCancellationAnchor}>
                Late Cancellations
            </Heading>
            <TextBlock width="xl">
                <p>
                    Some ticket locations have a unique late cancellation policy that
                    determines what is considered a “late cancellation” of a reservation.
                </p>
                <p>
                    There are no refunds for tickets that are cancelled late. Review the
                    Fees and Cancellations section of a ticket facility&apos;s page to
                    determine if they have a late cancellation window in place.
                </p>
            </TextBlock>
        </section>
    );
}
