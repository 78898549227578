/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import './GovernmentShutdownPage.scss';

import {
    ContentContainerBodyShift,
    Heading,
    Alert,
    useFlags,
    TextBlock,
    FlexRow,
    FlexCol,
    ExternalLink,
    useBreakpoint,
} from 'sarsaparilla';

import { PartnerColorIcon } from '../Icons/Partner/PartnerColorIcon';

const partners = [
    {
        iconName: 'BLM',
        name: 'Bureau of Land Management',
        description: null,
        displayStatus: false,
        link: 'https://www.blm.gov/',
        shortName: 'blm',
    },
    {
        iconName: 'NPS',
        name: 'National Park Service',
        description: null,
        displayStatus: false,
        link: 'https://www.nps.gov/index.htm',
        shortName: 'nps',
    },
    {
        iconName: 'FWS',
        name: 'US Fish and Wildlife Service',
        description: null,
        displayStatus: false,
        link: 'https://www.fws.gov/',
        shortName: 'fws',
    },
    {
        iconName: 'DOI',
        name: 'Bureau of Reclamation',
        description: null,
        displayStatus: false,
        link: 'https://www.usbr.gov/',
        shortName: 'doi',
    },
    {
        iconName: 'ForestService',
        name: 'US Forest Service',
        description: 'Concession-operated facilities will remain open.',
        displayStatus: false,
        link: 'https://www.fs.usda.gov',
        shortName: 'fs',
    },
    {
        iconName: 'USACE',
        name: 'US Army Corps of Engineers',
        description: 'Will remain open.',
        displayStatus: false,
        link: 'https://www.usace.army.mil/Missions/Civil-Works/Recreation/',
        shortName: 'usace',
    },
    {
        iconName: 'NationalArchives',
        name: 'National Archives & Records Administration',
        description: null,
        displayStatus: false,
        link: 'https://www.archives.gov/',
        shortName: 'archives',
    },
    {
        iconName: 'PT',
        name: 'Presidio Trust',
        description: null,
        displayStatus: false,
        link: 'https://presidio.gov/about/presidio-trust',
        shortName: 'pt',
    },
    {
        iconName: 'NDW',
        name: 'Naval District Washington',
        description: null,
        displayStatus: false,
        link: 'https://www.navyregionmwrndw.com/',
        shortName: 'ndw',
    },
] as const;

export function GovernmentShutdownPage() {
    const { lapseInFundingPageNotice } = useFlags();
    const { isMdAndDown } = useBreakpoint();

    return (
        <div className="nav-shutdown-info-page" role="main">
            <ContentContainerBodyShift>
                <div className="nav-section nav-shutdown-info-page mt-4">
                    <div className="nav-shutdown-header-wrap">
                        <Heading headingLevel={1} appearance="h3" className="mb-4">
                            Government Shutdown
                        </Heading>

                        {lapseInFundingPageNotice === true && (
                            <>
                                <Heading headingLevel={2} appearance="h5">
                                    The shutdown of the Federal government may impact your
                                    reservation and travel plans.
                                </Heading>
                                <TextBlock width="xl">
                                    <ul>
                                        <li>
                                            The {process.env.SITE_NAME} website will
                                            remain operational and you’ll still be able to
                                            make reservations for future trips.
                                        </li>
                                        <li>
                                            {process.env.SITE_NAME} does not maintain a
                                            comprehensive list of which locations are
                                            open, closed, or offering limited access. We
                                            recommend you contact local facilities before
                                            your visit.
                                        </li>
                                        <li>
                                            Be aware that if you have an existing
                                            reservation during this government shutdown
                                            period, and the location is not fully staffed,
                                            your reservation may not be honored.
                                        </li>
                                        <li>
                                            If you do make a reservation for the near
                                            future and the shutdown is not resolved by
                                            your arrival date, your reservation may not be
                                            honored or others may have occupied your site.
                                            Please consider this when making new
                                            reservations.
                                        </li>
                                    </ul>
                                </TextBlock>
                            </>
                        )}
                        {lapseInFundingPageNotice === false && (
                            <Alert type="success">
                                <strong>Operating Status:</strong> Normal Operations
                            </Alert>
                        )}
                    </div>

                    <FlexRow className="nav-shutdown-partners-wrap">
                        <FlexCol xl={12}>
                            <Heading headingLevel={2} appearance="h5">
                                Featured Agencies:
                            </Heading>
                        </FlexCol>
                        <FlexCol xl={12}>
                            <TextBlock width="xl">
                                <p className="mb-4">
                                    These agencies offer sites and activities that may be
                                    reserved on {process.env.SITE_NAME}. Please contact
                                    agencies for details on their status during the
                                    government shutdown. Be aware that offices may not be
                                    staffed due to a shutdown.
                                </p>
                            </TextBlock>
                        </FlexCol>
                    </FlexRow>
                    <FlexRow className="nav-shutdown-partners-wrap">
                        {partners.map((partner) => {
                            let status = null;
                            if (partner.displayStatus) {
                                status = (
                                    <p className="nav-shutdown-partner-description">
                                        <span>
                                            <strong>STATUS:</strong> {partner.description}
                                        </span>
                                    </p>
                                );
                            } else {
                                status = null;
                            }

                            return (
                                <FlexCol md={6}>
                                    <div
                                        className="nav-shutdown-partner"
                                        key={partner.shortName}
                                    >
                                        <div className="nav-shutdown-partner-media">
                                            <PartnerColorIcon
                                                iconName={partner.iconName}
                                                size={isMdAndDown ? 'xl' : 'xxl'}
                                            />
                                        </div>
                                        <div className="nav-shutdown-partner-content">
                                            <Heading headingLevel={3} appearance="h6">
                                                {partner.name}
                                            </Heading>

                                            {status}

                                            <div className="nav-shutdown-partner-actions">
                                                <ExternalLink
                                                    className="rec-button-link"
                                                    url={partner.link}
                                                    aria-label={`Visit the ${partner.name} website`}
                                                >
                                                    Visit Website
                                                </ExternalLink>
                                            </div>
                                        </div>
                                    </div>
                                </FlexCol>
                            );
                        })}
                    </FlexRow>
                </div>
            </ContentContainerBodyShift>
        </div>
    );
}
