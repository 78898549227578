/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import PropTypes from 'prop-types';
import { Heading, FlexRow, FlexCol, ImageWithFallback, TextBlock } from 'sarsaparilla';

import atbAccessJpg from './images/atb-access.jpg';
import atbAccessWebp from './images/atb-access.webp';
import atbSeniorAnnualJpg from './images/atb-senior-annual.jpg';
import atbSeniorAnnualWebp from './images/atb-senior-annual.webp';
import atbSeniorLifetimeJpg from './images/atb-senior-lifetime.jpg';
import atbSeniorLifetimeWebp from './images/atb-senior-lifetime.webp';
import goldenAccessJpg from './images/golden-access.jpg';
import goldenAccessWebp from './images/golden-access.webp';
import goldenAgeJpg from './images/golden-age.jpg';
import goldenAgeWebp from './images/golden-age.webp';

interface PassSectionProps {
    interagencyPassesAnchor: string;
    passesDiscountAnchor: string;
}

export default function PassSection({
    interagencyPassesAnchor = '',
    passesDiscountAnchor = '',
}: PassSectionProps) {
    const passes = [
        {
            name: 'Interagency Senior Annual Pass',
            jpgPath: atbSeniorAnnualJpg,
            webpPath: atbSeniorAnnualWebp,
        },
        {
            name: 'Interagency Senior Lifetime Pass',
            jpgPath: atbSeniorLifetimeJpg,
            webpPath: atbSeniorLifetimeWebp,
        },
        {
            name: 'Interagency Access Pass',
            jpgPath: atbAccessJpg,
            webpPath: atbAccessWebp,
        },
        {
            name: 'Golden Age Pass',
            jpgPath: goldenAgeJpg,
            webpPath: goldenAgeWebp,
        },
        {
            name: 'Golden Access Pass',
            jpgPath: goldenAccessJpg,
            webpPath: goldenAccessWebp,
        },
    ];

    return (
        <section className="mb-8" id={interagencyPassesAnchor}>
            <Heading headingLevel={2} appearance="h2" className="mb-4">
                Interagency Passes and Discounts
            </Heading>

            <Heading headingLevel={2} appearance="h3" id={passesDiscountAnchor}>
                Which Passes Provide Discounts on Reservations?
            </Heading>

            <TextBlock width="xl">
                <p className="mb-3">
                    The following are the only Interagency Passes that provide a discount
                    on camping reservations and some tickets:
                </p>
            </TextBlock>

            <FlexRow>
                {passes.map((pass, index) => (
                    <FlexCol sm={6} md={4} className="pass-image-wrap mb-2" key={index}>
                        <ImageWithFallback
                            src={pass.webpPath}
                            fallbackSrc={pass.jpgPath}
                            alt={pass.name}
                            imgHeight="120px"
                            imgWidth="190px"
                        />

                        <div className="pass-image-text">{pass.name}</div>
                    </FlexCol>
                ))}
            </FlexRow>

            <TextBlock width="xl">
                <p>
                    Eligible pass discounts only apply to individual campsites and may
                    apply to a ticket for the pass holder. Interagency pass discounts do
                    not apply to group reservations, cabins, shelters, and multi-use sites
                    (doubles, triples, etc.).
                </p>
                <p>
                    Pass discounts only apply to the recreation use fee for the location.
                    Other charges such as electricity or water hookups, reservation or
                    other fees are not eligible for a discount.
                </p>
                <p>
                    The holder of the pass must occupy the site for which the discount is
                    applied.
                </p>
                <p>
                    The pass discount may only apply to one site during any given time
                    period.
                </p>
                <p>
                    You must provide your pass and photo identification upon check in. If
                    you received a pass discount for which you were not eligible, you must
                    pay the difference in price upon arrival.
                </p>
            </TextBlock>
        </section>
    );
}

PassSection.propTypes = {
    interagencyPassesAnchor: PropTypes.string,
    passesDiscountAnchor: PropTypes.string,
};
