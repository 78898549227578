/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Heading } from 'sarsaparilla';

const voiceOverActions = [
    {
        action: 'Find the next heading',
        command: 'VO - Command - H',
    },
    {
        action: 'Find the previous heading',
        command: 'VO - Command - Shift - H',
    },
    {
        action: 'Find the next control',
        command: 'VO - Command - J',
    },
    {
        action: 'Find the previous control',
        command: 'SHIFT + TAB',
    },
    {
        action: 'Find the next Table',
        command: 'VO - Command - T',
    },
    {
        action: 'Find the previous Table',
        command: 'VO - Command - Shift - T',
    },
    {
        action: 'Find the next Link',
        command: 'VO - Command - L',
    },
    {
        action: 'Find the previous Link',
        command: 'VO - Command - Shift - L',
    },
    {
        action: 'Find the next Paragraph',
        command: 'VO - Shift - Page Down',
    },
    {
        action: 'Find the previous Paragraph',
        command: 'VO - Shift - Page Up',
    },
    {
        action: 'Read the next Sentence',
        command: 'VO - Command - Page Down',
    },
    {
        action: 'Read the next Line',
        command: 'VO - Down Arrow',
    },
    {
        action: 'Read the previous Line',
        command: 'VO - Up Arrow',
    },
    {
        action: 'Read everything visible in the window or the Dock, or on your desktop, depending on your location',
        command: 'VO - Shift - W',
    },
];

const tecName = 'Voice Over';

export default function VoiceOverActionSection() {
    return (
        <section>
            <Heading
                headingLevel={3}
                appearance="h4"
                className="accessibility-table-heading mb-2"
            >
                {tecName} Actions and Commands
            </Heading>
            <table
                className="table-no-border mb-4"
                aria-label={`${tecName} Actions and Commands Table`}
            >
                <thead>
                    <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Commands</th>
                    </tr>
                </thead>
                <tbody>
                    {voiceOverActions.map((voiceOverAction) => (
                        <tr key={voiceOverAction.action}>
                            <td width="70%">{voiceOverAction.action}</td>
                            <td width="30%">{voiceOverAction.command}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
}
