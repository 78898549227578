/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * UseOurData.jsx
 * Created by Destin Frasier on 11/29/17
 */

import React from 'react';
import {
    HelmetWrapper,
    SingleColumnContainer,
    Heading,
    HeroImageSectionFront,
} from 'sarsaparilla';

export default class UseOurData extends React.Component {
    render() {
        return (
            <div className="nav-use-our-data-page">
                <HelmetWrapper title="Use &amp; Share Our Data" />
                <HeroImageSectionFront
                    className="mb-6"
                    title="Use &amp; Share Our Data"
                    backgroundImagePath="/img/use-our-data/hero/use-our-data-hero"
                    backgroundImageAltText="Abstract shapes and colors"
                    backgroundImageColorOverlay="#0374fd"
                />

                <SingleColumnContainer className="mt-2" role="main">
                    <Heading headingLevel={2} appearance="h2">
                        Why is the Data Available?
                    </Heading>
                    <p>
                        Welcome to {process.env.SITE_NAME}&apos;s most valued asset - its
                        recreation data. The federal recreation data, and derivative
                        information, used on {process.env.SITE_NAME} is available for free
                        to anyone who envisions a variety of uses. {process.env.SITE_NAME}{' '}
                        was developed to share recreation data widely so other business
                        and government websites can provide reliable information to trip
                        planners and adventure seekers. The data can be consumed directly
                        through our retail and reservation-style website, or we have APIs
                        (Application Programming Interface) to enable efficient transfer
                        of the data to your systems.
                    </p>

                    <p>
                        Different businesses and individuals can exchange up-to-date
                        information on public recreation sites and activities using
                        {process.env.SITE_NAME} data. As more systems exchange the data in
                        real-time, the consistency of the information seen by online
                        customers improves substantially. The free and open data exchange
                        using REST API standards and technology also helps our users to
                        streamline their operations and reduce costs.
                    </p>

                    <p>
                        {process.env.SITE_NAME} is an electronic government
                        (&quot;e-gov&quot;) initiative that demonstrates the advantages of
                        sharing federal recreation data using APIs. The initiative is
                        successful when customers can find recreation information easily,
                        either through the {process.env.SITE_NAME} website or through
                        other third parties that consume and re-purpose the data.
                    </p>

                    <Heading headingLevel={2} appearance="h2">
                        Create a Web Service to Obtain Federal Recreation Data
                    </Heading>
                    <p>
                        Government and non-government organizations are encouraged to use
                        the Recreation Information Database (RIDB) API and create
                        &quot;web services&quot; to disseminate the{' '}
                        {process.env.SITE_NAME} data widely, while combining the data with
                        other information sources and business needs. Sharing data
                        according to an established standard enables different computer
                        systems and websites to display up-to-date, consistent information
                        on public recreation places and activities.
                    </p>

                    <p>
                        Creating web services to obtain data from {process.env.SITE_NAME}{' '}
                        can be found in technical documentation on the&nbsp;
                        <a
                            href={process.env.RIDB_ROOT}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            RIDB web page
                        </a>
                        .
                    </p>

                    <Heading headingLevel={2} appearance="h2">
                        Download and Use the {process.env.SITE_NAME} Data in Your System
                        or Website
                    </Heading>
                    <p>
                        {process.env.SITE_NAME} content focuses on outdoor-based and
                        historic and cultural activities available on federally managed
                        lands. It includes information for Federal-government managed and
                        Federal-government affiliated (subordinate, subsidiary or member)
                        sites. It does not list hotels, restaurants, or tourist
                        attractions managed by private companies or other organizations.
                    </p>

                    <p>
                        You are invited to use {process.env.SITE_NAME} data via the RIDB
                        website in your publication/web application/computer system, and
                        to get automatic updates as frequently as needed.
                    </p>

                    <p>
                        As stated earlier, the data in {process.env.SITE_NAME} is provided
                        for free, and there&apos;s no need to contact us before
                        incorporating {process.env.SITE_NAME} data into your system. In
                        exchange, we encourage you to provide a link to{' '}
                        {process.env.SITE_NAME} and acknowledge credit, such as{' '}
                        <i>&quot;Data Source: {process.env.SITE_NAME}&quot;</i>
                    </p>

                    <Heading headingLevel={2} appearance="h2">
                        Link to the {process.env.SITE_NAME} Data
                    </Heading>
                    <p>
                        Rather than download bulk updates from RIDB/
                        {process.env.SITE_NAME}, you may choose to simply add links on
                        your pages to our website. Add simple HTML code to your Web pages,
                        and customers who follow those links will see the most recent
                        information on {process.env.SITE_NAME}.
                    </p>

                    <p>
                        Come use our Recreation data, and contribute to its wider
                        availability and use!
                    </p>
                </SingleColumnContainer>
            </div>
        );
    }
}
