/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * Account.jsx
 * Created by Destin Frasier on 09/16/19
 */

import React from 'react';
import { FlexRow, FlexCol, Heading } from 'sarsaparilla';

import SiteMapItem from './SiteMapItem';

export default class Account extends React.Component {
    render() {
        return (
            <FlexRow>
                <FlexCol>
                    <Heading headingLevel={2} appearance="h5">
                        Account
                    </Heading>
                    <ul className="nav-site-map-wrap">
                        <SiteMapItem
                            name="Profile"
                            url="/account/profile"
                            requiresLogin
                        />
                        <SiteMapItem
                            name="Reservations"
                            url="/account/reservations"
                            requiresLogin
                        />
                        <SiteMapItem
                            name="Trip Preferences"
                            url="/account/trip_preferences"
                            requiresLogin
                        />
                        <SiteMapItem name="Trips" url="/account/trips" requiresLogin />
                        <SiteMapItem
                            name="Communication Preferences"
                            url="/account/communication_preferences"
                            requiresLogin
                        />
                        <SiteMapItem
                            name="Favorites"
                            url="/account/favorites"
                            requiresLogin
                        />
                        <SiteMapItem
                            name="Interagency Passes"
                            url="/account/interagency-passes"
                            requiresLogin
                        />
                        <SiteMapItem
                            name="Lotteries"
                            url="/account/lotteries"
                            requiresLogin
                        />
                        <SiteMapItem
                            name="Donations"
                            url="/account/donations"
                            requiresLogin
                        />
                        <SiteMapItem name="Passes" url="/account/passes" requiresLogin />
                        <SiteMapItem
                            name="Password"
                            url="/account/profile/password"
                            requiresLogin
                        />
                        <SiteMapItem
                            name="Payment"
                            url="/account/profile/payment"
                            requiresLogin
                        />
                        <SiteMapItem
                            name="Saved Payment"
                            url="/account/profile/saved_payment"
                            requiresLogin
                        />
                    </ul>
                </FlexCol>
            </FlexRow>
        );
    }
}
