/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';

import { ContentBlock, Box, Alert } from 'sarsaparilla';

export default function ErrorPage(props) {
    return (
        <ContentBlock {...props}>
            <Box alignX="center">
                <Alert type="error" heading="Something went wrong" headingLevel={1}>
                    Sorry, an unexpected error occurred. Refresh the page and try again.
                </Alert>
            </Box>
        </ContentBlock>
    );
}
