/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * Created by michaelbray on 7/2/18.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    Breadcrumbs,
    MediaGallery,
    DisplayHtmlContent,
    Heading,
    FlexRow,
    FlexCol,
    SocialShareLink,
    Inline,
} from 'sarsaparilla';

import { BREADCRUMB, JOURNAL } from '../../../../constants/propTypes';
import ArticleFeaturedImage from '../ArticleFeaturedImage';
import ArticleQuote from '../ArticleQuote';
import ArticleSource from '../ArticleSource';

export function ArticleJournalBody({ breadCrumbPath, article }) {
    let mediaGallery = null;
    if (article.photo_gallery_export.length > 0) {
        mediaGallery = <MediaGallery images={article.photo_gallery_export} />;
    }

    return (
        <article className="nav-article-body" role="main">
            <ArticleSource
                articleLocationSource={article.hero_image_location_and_source}
            />

            <FlexRow alignItems="center" className="mb-3">
                <FlexCol>
                    <Breadcrumbs path={breadCrumbPath} />
                </FlexCol>

                <FlexCol xs={12} lg="variable" className="rec-share-btn-wrap">
                    <Inline space="sm">
                        <SocialShareLink
                            platform="twitter-x"
                            text={article?.article_title}
                        />
                        <SocialShareLink
                            platform="facebook"
                            text={article?.article_title}
                        />
                        <SocialShareLink
                            platform="pinterest"
                            text={article?.article_title}
                            image={article?.promotional_image}
                        />
                        <SocialShareLink platform="email" text={article?.article_title} />
                    </Inline>
                </FlexCol>
            </FlexRow>

            <Heading headingLevel={2} appearance="h2">
                <DisplayHtmlContent html={article.article_subhead} />
            </Heading>

            <DisplayHtmlContent html={article.article_overview} />

            <ArticleFeaturedImage
                imageSource={article.journal_entry_image}
                imageAlt={article.journal_entry_image_alt_text}
                inventoryUrl={article.journal_entry_image_inv_url}
                mediaLocation={article.media_imgvideo_location_source}
                videoSource={article.journal_entry_video_youtube_url}
                videoTranscript={article.journal_entry_video_transcript}
            />

            <DisplayHtmlContent html={article.journal_entry_above_quote} />

            <ArticleQuote quoteText={article.quote} />

            <DisplayHtmlContent html={article.journal_entry_below_quote} />

            {mediaGallery}
        </article>
    );
}

ArticleJournalBody.propTypes = {
    breadCrumbPath: PropTypes.arrayOf(BREADCRUMB),
    article: JOURNAL,
};
