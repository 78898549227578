/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * PartnersSection.jsx
 * Created by Destin Frasier on 03/31/22
 */

import { ContentContainer } from 'sarsaparilla';
import { PartnerIcon } from '../Icons/Partner/PartnerIcon';

const partners = [
    'BLM',
    'DOI',
    'TRE',
    'ForestService',
    'NPS',
    'PT',
    'FWS',
    'USACE',
    'NationalArchives',
] as const;

export default function PartnersSection() {
    return (
        <section className="nav-ni-partners-section-wrap">
            <ContentContainer className="my-0">
                <div className="nav-ni-partners-icon-wrap">
                    {partners.map((iconName) => (
                        <div className="nav-ni-partners-icon-block" key={iconName}>
                            <PartnerIcon
                                iconName={iconName}
                                size="lg"
                                className="partner-icon"
                            />
                        </div>
                    ))}
                </div>
            </ContentContainer>
        </section>
    );
}
