/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import * as React from 'react';

import {
    Accordion,
    AccordionHeading,
    AccordionContent,
    AccordionItem,
    Button,
    announce,
    useWindowSize,
} from 'sarsaparilla';

const sections = [
    {
        sectionTitle: 'Fees and Definitions',
        anchorLink: 'feesAnchor',
    },
    {
        sectionTitle: 'Policies and Rules for Different Types of Activities',
        anchorLink: 'policiesAnchor',
        subItems: [
            {
                subSectionTitle: 'Camping, Day-Use, Scan & Pay and Cabin Reservations',
                subAnchorLink: 'campingAnchor',
                subSubItems: [
                    {
                        subSubSectionTitle: 'Cancellations',
                        subSubAnchorLink: 'cancelAnchor',
                    },
                    {
                        subSubSectionTitle:
                            'Modifying/Changing an Existing Reservation Prior to the Cut Off Window',
                        subSubAnchorLink: 'modifyAnchor',
                    },
                    {
                        subSubSectionTitle: 'No-Shows',
                        subSubAnchorLink: 'noShowAnchor',
                    },
                    {
                        subSubSectionTitle: 'Early Departures',
                        subSubAnchorLink: 'departuresAnchor',
                    },
                    {
                        subSubSectionTitle: 'Due Now Payments',
                        subSubAnchorLink: 'dueNowAnchor',
                    },
                    {
                        subSubSectionTitle: 'Down Payment',
                        subSubAnchorLink: 'downPaymentAnchor',
                    },
                    {
                        subSubSectionTitle: 'Security Deposit',
                        subSubAnchorLink: 'securityDepositAnchor',
                    },
                ],
            },
            {
                subSectionTitle:
                    'Tickets, Tours and Vehicle Reservations (Timed Entry) Reservations',
                subAnchorLink: 'ticketsAnchor',
                subSubItems: [
                    {
                        subSubSectionTitle:
                            'Modifying/Changing an Existing Reservation Prior to Tour Time',
                        subSubAnchorLink: 'ticketsModifyAnchor',
                    },
                    {
                        subSubSectionTitle: 'Cancellations',
                        subSubAnchorLink: 'ticketsCancellationAnchor',
                    },
                    {
                        subSubSectionTitle: 'Late Cancellations',
                        subSubAnchorLink: 'ticketsLateCancellationAnchor',
                    },
                ],
            },
            {
                subSectionTitle: 'Permit Reservations',
                subAnchorLink: 'permitsAnchor',
            },
            {
                subSectionTitle: 'Christmas Tree Permits',
                subAnchorLink: 'christmasTreeAnchor',
            },
            {
                subSectionTitle: 'Site and Activity Passes',
                subAnchorLink: 'activityPassAnchor',
            },
            {
                subSectionTitle: 'Venues',
                subAnchorLink: 'venuesAnchor',
            },
            {
                subSectionTitle: 'Interagency Passes and Discounts',
                subAnchorLink: 'interagencyPassesAnchor',
                subSubItems: [
                    {
                        subSubSectionTitle:
                            'Which Passes Provide Discounts on Reservations?',
                        subSubAnchorLink: 'passesDiscountAnchor',
                    },
                ],
            },
        ],
    },
    {
        sectionTitle: 'Refunds and Refund Requests',
        anchorLink: 'refundsAnchor',
        subItems: [
            {
                subSectionTitle: 'Submitting a Refund Request',
                subAnchorLink: 'submittingRefundAnchor',
            },
            {
                subSectionTitle: 'Refund Request Approvals',
                subAnchorLink: 'refundsRequestAnchor',
            },
            {
                subSectionTitle: 'How Refunds are Delivered',
                subAnchorLink: 'refundsDeliveredAnchor',
            },
            {
                subSectionTitle: 'What is and is not Refundable?',
                subAnchorLink: 'notRefundableAnchor',
                subSubItems: [
                    {
                        subSubSectionTitle: 'Refundable',
                        subSubAnchorLink: 'refundableAnchor',
                    },
                    {
                        subSubSectionTitle: 'Non-Refundable',
                        subSubAnchorLink: 'nonRefundableAnchor',
                    },
                ],
            },
        ],
    },
    {
        sectionTitle: 'Recreation.gov Billing Information',
        anchorLink: 'billingAnchor',
    },
    {
        sectionTitle: 'Changes to Policies and Procedures',
        anchorLink: 'billingAnchor',
    },
];

export function TableOfContents() {
    const { width = 0 } = useWindowSize();

    function anchorLinkHandler(
        event: React.MouseEvent<HTMLButtonElement>,
        id: string,
        offsetTop: number
    ) {
        const distanceToTop = (el: HTMLElement) =>
            Math.floor(el.getBoundingClientRect().top);
        const targetId = id || event.currentTarget?.getAttribute('href');
        const targetAnchor = targetId
            ? (document.querySelector(targetId) as HTMLElement)
            : null;
        if (!targetAnchor) return;

        let timer: number;

        /**
         * Check when scroll event ends
         */
        const onScrollEnd = () => {
            clearTimeout(timer);
            timer = window.setTimeout(() => {
                targetAnchor.focus(); // Set the focus to first section element
                window.removeEventListener('scroll', onScrollEnd); // Remove listener after the element gets the focus
            }, 300);
        };

        announce(`Scrolled to ${targetAnchor?.innerText || id} section`);
        const originalTop = distanceToTop(targetAnchor);
        window.scrollBy({
            top: originalTop - 100 - offsetTop,
            left: 0,
            behavior: 'smooth',
        });
        window.addEventListener('scroll', onScrollEnd); // Add scroll event listener
    }

    return (
        <nav>
            <Accordion
                id="with-children"
                hasIndentedBody={false}
                headingAppearance="h5"
                defaultActiveIndex={width >= 768 ? 0 : undefined}
                className="rules-reservation-policies-toc mb-2"
                items={[]}
            >
                <AccordionItem>
                    <AccordionHeading>Table of Contents</AccordionHeading>
                    <AccordionContent>
                        <ul>
                            {sections.map((section, index) => {
                                return (
                                    <li key={index}>
                                        <Button
                                            appearance="link"
                                            onClick={(
                                                event: React.MouseEvent<HTMLButtonElement>
                                            ) =>
                                                anchorLinkHandler(
                                                    event,
                                                    `#${section?.anchorLink}`,
                                                    32
                                                )
                                            }
                                            clickTagCategory="Rules & Reservation Policies Page"
                                            clickTagAction={section.sectionTitle}
                                            clickTagLabel="TOC Anchor Link"
                                        >
                                            {section.sectionTitle}
                                        </Button>

                                        {section.subItems?.length ? (
                                            <ul>
                                                {section.subItems.map(
                                                    (subItem, subIndex) => {
                                                        return (
                                                            <li key={subIndex}>
                                                                <Button
                                                                    appearance="link"
                                                                    onClick={(
                                                                        event: React.MouseEvent<HTMLButtonElement>
                                                                    ) =>
                                                                        anchorLinkHandler(
                                                                            event,
                                                                            `#${subItem?.subAnchorLink}`,
                                                                            32
                                                                        )
                                                                    }
                                                                    clickTagCategory="Rules & Reservation Policies Page"
                                                                    clickTagAction={
                                                                        subItem.subSectionTitle
                                                                    }
                                                                    clickTagLabel="TOC Anchor Link"
                                                                >
                                                                    {
                                                                        subItem.subSectionTitle
                                                                    }
                                                                </Button>
                                                                {subItem.subSubItems
                                                                    ?.length ? (
                                                                    <ul>
                                                                        {subItem.subSubItems?.map(
                                                                            (
                                                                                subSubItem,
                                                                                subSubIndex
                                                                            ) => {
                                                                                return (
                                                                                    <li
                                                                                        key={
                                                                                            subSubIndex
                                                                                        }
                                                                                    >
                                                                                        <Button
                                                                                            appearance="link"
                                                                                            onClick={(
                                                                                                event: React.MouseEvent<HTMLButtonElement>
                                                                                            ) =>
                                                                                                anchorLinkHandler(
                                                                                                    event,
                                                                                                    `#${subSubItem?.subSubAnchorLink}`,
                                                                                                    32
                                                                                                )
                                                                                            }
                                                                                            clickTagCategory="Rules & Reservation Policies Page"
                                                                                            clickTagAction={
                                                                                                subSubItem.subSubSectionTitle
                                                                                            }
                                                                                            clickTagLabel="TOC Anchor Link"
                                                                                        >
                                                                                            {
                                                                                                subSubItem.subSubSectionTitle
                                                                                            }
                                                                                        </Button>
                                                                                    </li>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </ul>
                                                                ) : null}
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        ) : null}
                                    </li>
                                );
                            })}
                        </ul>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </nav>
    );
}
