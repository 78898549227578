/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import cx from 'classnames';
import {
    Icon,
    Button,
    FlexCol,
    FlexRow,
    EmailField,
    ZIPCodeField,
    announce,
} from 'sarsaparilla';

import type { NewsletterSubscribeFormProps } from '../../../../types/NewsletterSubscribeForm';

export function NewsletterSubscribeForm({
    isBackgroundLight = false,
    isForHomePage = true,
}: NewsletterSubscribeFormProps) {
    const [email, setEmail] = React.useState('');
    const [zip, setZip] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);
    const formRef = React.useRef<HTMLFormElement>(null);
    const emailFieldRef = React.useRef<any>(null);
    const zipFieldRef = React.useRef<any>(null);

    function onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function onZipChange(event: React.ChangeEvent<HTMLInputElement>) {
        setZip(event.target.value);
    }

    function handleSubmitForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const emailValidation = emailFieldRef.current.validate();
        const zipValidation = zipFieldRef.current.validate();

        const isInvalid = emailValidation.isInvalid || zipValidation.isInvalid;

        if (emailValidation.isInvalid) {
            announce(emailValidation.errorText);
            const input = document.getElementById(emailValidation.id);
            input?.focus();
        } else if (zipValidation.isInvalid) {
            announce(zipValidation.errorText);
            const input = document.getElementById(zipValidation.id);
            input?.focus();
        }

        if (!isInvalid) {
            setLoading(true);
            formRef.current?.submit();
        }
    }

    return (
        <form
            method="POST"
            action="https://recreationgov.activehosted.com/proc.php"
            ref={formRef}
            noValidate
            onSubmit={handleSubmitForm}
        >
            <input type="hidden" name="u" value="14" />
            <input type="hidden" name="f" value="14" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <input type="hidden" name="email" value={email} />
            <input type="hidden" name="field[2]" value={zip} />

            <FlexRow className="nav-newsletter-form" hasGutters={!isForHomePage}>
                <FlexCol sm={12} md={isForHomePage ? 7 : 6}>
                    <EmailField
                        ref={emailFieldRef}
                        id="nav-newsletter-email-address"
                        className={cx({
                            'nav-newsletter-item': isForHomePage,
                            'mb-2': !isForHomePage,
                            'field-reverse': !isBackgroundLight,
                        })}
                        isLabelVisible={!isForHomePage}
                        isRequired
                        iconElement={<Icon iconName="email" />}
                        placeholder="Email Address"
                        onChange={onEmailChange}
                    />
                </FlexCol>
                <FlexCol sm={isForHomePage ? 12 : 6} md={3}>
                    <ZIPCodeField
                        ref={zipFieldRef}
                        id="nav-newsletter-zip-code"
                        className={cx({
                            'nav-newsletter-item': isForHomePage,
                            'mb-2': !isForHomePage,
                            'field-reverse': !isBackgroundLight,
                        })}
                        isRequired
                        placeholder="ZIP Code"
                        isLabelVisible={!isForHomePage}
                        onChange={onZipChange}
                    />
                </FlexCol>
                <FlexCol
                    sm={12}
                    md={isForHomePage ? 2 : undefined}
                    className={isForHomePage ? undefined : 'mt-2'}
                >
                    <Button
                        type="submit"
                        appearance={
                            !isForHomePage
                                ? 'primary'
                                : isBackgroundLight
                                  ? 'primary'
                                  : 'tertiary-white'
                        }
                        className={cx('nav-newsletter-item nav-newsletter-submit-btn', {
                            reverse: !isBackgroundLight,
                        })}
                        size="md"
                        isLoading={isLoading}
                    >
                        Subscribe
                    </Button>
                </FlexCol>
            </FlexRow>
        </form>
    );
}
