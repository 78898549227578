/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * Permits.jsx
 * Created by Destin Frasier on 09/16/19
 */

import React from 'react';
import { FlexRow, FlexCol, Heading } from 'sarsaparilla';
import SiteMapItem from './SiteMapItem';

export default class Permits extends React.PureComponent {
    render() {
        return (
            <FlexRow>
                <FlexCol>
                    <Heading headingLevel={2} appearance="h5">
                        Permits
                    </Heading>
                    <ul className="nav-site-map-wrap">
                        <SiteMapItem
                            name="Anan Wildlife Observatory Permits"
                            url="/permits/251349"
                        />
                        <SiteMapItem
                            name="Aravaipa Canyon Wilderness Permits"
                            url="/permits/251864"
                        />
                        <SiteMapItem name="Cables On Half Dome" url="/permits/234652" />
                        <SiteMapItem
                            name="Cape Hatteras National Seashore OHV Permit"
                            url="/vehiclepermits/249978"
                        />
                        <SiteMapItem name="Cedar Mesa Permits" url="/permits/273374" />
                        <SiteMapItem
                            name="Conundrum Hot Springs - Maroon Bells-Snowmass Wilderness"
                            url="/permits/273336"
                        />
                        <SiteMapItem
                            name="Denali National Park - Road Lottery"
                            url="/permits/233304"
                        />
                        <SiteMapItem
                            name="Desolation Wilderness Permit"
                            url="/permits/233261"
                        />
                        <SiteMapItem
                            name="Dinosaur National Monument - Split Mountain Permits"
                            url="/permits/250987"
                        />
                        <SiteMapItem
                            name="Enchantment Permit Area"
                            url="/permits/233273"
                        />
                        <SiteMapItem
                            name="Fire Island National Seashore Permits"
                            url="/permits/234768"
                        />
                        <SiteMapItem
                            name="Grand Teton National Park - Backcountry Permits"
                            url="/permits/249986"
                        />
                        <SiteMapItem
                            name="Hells Canyon - Snake River (4 Rivers)"
                            url="/permits/234625"
                        />
                        <SiteMapItem
                            name="Hells Canyon - Snake River (Powerboat)"
                            url="/permits/249988"
                        />
                        <SiteMapItem name="Hemlock Cabin" url="/permits/233386" />
                        <SiteMapItem
                            name="Inyo National Forest - Wilderness Permits"
                            url="/permits/233262"
                        />
                        <SiteMapItem
                            name="Humboldt-Toiyabe National Forest Wilderness Permits"
                            url="/permits/445856"
                        />
                        <SiteMapItem
                            name="Lower Deschutes River Boater Pass"
                            url="/permits/251980"
                        />
                        <SiteMapItem
                            name="Mariposa Grove Commercial Bus Parking"
                            url="/vehiclepermits/274348"
                        />
                        <SiteMapItem
                            name="Middle Fork Of The Salmon (4 Rivers)"
                            url="/permits/234623"
                        />
                        <SiteMapItem
                            name="Mount Margaret Backcountry"
                            url="/permits/250003"
                        />
                        <SiteMapItem
                            name="Mount St. Helens Climbing Permit"
                            url="/permits/4675309"
                        />
                        <SiteMapItem name="Mt. Whitney" url="/permits/233260" />
                        <SiteMapItem
                            name="National Forests in Florida OHV Trails"
                            url="/vehiclepermits/74048"
                        />
                        <SiteMapItem
                            name="Obsidian Limited Entry Area"
                            url="/permits/234770"
                        />
                        <SiteMapItem
                            name="Olympic National Park Wilderness Permit"
                            url="/permits/4098362"
                        />
                        <SiteMapItem
                            name="Pack Creek Bear Viewing Area"
                            url="/permits/233328"
                        />
                        <SiteMapItem
                            name="Pamelia Limited Entry Area"
                            url="/permits/234769"
                        />
                        <SiteMapItem
                            name="Pere Marquette National Scenic River Watercraft Permits (Huron Manistee)"
                            url="/permits/249987"
                        />
                        <SiteMapItem
                            name="Rogue River Wild Section"
                            url="/permits/251982"
                        />
                        <SiteMapItem
                            name="Ruby Horsethief Canyon Permits"
                            url="/permits/74466"
                        />
                        <SiteMapItem
                            name="Salmon River (4 Rivers)"
                            url="/permits/234622"
                        />
                        <SiteMapItem
                            name="Salt River Canyon Wilderness Permit"
                            url="/permits/249275"
                        />
                        <SiteMapItem
                            name="Selway River (4 Rivers)"
                            url="/permits/234624"
                        />
                        <SiteMapItem name="Tilly Jane A-frame" url="/permits/233326" />
                        <SiteMapItem
                            name="Voyageurs National Park Houseboat Permits"
                            url="/permits/251977"
                        />
                        <SiteMapItem
                            name="Yellowstone Non-Commercially Guided Snowmobile Access Program"
                            url="/permits/250849"
                        />
                    </ul>
                </FlexCol>
            </FlexRow>
        );
    }
}
