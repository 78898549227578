/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import PropTypes from 'prop-types';

import { Heading, sluggify } from 'sarsaparilla';

interface AnchorPageSectionProps {
    children: React.ReactNode;
    heading: React.ReactNode;
    headingLevel: 1 | 2 | 3 | 4 | 5 | 6;
    headingAppearance?:
        | 'display'
        | 'h1'
        | 'h2'
        | 'title'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h5Caps'
        | 'h6'
        | 'small';
    className?: string;
    gaTrackingId?: string;
}

export function AnchorPageSection({
    children,
    heading,
    headingLevel = 2,
    headingAppearance = 'h5',
    className = '',
    gaTrackingId,
}: AnchorPageSectionProps) {
    const id = sluggify(heading);

    return (
        <section data-component="AnchorPageSection" id={`${id}-section`}>
            <div className="heading-spacer">
                <Heading
                    data-shared-anchor-page-section-heading
                    tabIndex={-1}
                    className={`mb-2 ${className}`}
                    id={id}
                    headingLevel={headingLevel}
                    appearance={headingAppearance}
                    data-ga-tracking-id={gaTrackingId}
                >
                    {heading}
                </Heading>
            </div>

            {children}
        </section>
    );
}

AnchorPageSection.propTypes = {
    heading: PropTypes.string,
    children: PropTypes.node,
    headingLevel: PropTypes.number,
    headingAppearance: PropTypes.string,
    className: PropTypes.string,
    gaTrackingId: PropTypes.string,
};
